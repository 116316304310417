import React, {useEffect, useRef, useState, Fragment} from 'react';
import { debounce } from 'lodash';
import CategoryItem from './CategoryItem';
import ProductCard from '../../modules/ProductCard';
import flatstore from 'flatstore';
import {getProducts, getMenu} from '../../../utils/services';
import { isNull, isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { currentLang } from '../../../utils/functions';

const Category = ({animal_data, products_data, filter, config}) => {
    const [categories, setCategories] = useState(null);
    const [parentCategories, setParentCategories] = useState([]);
    const [products, setProducts] = useState(null);
    const [activeCategory, setActiveCategory] = useState(null);
    const [items, setItems] = useState(null);
    const { t } = useTranslation();
    const [pageLoad, setPageLoad] = useState(0);
    const {pathname} = useLocation();
    const [mobileActiveScroll, setMobileActiveScroll] = useState(false); // active animal switch scroll on mobile

    const location = useLocation();
    const path = location.pathname.split('/').filter(str=>str.length>1);

    const animalRefs = useRef({});

    useEffect(() => {
        animal_data = null;
        products_data = null;
    }, [])

    useEffect(() => {
        //console.log(animal_data);
        if(isNil(animal_data)){
            getMenu('animals', 'animal_data');
            getProducts();
        }

        if(!isNil(animal_data) && isNil(categories)){
            setCategories(animal_data);
            setParentCategories(animal_data.filter(item=>(isNil(item.parent_id)) && !(config && config.disabled_items && config.disabled_items.includes(item.menu_item_id.toString()))));
        }

    }, [animal_data]);

    useEffect(() => {

        if(!isNil(products_data)){
            setProducts(products_data);
            if(categories){
                if(activeCategory){
                    handleClick(activeCategory);
                }else{
                    var clicked = false;
                    categories.map((item)=>{
                        if(filter){
                            if(t(filter) == item.url){
                                clicked = true;
                                handleClick(item.menu_item_id);
                            }
                        }else{
                            if(config && !isNil(config.selected_item)){
                                clicked = true;
                                handleClick(parseInt(config.selected_item));
                            }
                        }
                    })
                }
            }
        }
        // activate the scroll on mobile after products data load
        if(!isNil(path[2]) && path.includes(t('menu.animal'))){
            setMobileActiveScroll(true);
        }

    }, [products_data, categories]);

    const hasInitialScroll = useRef(false);

    useEffect(() => {

        if (!isNil(path[2]) && path.includes(t('menu.animal')) && !isNil(categories) && !hasInitialScroll.current) {
            // Extract the category from the URL
            const categoryFromURL = path[3];
            // Find the corresponding category item in the data
            const categoryItem = categories.find(item => t(item.url) === categoryFromURL);

            // Scroll to the category if found
            if (categoryItem) { 
                setTimeout(() => {
                    handleClick(categoryItem.menu_item_id);
                    hasInitialScroll.current = true; // this is to stop rescrolling in middle of scrolling
                }, 2000);
                }
        }
    }, [path, categories]);


    useEffect(() => {

        if(products_data){

            if(categories){
                var clicked = false;
                categories.map((item)=>{
                    if(filter){
                        if(t(filter) == item.url){
                            clicked = true;
                            handleClick(item.menu_item_id);
                        }
                    }else{
                        if(config && !isNil(config.selected_item)){
                            clicked = true;
                            handleClick(parseInt(config.selected_item));
                        }
                    }
                })
                if(!clicked){
                    //handleClick(categories[Math.ceil(categories.length/2)-1].menu_item_id);
                }
            }
        }
    }, [filter])



    useEffect(() => {

        if(activeCategory){
            var activeItem = document.querySelector('.category__item.active');
            if(activeItem && window.innerWidth <= 768){
                var is_not_homepage = pathname !== '/'+currentLang() && pathname !== '/'+currentLang()+'/';
                var section = activeItem.parentElement
                var header__top = document.querySelector('.header__top');
                var header_height = header__top ? header__top.offsetHeight : 0;
                if(pageLoad == 1){
                    // clicked switch - scroll top of window
                    window.scrollTo({
                        top:  section.getBoundingClientRect().top + window.pageYOffset - header_height,
                        behavior: 'smooth'
                    });
                    section.scrollTo({
                        top:  0,
                        left: activeItem.offsetLeft - ( section.offsetWidth - activeItem.offsetWidth) / 2,
                        behavior: 'smooth'
                    });
                }else if(pageLoad == 0 && is_not_homepage && mobileActiveScroll){
                    // page loaded - scroll towards bottom of window
                    window.scrollTo({
                        top:  section.getBoundingClientRect().top + window.pageYOffset + header_height - window.innerHeight,
                        behavior: 'smooth'
                    });
                    section.scrollTo({
                        top:  0,
                        left: activeItem.offsetLeft - ( section.offsetWidth - activeItem.offsetWidth) / 2,
                        behavior: 'smooth'
                    });
                }
            }
            setPageLoad(1);
        }
    }, [activeCategory])

    const handleClick = (value) => {
        if (activeCategory !== value) {
            setActiveCategory(value);
    
            let categoryItems = [];
    
            categories.map((item) => {
                if (item.parent_id == value)
                    categoryItems.push(item);
            })
            setItems(categoryItems);
    
            // Scroll to the selected section smoothly using native JavaScript
            if (animalRefs.current[value]) {
                const targetElement = animalRefs.current[value];
                const sectionPosition = targetElement.getBoundingClientRect().top + window.scrollY;
                const startPosition = window.scrollY;
                const duration = 250; // Adjust the duration as needed
                const startTime = performance.now();
    
                function scrollAnimation(currentTime) {
                    const elapsedTime = currentTime - startTime;
                    const scrollProgress = Math.min(1, elapsedTime / duration);
                    const easeFunction = t => t * t * t; // Adjust the ease function if needed
    
                    window.scrollTo(0, startPosition + (sectionPosition - startPosition) * easeFunction(scrollProgress));
    
                    if (scrollProgress < 1) {
                        requestAnimationFrame(scrollAnimation);
                    }
                }
    
                requestAnimationFrame(scrollAnimation);
            }
        }
    }
    

    const getProductInfo = (product_slug) => {
        var productInfo = false;
        // Check if products is not null before mapping
        if (!isNil(products) && Array.isArray(products)) {
            products.map(item => {
                if(item.slug == product_slug){
                    productInfo = item;
                }
            });
        }
        return productInfo;
    }
    const handleScroll = debounce(() => {
        if (!Array.isArray(parentCategories)) return; // Add this line

        const activationThreshold = window.innerHeight * 0.25;

        for (const category of parentCategories) {
            const ref = animalRefs.current[category.menu_item_id];
            if (ref) {
                const rect = ref.getBoundingClientRect();
                if (rect.top >= -activationThreshold && rect.top <= activationThreshold) {
                    setActiveCategory(category.menu_item_id);
                    break;  // We've found the category in view, no need to check the rest
                }
            }
        }
    }, 50);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [parentCategories, setActiveCategory, animalRefs]);


    return (
        <>
        <div className={"module__category-switch "+((parentCategories && parentCategories.length < 2) ? " none--categorey" : "")}>
        {items &&
            <Fragment>
                {parentCategories && parentCategories.length > 1 &&
                    <ul className={"tabs" + (" " + currentLang()) + (parentCategories.length > 4 ? ' left' : '')}>
                        {parentCategories.map((item, index) => {
                            return (
                                <li key={'category_item_li_'+index}>
                                    <CategoryItem
                                        key={'category_item_'+index}
                                        id={item.menu_item_id}
                                        name={item.title}
                                        icon={item.icon_class}
                                        itemKey={"categoryItem_"+item.menu_item_id}
                                        activeCategory={activeCategory}
                                        filter={item.url}
                                    />
                                </li>
                            )
                        })}
                    </ul>
                }
                {parentCategories && parentCategories.length >= 1 &&
                    <div className='category__items'>
                        {parentCategories.map((item, index) => {
                            let relatedItems = animal_data.filter(a => a.parent_id === item.menu_item_id);
                            return (
                                <div className='x-scroll' key={'category_items_'+index}>
                                    <div className='container'>
                                        <div className="col animal_group">
                                            <h2 className={"title m-0" + item.title.replaceAll(' ', '-').toLowerCase()}> {item.title} </h2>
                                            <hr ref={ref => animalRefs.current[item.menu_item_id] = ref} />
                                        </div>
                                        <div className='row'>
                                            {relatedItems.length &&
                                                relatedItems.map((relatedItem, idx) => {
                                                    let productInfo = getProductInfo(relatedItem.product_slug);
                                                    return (
                                                        <div className='col-lg-4 col-xl-3' key={'related_items_'+idx}>
                                                            {productInfo &&
                                                                <div
                                                                    key={"product_card_" + index}
                                                                    className={"category-products-item item_" + productInfo.product_slug}
                                                                    >
                                                                    <ProductCard data={productInfo} />
                                                                </div>
                                                            }
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                }
            </Fragment>
        }
        </div>
        </>
    )
}

export default flatstore.connect(['animal_data', 'products_data'])(Category);
