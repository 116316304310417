import React, {useState, useEffect, Fragment} from 'react';
import Slider from '../Slider';
import parse from 'html-react-parser';
import Freezer from '../Freezer';
import FarmerBanner from '../FarmerBanner';
import Category from '../Category';
import RelatedProducts from '../RelatedProducts';
import RelatedRecipes from '../RelatedRecipes';
import MeatcutOverview from '../MeatcutOverview';
import RecipeHighlight from '../RecipeHighlight';
import ProductHighlight from '../ProductHighlight';
import Newsletter from '../Newsletter';
import NewsletterPopup from '../NewsletterPopup';
import BreedsOverview from '../BreedsOverview';
import Reviews from '../Reviews';
import {isNil, replace} from 'lodash';
import {isJSONstring} from '../../../utils/storage';
import W from '../../common/Word';
import { useLocation } from 'react-router-dom';
import {currentLang, dynamic_delivery_promise} from '../../../utils/functions';
import {getStorage} from '../../../utils/storage';
import Navigator from '../Navigator';
import BasicRecipe from '../BasicRecipe';
import FarmshopHighlight from '../FarmshopHighlight';
import BlogHighlight from '../BlogHighlight';
import { useTranslation } from 'react-i18next';
import FarmshopPopularProducts from '../FarmshopPopularProducts';

function LoadModule({data, product, filter, moduleData, moduleId}) {
    const [moduleType, setModuleType] = useState();
    const [moduleConfig, setModuleConfig] = useState(null);
    const [items, setItems] = useState();
    const [moduleClass, setModuleClass] = useState(null);
    const { t } = useTranslation();

    // temp
    let location = useLocation();
    let urlpaths = replace(location.pathname, '/'+currentLang()+'/', '').split('/');
    let slug = urlpaths[0];
    useEffect(() => {
        if(!isNil(data.config)){
            if(isJSONstring(data.config)){
                setModuleConfig(JSON.parse(data.config));
            }else{
                setModuleConfig(data.config);
            }
        }
        setModuleType(data.module);
        if(data && moduleType == 'html_box'){
            var webp_disable = getStorage('webp_disable');
            if(webp_disable){
                data.body = data.body.replaceAll('.webp', '.jpg').replaceAll('image/webp', 'image/jpeg');
            }
        }

        // Dynamic delivery promise
        if(data){
            let dynamic_delivery_promise_text = t(dynamic_delivery_promise(t('farmshop.deadline_hour')));

            if(data.module == 'slider'){
                if(data.slider_items && data.slider_items.length > 0){
                    data.slider_items.map((item, index)=>{
                        if(data.slider_items[index].body){
                            data.slider_items[index].body = data.slider_items[index].body.replaceAll('{dynamic_delivery_promise}', dynamic_delivery_promise_text );
                        }
                    })
                }
            }else if(['html_box','popular_farmshop','farmshop_highlight'].includes(data.module)){
                if(data.body){
                    data.body = data.body.replaceAll('{dynamic_delivery_promise}', dynamic_delivery_promise_text );
                }
            }
            var className = data.class ? data.class : ' ';
            setModuleClass(className);
        }

    }, [data])


    return (
        <div>
            {data && moduleType == 'slider' &&
                <Slider data={data.slider_items} config={moduleConfig} />
            }
            {data && moduleType == 'text_box' &&
                <div className={"container mt-4 module__text-box"}>
                    {data.anchor && <a name={data.anchor+'-'+moduleId}></a>}
                    <div className="row">
                    <div className="col-12">
                        <h2>{data.title}</h2>
                        {data.body && parse(data.body)}
                    </div>
                    </div>
                </div>
            }
            {data && moduleType == 'html_box' &&
                <div className={"module__html-box "+moduleClass}>
                    {data.anchor && <a name={data.anchor+'-'+moduleId}></a>}
                    {slug == 'invest' &&
                        <div dangerouslySetInnerHTML={{__html: data.body}}></div>
                    }
                    {slug != 'invest' &&
                        <Fragment>
                        {data.body && parse(data.body)}
                        </Fragment>
                    }
                </div>
            }
            {data && moduleType == 'freezer_size' &&
                <div className={"module__freezer-size"}>
                    <Freezer config={moduleConfig} weight={product && product.shop_package_frontend_size} />
                </div>
            }
            {data && moduleType === 'animal_switch' &&
                <div className={moduleClass}>
                    {data.anchor && <a name={data.anchor+'-'+moduleId}></a>}
                    <Category filter={filter} config={moduleConfig} />
                </div>
            }
            {data && moduleType === 'farmer_banner' &&
                <FarmerBanner data={moduleData} />
            }
            {moduleData && moduleType === 'related_products' &&
                <div className={moduleClass}>
                    {data.anchor && <a name={data.anchor+'-'+moduleId}></a>}
                    <RelatedProducts data={data} relatedType={moduleData.relatedType} relatedSlug={moduleData.relatedSlug} />
                </div>
            }
            {moduleData && moduleType === 'related_recipes' &&
                <div className={moduleClass}>
                    {data.anchor && <a name={data.anchor+'-'+moduleId}></a>}
                    <RelatedRecipes data={data} relatedType={moduleData.relatedType} relatedSlug={moduleData.relatedSlug} />
                </div>
            }
            {data && moduleType == 'related_meatcuts' &&
                <div className={"module__meatcut "+moduleClass}>
                    {data.anchor && <a name={data.anchor+'-'+moduleId}></a>}
                    <MeatcutOverview data={data} />
                </div>
            }
            {data && moduleType == 'related_breeds' &&
                <div className={"module__breeds "+moduleClass}>
                    {data.anchor && <a name={data.anchor+'-'+moduleId}></a>}
                    <BreedsOverview data={data} items={data.items} />
                </div>
            }
            {data && moduleType == 'related_reviews' &&
                <div className={"module__reviews "+moduleClass}>
                    {data.anchor && <a name={data.anchor+'-'+moduleId}></a>}
                    <Reviews data={data} items={data.items} />
                </div>
            }
            {data && moduleType == 'recipe_highlight' &&
                <div className={moduleClass}>
                    {data.anchor && <a name={data.anchor+'-'+moduleId}></a>}
                    <RecipeHighlight data={data} items={data.items} />
                </div>
            }
            {data && moduleType == 'product_card' &&
                <div>
                    {data.anchor && <a name={data.anchor+'-'+moduleId}></a>}
                    <ProductHighlight data={data} items={data.items} moduleClass={moduleClass} />
                </div>
            }
            {data && moduleType == 'newsletter' && moduleConfig.position != 'popup' &&
                <Newsletter data={moduleConfig} />
            }
            {data && moduleType == 'newsletter' && moduleConfig.position == 'popup' &&
                <NewsletterPopup data={moduleConfig} />
            }
            {data && moduleType == 'navigator' &&
                <div className={"module__navigator "+moduleClass}>
                    {data.anchor && <a name={data.anchor+'-'+moduleId}></a>}
                    <Navigator data={data} />
                </div>
            }
            {data && moduleType == 'basic_recipe' &&
                <div className={moduleClass}>
                    {data.anchor && <a name={data.anchor+'-'+moduleId}></a>}
                    <BasicRecipe data={data.recipe && data.recipe} />
                </div>
            }
            {data && moduleType == 'farmshop_highlight' &&
                <div className={"module__farmshop-highlight "+moduleClass}>
                    {data.anchor && <a name={data.anchor+'-'+moduleId}></a>}
                    <FarmshopHighlight data={data} />
                </div>
            }
            {data && moduleType == 'blog_highlight' &&
                <div className={"module__blog_highlight "+moduleClass}>
                    {data.anchor && <a name={data.anchor+'-'+moduleId}></a>}
                    <BlogHighlight data={data} />
                </div>
            }
            {data && moduleType == 'popular_farmshop' &&
                <div className={"module__popular_farmshop "+moduleClass}>
                    {data.anchor && <a name={data.anchor+'-'+moduleId}></a>}
                    {<FarmshopPopularProducts data={data} />}
                </div>

            }
        </div>
    )

}

export default LoadModule
