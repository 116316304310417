import React, {Fragment, useState} from 'react'
import {currentLang} from './../../../utils/functions';
import {siteInfo} from './../../../utils/config';
import { Helmet } from 'react-helmet-async';


function SchemaFarmshopProducts({data}) {
    var canonicalurl = window.location.origin+window.location.pathname.replace(/\/$/, "");
    let jsonld = {
        "@context": "https://schema.org/",
        "@type": "Product",
        "name": data.title,
        "image": [data.image_url],
        "brand": {
            "@type": "Brand",
            "name": siteInfo[currentLang()]['domain']
        },
        "description": data.body ? (data.body?.replace(/<[^>]+>/g, '') || "") : "",
        "sku": data.slug,
        "offers": {
            "@type": "Offer",
            "url": canonicalurl,
            "priceCurrency": siteInfo[currentLang()]['currency'],
            "price": data.regular_price,
            "availability": data.stock_status == 'instock' ? "https://schema.org/InStock" : "https://schema.org/OutOfStock"
        }
    };

    return (
        <Helmet>
        <script type="application/ld+json">
            {JSON.stringify(jsonld)}
        </script>
        </Helmet>
    )
}

export default SchemaFarmshopProducts
