import ContentLoader from 'react-content-loader';
import React, { useState, useEffect } from 'react';

const SubscriptionCustomizeLoader = () => {
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 992);

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 992);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {isWideScreen ? (
        <ContentLoader viewBox="0 0 1420 1006" speed={2} backgroundColor="#F7F3E8" foregroundColor="#ffffff"  style={{ width: '100%' }}>
          <rect x="460" width="500" height="23" rx="5"/>
          <rect y="62" width="90" height="20" rx="5"/>
          <rect x="100" y="71" width="936" height="2" rx="1"/>
          <rect width="236" height="285" transform="translate(0 112)"/>
          <rect width="237" height="285" transform="translate(266 112)"/>
          <rect width="236" height="285" transform="translate(533 112)"/>
          <rect width="237" height="285" transform="translate(799 112)"/>
          <rect width="236" height="285" transform="translate(0 427)"/>
          <rect width="237" height="285" transform="translate(266 427)"/>
          <rect width="236" height="285" transform="translate(533 427)"/>
          <rect width="237" height="285" transform="translate(799 427)"/>
          <rect x="1086" y="112" width="334" height="182"/>
        </ContentLoader>
      ) : (
        <ContentLoader viewBox="0 0 600 1238" speed={2} backgroundColor="#F7F3E8" foregroundColor="#ffffff"  style={{ width: '100%' }}>
          <rect x="50" width="500" height="23" rx="5"/>
          <rect y="70" width="90" height="20" rx="5"/>
          <rect x="100" y="79" width="500" height="2" rx="1"/>
          <rect width="285" height="350" transform="translate(0 120)"/>
          <rect width="285" height="350" transform="translate(315 120)"/>
          <rect width="285" height="350" transform="translate(0 504)"/>
          <rect width="285" height="350" transform="translate(315 504)"/>
          <rect width="285" height="350" transform="translate(0 888)"/>
          <rect width="285" height="350" transform="translate(315 888)"/>
        </ContentLoader>
      )}
    </>
  );
};

export default SubscriptionCustomizeLoader;