import React, {useState, useEffect, Fragment} from 'react'
import parse from 'html-react-parser';
import Image from '../../common/Image'
import {isNil, toNumber} from "lodash";
import W from '../../common/Word';
import { useTranslation } from 'react-i18next';
import { currentLang } from '../../../utils/functions';

import imgSm from './img/freezer-img-sm.jpg';
import imgSmWebp from './img/freezer-img-sm.webp';
import vecotrSm from './img/freezer-sm.svg';

import imgMd from './img/freezer-img-md.jpg';
import imgMdWebp from './img/freezer-img-md.webp';
import vecotrMd from './img/freezer-md.svg';

import imgLg from './img/freezer-img-lg.jpg';
import imgLgWebp from './img/freezer-img-lg.webp';
import vecotrLg from './img/freezer-lg.svg';

const Freezer = ({config, weight}) => {
    const [item, setItem] = useState();
    const { t } = useTranslation();
    const data = t("freezer", {'returnObjects': true});

    const getNumberFromString = str => {
        if (!str) {
            console.error("Input is empty or not provided:", str);
            return null; // Or some default value if you prefer
        }

        // Coerce to string if it's not already a string
        str = String(str);

        var regex = /[+-]?\d+(\.\d+)?(\,\d+)?/g;
        var matches = str.match(regex);

        if (!matches) {
            console.error("No matches found in string:", str);
            return null; // Or some default value if you prefer
        }

        var floats = matches.map(function(v) {
            return parseFloat(v.replace(',', '.'));
        });

        return toNumber(floats);
    }

    useEffect(() => {
        if(weight !== undefined){
            //weight = toNumber(weight.replace(' KG', '').replace(',', '.'));
            weight = getNumberFromString(weight);

            if(weight <= toNumber(data.kg4.size)){
                setItem(data.kg4);
            }else{
                if(weight >= toNumber(data.kg8.size)){
                    setItem(data.kg8);
                }else{
                    setItem(data.kg6);
                }
            }
        }
        if(config && config !== 'automatic'){
            if(config == 'large')
                setItem(data.kg8);
            else if(config == 'medium')
                setItem(data.kg6);
            else
                setItem(data.kg4);
        }

    }, [weight])


    return (
        <section className="freezer-fit bg--gray_light">
            <div className="container">
                <div className="row align-items-center justify-content-center">
                    <div className="col-12 col-md-6 col-lg-6 col-xl-5">
                        <div className="freezer-fit__img d-none d-md-block">
                            {/* small package */}
                            {item && item.active == 1 &&
                                <picture>
                                    <source srcSet={imgSmWebp} type="image/webp" />
                                    <source srcSet={imgSm} type="image/jpeg" />
                                    <img src={imgSm} className={"img-fluid"} alt={(t("freezer.image.pic")) + ((weight) ? weight : (config) ? item && item.size+' KG' : '')} title={(t("freezer.image.pic")) + ((weight) ? weight : (config) ? item && item.size+' KG' : '')} />
                                </picture>
                            }
                            {/* medium package */}
                            {item && item.active == 2 &&
                                <picture>
                                    <source srcSet={imgMdWebp} type="image/webp" />
                                    <source srcSet={imgMd} type="image/jpeg" />
                                    <img src={imgMd} className={"img-fluid"} alt={(t("freezer.image.pic")) + ((weight) ? weight : (config) ? item && item.size+' KG' : '')} title={(t("freezer.image.pic")) + ((weight) ? weight : (config) ? item && item.size+' KG' : '')} />
                                </picture>
                            }
                            {/* large package */}
                            {item && item.active == 3 &&
                                <picture>
                                    <source srcSet={imgLgWebp} type="image/webp" />
                                    <source srcSet={imgLg} type="image/jpeg" />
                                    <img src={imgLg} className={"img-fluid"} alt={(t("freezer.image.pic")) + ((weight) ? weight : (config) ? item && item.size+' KG' : '')} title={(t("freezer.image.pic")) + ((weight) ? weight : (config) ? item && item.size+' KG' : '')} />
                                </picture>
                            }
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6 col-xl-5">
                        <div className="freezer-fit__content">
                            <div className="weight">{(weight) ? weight : (config) ? item && item.size+' KG' : ''}</div>
                            <h2 className="heading--h4">{item && parse(item.title)}</h2>
                            <p className="desc">
                                {item && parse(item.body)}
                            </p>
                            <div className="vector">
                                {/* small package */}
                                {item && item.active == 1 &&
                                    <Image src={vecotrSm} className="img-fluid" alt={(t("freezer.image.shape")) + ((weight) ? weight : (config) ? item && item.size+' KG' : '')} title={(t("freezer.image.shape")) + " " + ((weight) ? weight : (config) ? item && item.size+' KG' : '')} />
                                }
                                {/* medium package */}
                                {item && item.active == 2 &&
                                    <Image src={vecotrMd} className="img-fluid" alt={(t("freezer.image.shape")) + ((weight) ? weight : (config) ? item && item.size+' KG' : '')} title={(t("freezer.image.shape")) + " " + ((weight) ? weight : (config) ? item && item.size+' KG' : '')} />
                                }
                                {/* large package */}
                                {item && item.active == 3 &&
                                    <Image src={vecotrLg} className="img-fluid" alt={(t("freezer.image.shape")) + ((weight) ? weight : (config) ? item && item.size+' KG' : '')} title={(t("freezer.image.shape")) + " " + ((weight) ? weight : (config) ? item && item.size+' KG' : '')} />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Freezer
