/*
Meta info and Static html for Farmers list, Recipes List, and Breeds List
*/
import {Fragment} from 'react';
import flatstore from 'flatstore';
import parse from 'html-react-parser';
import Meta from '../../../components/common/Meta';

function ListStatic({data,curPage,filterType}) {
    return (
        <div className="row justify-content-center">
            {data &&
                <Fragment>
                <Meta
                    title={data.seo_title ? data.seo_title : data.title}
                    description={data.seo_description ? data.seo_description : data.body}
                    robots={curPage > 1 || filterType ? "noindex,follow" : "index,follow"}
                />
                {data.body &&
                    <div className="col-12 col-xxl-6 col-xl-8 mt-6 mb-2 top-page">
                        <h1 className="heading--h1">{data.title}</h1>
                        <p className='m-0'> {parse(data.body)}</p>
                    </div>
                }
                </Fragment>
            }
        </div>
    )
}
export default ListStatic
