import ContentLoader from 'react-content-loader';
import React, { useState, useEffect } from 'react';

const RecipeDetailLoader = () => {
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 992);

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 992);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {isWideScreen ? (
        <ContentLoader viewBox="0 0 1420 985" speed={2} backgroundColor="#d9d9d9" foregroundColor="#ededed"  style={{ width: '100%' }}>
          <rect x="40" y="40" width="400" height="28" rx="5"/>
          <rect x="40" y="80" width="615" height="8" rx="4"/>
          <rect x="40" y="100" width="615" height="8" rx="4"/>
          <rect x="40" y="120" width="615" height="8" rx="4"/>
          <rect x="40" y="140" width="249" height="8" rx="4"/>
          <rect x="40" y="185" width="101" height="14" rx="5"/>
          <rect x="40" y="218" width="43" height="14" rx="5"/>
          <rect x="292" y="185" width="82" height="14" rx="5"/>
          <rect x="292" y="218" width="130" height="14" rx="5"/>
          <rect x="707" width="713" height="413"/>
          <rect y="411" width="707" height="2"/>
          <rect width="2" height="413"/>
          <rect width="707" height="2"/>
          <rect x="100.5" y="483" width="190" height="24" rx="4"/>
          <rect x="60" y="537" width="69" height="14" rx="5"/>
          <rect x="144" y="537" width="140" height="14" rx="5"/>
          <rect x="69" y="567" width="60" height="14" rx="5"/>
          <rect x="143" y="567" width="89" height="14" rx="5"/>
          <rect x="40" y="597" width="89" height="14" rx="5"/>
          <rect x="143" y="597" width="48" height="14" rx="5"/>
          <rect x="99" y="627" width="30" height="14" rx="5"/>
          <rect x="144" y="627" width="140" height="14" rx="5"/>
          <rect x="66" y="657" width="63" height="14" rx="5"/>
          <rect x="144" y="657" width="214" height="14" rx="5"/>
          <rect x="55" y="687" width="74" height="14" rx="5"/>
          <rect x="143" y="687" width="102" height="14" rx="5"/>
          <rect x="25" y="717" width="104" height="14" rx="5"/>
          <rect x="143" y="717" width="151" height="14" rx="5"/>
          <rect x="99" y="747" width="30" height="14" rx="5"/>
          <rect x="144" y="747" width="140" height="14" rx="5"/>
          <rect y="463" width="391" height="2"/>
          <rect y="779" width="391" height="2"/>
          <rect y="463" width="2" height="318"/>
          <rect x="389" y="463" width="2" height="318"/>
          <rect x="885.5" y="463" width="190" height="24" rx="4"/>
          <circle cx="575" cy="536" r="19"/>
          <rect x="604" y="530" width="302" height="12" rx="4"/>
          <rect x="541" y="502" width="879" height="2"/>
          <rect x="541" y="568" width="879" height="2"/>
          <rect x="541" y="502" width="2" height="68"/>
          <rect x="1418" y="502" width="2" height="68"/>
          <circle cx="575" cy="619" r="19"/>
          <rect x="604" y="604" width="801" height="12" rx="4"/>
          <rect x="604" y="622" width="174" height="12" rx="4"/>
          <rect x="541" y="585" width="879" height="2"/>
          <rect x="541" y="651" width="879" height="2"/>
          <rect x="541" y="585" width="2" height="68"/>
          <rect x="1418" y="585" width="2" height="68"/>
          <circle cx="575" cy="702" r="19"/>
          <rect x="604" y="696" width="558" height="12" rx="4"/>
          <rect x="541" y="668" width="879" height="2"/>
          <rect x="541" y="734" width="879" height="2"/>
          <rect x="541" y="668" width="2" height="68"/>
          <rect x="1418" y="668" width="2" height="68"/>
          <circle cx="575" cy="785" r="19"/>
          <rect x="604" y="770" width="801" height="12" rx="4"/>
          <rect x="604" y="788" width="174" height="12" rx="4"/>
          <rect x="541" y="751" width="879" height="2"/>
          <rect x="541" y="817" width="879" height="2"/>
          <rect x="541" y="751" width="2" height="68"/>
          <rect x="1418" y="751" width="2" height="68"/>
          <circle cx="575" cy="868" r="19"/>
          <rect x="604" y="861" width="302" height="12" rx="4"/>
          <rect x="541" y="834" width="879" height="2"/>
          <rect x="541" y="900" width="879" height="2"/>
          <rect x="541" y="834" width="2" height="68"/>
          <rect x="1418" y="834" width="2" height="68"/>
          <circle cx="575" cy="951" r="19"/>
          <rect x="604" y="945" width="558" height="12" rx="4"/>
          <rect x="541" y="917" width="879" height="2"/>
          <rect x="541" y="983" width="879" height="2"/>
          <rect x="541" y="917" width="2" height="68"/>
          <rect x="1418" y="917" width="2" height="68"/>
        </ContentLoader>
      ) : (
        <ContentLoader viewBox="0 0 600 1011" speed={2} backgroundColor="#d9d9d9" foregroundColor="#ededed"  style={{ width: '100%' }}>
          <rect width="600" height="206"/>
          <rect x="30" y="236" width="400" height="28" rx="5"/>
          <rect x="30" y="276" width="540" height="8" rx="4"/>
          <rect x="30" y="296" width="540" height="8" rx="4"/>
          <rect x="30" y="316" width="540" height="8" rx="4"/>
          <rect x="30" y="336" width="249" height="8" rx="4"/>
          <rect x="30" y="381" width="101" height="14" rx="5"/>
          <rect x="30" y="414" width="43" height="14" rx="5"/>
          <rect x="222" y="381" width="82" height="14" rx="5"/>
          <rect x="222" y="414" width="130" height="14" rx="5"/>
          <rect y="456" width="600" height="2"/>
          <rect y="206" width="2" height="252"/>
          <rect x="598" y="206" width="2" height="252"/>
          <rect x="205" y="508" width="190" height="24" rx="4"/>
          <rect x="164.5" y="562" width="69" height="14" rx="5"/>
          <rect x="248.5" y="562" width="140" height="14" rx="5"/>
          <rect x="173.5" y="592" width="60" height="14" rx="5"/>
          <rect x="247.5" y="592" width="89" height="14" rx="5"/>
          <rect x="144.5" y="622" width="89" height="14" rx="5"/>
          <rect x="247.5" y="622" width="48" height="14" rx="5"/>
          <rect x="203.5" y="652" width="30" height="14" rx="5"/>
          <rect x="248.5" y="652" width="140" height="14" rx="5"/>
          <rect x="170.5" y="682" width="63" height="14" rx="5"/>
          <rect x="248.5" y="682" width="214" height="14" rx="5"/>
          <rect x="159.5" y="712" width="74" height="14" rx="5"/>
          <rect x="247.5" y="712" width="102" height="14" rx="5"/>
          <rect x="129.5" y="742" width="104" height="14" rx="5"/>
          <rect x="247.5" y="742" width="151" height="14" rx="5"/>
          <rect y="774" width="600" height="2"/>
          <rect y="488" width="600" height="2"/>
          <rect y="488" width="2" height="288"/>
          <rect x="598" y="488" width="2" height="288"/>
          <rect x="205" y="806" width="190" height="24" rx="4"/>
          <circle cx="300" cy="857" r="19"/>
          <circle cx="300" cy="958" r="19"/>
          <rect x="80" y="882" width="440" height="12" rx="4"/>
          <rect x="80" y="983" width="440" height="12" rx="4"/>
          <rect x="213" y="900" width="174" height="12" rx="4"/>
          <rect y="856" width="600" height="2"/>
          <rect y="957" width="600" height="2"/>
          <rect y="922" width="600" height="2"/>
          <rect y="1009" width="600" height="2"/>
          <rect y="856" width="2" height="68"/>
          <rect y="957" width="2" height="54"/>
          <rect x="598" y="856" width="2" height="68"/>
          <rect x="598" y="957" width="2" height="54"/>
        </ContentLoader>
      )}
    </>
  );
};

export default RecipeDetailLoader;