import React, { useState, useEffect, Fragment } from 'react';
import flatstore from 'flatstore';
import { getBasketCount } from '../../../../utils/services';
import { currentLang } from '../../../../utils/functions';
import W from '../../../common/Word';
import { useTranslation } from 'react-i18next';
import { isNil } from 'lodash';

const Basket = ({ basket_count, basket_status }) => {
    const [count, setCount] = useState(null);
    const { t } = useTranslation();

    useEffect(() => {
        var data = flatstore.get('basket_count');
        setCount(data?.count);

        if (isNil(basket_count)) {
            if (['nl', 'de'].includes(currentLang())) {
                getBasketCount();
            }
        } else {
            if (isNil(count) && basket_count.count) {
                setCount(basket_count.count);
            }
        }
    }, [basket_count]);

    return (
        <Fragment>
            {basket_status ? (
                // Display loading spinner while loading
                <a title={t('general.checkout')} className="basket-link is-loading">
                    <span className="basket">
                        <i className="icon-basket"></i>
                        <i className="icon-basket2 d-none"></i>
                        <span className="basket-count is-loading"></span>
                    </span>
                    <span className="text"> <W>general.checkout</W> </span>
                </a>
            ) : (
                <a href={'/' + currentLang() + '/shop/'} title={t('general.checkout')} className="basket-link">
                    <span className="basket">
                        <i className="icon-basket"></i>
                        <i className="icon-basket2 d-none"> </i>
                        {count > 0 ? <span className="basket-count">{count}</span> : null}
                    </span>
                    <span className="text"> <W>general.checkout</W> </span>
                </a>
            )}
        </Fragment>
    );
};

export default flatstore.connect(['basket_count', 'basket_status'])(Basket);
