import React, {Fragment} from 'react'
import W from '../../common/Word';
import SchemaRecipe from '../../common/Meta/SchemaRecipe';
import parse from 'html-react-parser';
import Image from '../../../components/common/Image';
import {Link} from 'react-router-dom';

function BasicRecipe({data}) {

  const isExternalURL = (url) => {
    return url.includes('http');
  }

  return (
    <Fragment>
        {data &&
          <SchemaRecipe data={data} />
        }
        {data &&
        <div className='meatcut__baking'>
            <div className='anchor-box'>
                <a name="cooking-steps-765"></a>
            </div>
            <div className='container py-6'>
                <div className='row'>
                    <div className='col text-center pb-4'>
                        <h2 className='heading--h2'>{data.title && data.title}</h2>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-3 col-md-4 mb-4 mb-md-0'>
                        <div className='metadata'>
                            {data.portion_amount &&
                                <div className='itm'>
                                    <i className='icon-user'></i> {data.portion_amount}
                                </div>
                            }
                            {data.prep_time &&
                                <div className='itm'>
                                    <i className='icon-time'></i> {data.prep_time} <W>basic_recipe.prep_time</W>
                                </div>
                            }
                            {data.cook_time &&
                                <div className='itm'>
                                    <i className='icon-time'></i> {data.cook_time} <W>basic_recipe.cook_time</W>
                                </div>
                            }
                        </div>
                        {data.ingredients && data.ingredients.length > 0 &&
                            <div className='metadata-box'>
                                <h3 className='heading--h6 mb-4 text--center'><W>recipes.ingredients</W></h3>
                                {data.ingredients.map((item, index) => {
                                    var ing_title = item.url ? (isExternalURL(item.url)) ? <a href={item.url} key={"ing-link"+index}>{item.title && parse(item.title)}</a> : <Link key={"ing-link"+index} to={item.url}>{item.title && parse(item.title)}</Link> : item.title && parse(item.title);
                                    return (
                                    <div key={'basic_recipe_ing_'+index} className='recipe-ingredient-item'>
                                        <span className='amount'>{item.amount && item.amount}</span>
                                        <span className='name'>{ing_title}</span>
                                    </div>
                                    )
                                })}
                            </div>
                        }
                        {data.requirements && data.requirements.length > 0 &&
                            <div className='metadata-box'>
                                <h3 className='heading--h6 mb-4 text--center'><W>basic_recipe.items</W></h3>
                                <ul className='list'>
                                    {data.requirements.map((item, index) => {
                                        return ( <li key={"basic_recipe_items_"+index}>{item.body && item.body}</li>)
                                    })}
                                </ul>
                            </div>
                        }


                    </div>
                    {data.instructions && data.instructions.length > 0 &&
                    <div className='col-lg-8 col-md-7 offset-md-1'>
                            <h3 className='heading--h6 mb-4'><W>basic_recipe.instruction</W></h3>
                            <ul className='recipe-steps'>
                                {data.instructions.map((item, index) => {
                                    return(
                                        <li key={"basic_recipe_isn_"+index} className={item.image_url ? 'with-image' : ''}>
                                            {item.image_url &&
                                            <div className='image'>
                                                <Image src={item.image_url} width="200" height="100" className="img-fluid" alt={item.body && parse(item.body).substr(0, 50)} title={item.body && parse(item.body).substr(0, 50)} />
                                            </div>
                                            }
                                            <div className='number'>
                                                <span>{index+1}</span>
                                            </div>
                                            <div className='text'>
                                                {item.body && item.body}
                                            </div>
                                        </li>
                                    )
                                })}
                            </ul>
                    </div>
                    }
                </div>
            </div>
        </div>
        }
        </Fragment>
  )
}

export default BasicRecipe
