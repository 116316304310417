import React, {useEffect, useState, Fragment} from 'react';
import flatstore from 'flatstore';
import parse from 'html-react-parser';
import {getFAQ, getStaticPage} from '../../utils/services';
import DataFilter from '../../components/common/DataFilter'
import LoadModules from '../../components/modules/LoadModules';
import Meta from '../../components/common/Meta';
import SchemaFAQ from '../../components/common/Meta/SchemaFAQ';
import {isNull, isNil, isEmpty} from 'lodash';
import W from '../../components/common/Word';
import { useTranslation } from 'react-i18next';
import SearchState from '../../components/common/SearchState';
import { accentsTidy, listFilter } from '../../utils/functions';
import ListStatic from '../inc/ListStatic';
import {useLocation, useParams} from 'react-router-dom';
import FAQLoader from '../../assets/contentLoader/FAQLoader';


const Faq = ({loading, faq_data, faq_static, list_filter_type, list_search_status, list_filtered_data, list_active_page}) => {
  const [data, setData] = useState();
  const [staticData, setStaticData] = useState();
  const [filteredData, setFilteredData] = useState([]);
  const [modules, setModules] = useState(null);
  const [filterType, setFilterType] = useState([]);
  const [searchStatus, setSearchStatus] = useState(null);
  const { t, i18n } = useTranslation();
  const {pathname} = useLocation();
  let {filter} = useParams();
  if(isNil(filter)){
    filter = "general";
  }else{
     filter = t(filter);
  }
  const type = 'faq';

  useEffect(() => {
    if (isNil(faq_data)) {

        //setFilterType('General');
      setFilterType(filter);
      flatstore.set("list_filter_type", filter);
      flatstore.set('list_active_page', 1);

      getFAQ();
      getStaticPage('faq', 'faq_static');
    }
    if(faq_data && !isNil(faq_data.data)){
      setData(faq_data.data);
      listFilter(type);
    }
  }, [faq_data]);

  useEffect(() => {
		if(!isNil(faq_static)){
			setStaticData(faq_static);
			if(faq_static.modules && faq_static.modules.module !== null){
				setModules(faq_static.modules);
			}
		}
  }, [faq_static])

  useEffect(() => {
		if(list_filtered_data){
			setFilteredData(list_filtered_data);
		}
	}, [list_filtered_data])

	useEffect(() => {
		if(list_filter_type && faq_data){
			setFilterType(list_filter_type);
			listFilter(type);
    }
	}, [list_filter_type])

  useEffect(() => {
    if(!isNil(filter)){
      flatstore.set('list_filter_type', filter);
    }else{
      flatstore.set('list_filter_type', 'general');
    }
    listFilter(type)
  }, [pathname])

  useEffect(() => {
		setSearchStatus(list_search_status);
	}, [list_search_status])

  if (loading) {
		return (
			<div className="container mt-5 mb-5">
				<div className="row">
					<div className="col-12">
						<FAQLoader />
					</div>
				</div>
			</div>
		)
  } else {
    return (
      <Fragment>
      <LoadModules modules={modules} type="top" />
      <div className="container">

        {staticData &&
          <ListStatic data={staticData} />
        }
        {data &&
          <SchemaFAQ data={data} />
        }
				<DataFilter type={type} filterType={filterType} data={data} filteredData={filteredData} staticData={staticData} />

          <div className="row">
          <div className="col-12 col-md-7 mb-80">
            <div className="">

              {filteredData && filteredData.map((item, index) => {
                  return (<div key={index} className="faq__item">
                    <details>
                      <summary> <h2 className='question'>{item.question}</h2> </summary>
                      <div className="faq__body">{item.answer && parse(item.answer)}</div>
                    </details>
                  </div>)
                })
              }
              <SearchState status={searchStatus} />
            </div>

          </div>

          <div className="col-12 col-md-4 offset-md-1 mb-80">

              <div className="contact__box">

                <div className="contact__item">
                  <h2 className="heading--h5 m-0"><W>faq.contact.title</W></h2>
                  {t('faq.contact.body') != "" &&
                    <p className="mb-0 mt-2">
                      <W>faq.contact.body</W>
                    </p>
                  }
                </div>
                {t('contact.opening_hours') != "" &&
                  <div className="contact__item contact--lefticon">
                      <i className="icon-time"></i>
                      <span> <W>contact.opening_hours</W> </span>
                  </div>
                }
                {t('contact.phone') != "" &&
                  <a href={t("contact.phone_url")} className="contact__item contact--lefticon">
                    <i className="icon-phone"></i> <W>contact.phone</W>
                  </a>
                }
                <a href={t("contact.email_url")} className="contact__item contact--lefticon">
                  <i className="icon-email"></i> <W>contact.email</W>
                </a>
                {t('contact.facebook_url') != "" &&
                  <a target="_blank" href={t("contact.facebook_url")} className="contact__item contact--lefticon">

                    <i className="icon-facebook"></i> <W>contact.facebook</W>
                  </a>
                }
                {t('contact.twitter_url') != "" &&
                  <a target="_blank" href={t("contact.twitter_url")} className="contact__item contact--lefticon">
                    <i className="icon-twitter"></i> <W>contact.twitter</W>
                  </a>
                }
                {t('contact.instagram_url') != "" &&
                  <a target="_blank" href={t("contact.instagram_url")} className="contact__item contact--lefticon">
                    <i className="icon-instagram"></i> <W>contact.instagram</W>
                  </a>
                }

              </div>
              <div className="mt-30">
                <div className="heading--h5 mb-2"> <W>faq.company.name</W> </div>
                <p>
                  <W>faq.company.address</W>
                </p>
              </div>

          </div>
        </div>
      </div>
      <LoadModules modules={modules} type="bottom" />
      </Fragment>
    )
  }
}

export default flatstore.connect(['loading', 'faq_data', 'faq_static', 'list_search_status', 'list_filtered_data', 'list_filter_type', 'list_active_page'])(Faq);
