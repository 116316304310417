import React, {Fragment, useState} from 'react'
import {currentLang} from './../../../utils/functions';
import {siteInfo} from './../../../utils/config';
import {truncate} from 'lodash';
import { Helmet } from 'react-helmet-async';

function SchemaFAQ({data}) {
    var questions = [];
    if(data){
        data.map(item=>{
            questions.push({
                "@type": "Question",
                "name": item.question,
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": item.answer ? item.answer.replace(/<[^>]+>/g, '') : ""
                }
            });
        });
    }
    let jsonld = {
        "@context": "https://schema.org/",
        "@type": "FAQPage",
        "mainEntity": questions
    };

    return (
        <Helmet>
        <script type="application/ld+json">
            {JSON.stringify(jsonld)}
        </script>
        </Helmet>
    )
}

export default SchemaFAQ
