import React, {useState, useEffect, Fragment} from 'react'
import { useTranslation } from 'react-i18next';
import { currentLang } from '../../utils/functions';
import Price from '../../components/product/common/Price';
import W from '../../components/common/Word';

const CouponItem = ({data}) => {
    const [currentItem, setCurrentItem] = useState(null);
    const [shopURL, setShopURL] = useState(null);
    const [num, setNum] = useState(1);
    const { t } = useTranslation();

    useEffect(() => {
        setCurrentItem(data.variations[0]);
    }, [])

    useEffect(() => {
        if(currentItem)
            setShopURL("/"+currentLang()+"/shop/?add-to-cart="+currentItem.coupon_shop_variation_id+"&quantity="+num);
    }, [num, currentItem])

    const plus = () =>{
        setNum(num+1);
    }

    const minus = () =>{
        if(num > 1){
            setNum(num-1);
        }
    }

    const selectItem = (value) =>{
        data.variations.map(item=>{
            if(item.coupon_shop_variation_id == value)
                setCurrentItem(item);
        });
    }


    return (
        <Fragment>
        {currentItem && shopURL &&
        <div className="col-xs-12 col-sm-6 col-lg-4">
            <div className={"coupons__item "+currentLang()}>
                <div className="cover">
                    <div className="text">
                        <h4 className="name"><W>menu.coupons</W></h4>
                    </div>
                </div>
                <div className="body">
                    <div className="coupons__price">
                        <div className="price">
                            <div className="holder">
                                <Price value={data.visual_price} withCurrency="1" />
                            </div>
                        </div>
                        <div className="quantity">
                            <div className="product-add">
                                <span onClick={minus}> <i className="icon-minus"></i> </span>
                                    {num}
                                <span onClick={plus}> <i className="icon-plus"></i> </span>
                            </div>
                        </div>
                    </div>
                    <p className="excerpt">
                        {data.excerpt}
                    </p>
                    <div className="coupons__type">
                    <h4 className="title">{t("product.deliverymethod")}</h4>
                    {data.variations.map((value, index)=>{
                        var selectedClass = "";
                        if(value.coupon_shop_variation_id == currentItem.coupon_shop_variation_id)
                            selectedClass = " selected";
                        return(
                            <div className={"item"+selectedClass} onClick={()=>{selectItem(value.coupon_shop_variation_id)}} key={'coupons_'+index}>
                                <h4>{t("product."+value.delivery_method)}</h4>
                            </div>
                        )
                    })}
                    </div>
                    <a className="btn btn--primary btn--full" href={shopURL}>{t("product.buycoupon")}</a>
                </div>
            </div>
        </div>
        }
        </Fragment>
    )
}

export default CouponItem
