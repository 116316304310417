import React, { useState, Fragment }from 'react'
import { animalPercent } from '../../../../utils/functions';
import W from '../../../common/Word';
import {isNil, upperFirst} from 'lodash';
import { useTranslation } from 'react-i18next';

const ProfileBuyer = ( {totalBuyers, totalPackages, soldPercentage, animalType } ) => {
  const [toggled, setToggled] = useState(false)
  const { t } = useTranslation();
  if(totalBuyers < 1){
    totalBuyers = 1;
  }
  function HandleTooltipPopup(event) {
    if(window.innerWidth < 992){
      //Array.prototype.forEach.call(document.querySelectorAll('.open'), (el)=>el.classList.remove('open'))
      setToggled(!toggled)
    }
  }

  let toggledClass = (toggled) ? "open" : "";

  var totalBuyers2 = (totalPackages > 45) ? Math.ceil(parseInt(totalBuyers)/2) : totalBuyers;
  var totalPackages2 = (totalPackages > 45) ? Math.ceil(parseInt(totalPackages)/2) : totalPackages;

  let translateType = 'default';
  /*if (animalType == 'chicken'){
    translateType = animalType;
  };*/

  const finalNumber = parseInt(totalPackages2)-parseInt(totalBuyers2);

    return (
      <div className="item buyers-3 packages-remaining d-none">
      <div className={`buyer tooltip tooltip--center`} onClick={HandleTooltipPopup}>
        <div className={`profile tooltip__info ${toggledClass}`}>
          <a className="tooltip__info--link" ></a>
          <div className="avatar">
            <i className="icon-package"></i>
          </div>
          <div className={`tooltip__content tooltip__buyers`}>
            <a className="icon-close align__right" onClick={HandleTooltipPopup}></a>
            <div className="tooltip__content--info buyers__info">
              <div className="row">
                <div className="col-4 tooltip__content--icon buyers__info">
                  <span className="icon-users"></span>
                </div>
                <div className="col-8 tooltip__content--list buyers__info">
                  <ul className="list list--no-list">
                    <li className="buyers--sold">{upperFirst(t('product.sold'))}: <strong>{animalPercent(soldPercentage)}</strong>% </li>
                    <li className="buyers--buyers"><W>product.numberofbuyers.{translateType}</W>: <strong>{totalBuyers2}</strong> </li>
                    <li className="buyers--packages"><W>product.meatboxavailable.{translateType}</W>:
                      <strong>
                        {finalNumber}
                      </strong>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tooltip__title">
          <span className="title">
            <strong>{finalNumber}</strong> {t('product.abtest.packagesRemaining.'+translateType, {count:finalNumber, postProcess:'interval'} )}
          </span>
        </div>

      </div>
    </div>
    )
}

export default ProfileBuyer
