import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/locale-data/nl';  // for Dutch
import '@formatjs/intl-pluralrules/locale-data/de';  // for German
import '@formatjs/intl-pluralrules/locale-data/fr';  // for French
import '@formatjs/intl-pluralrules/locale-data/en';  // for English (assuming 'uk' is meant to represent English, typically 'en' is used for English)
import i18n  from 'i18next';
import { initReactI18next } from 'react-i18next';
import XHR from 'i18next-http-backend';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import {currentLang} from '../utils/functions';
import nljson from '../assets/translations/nl.json';
import enjson from '../assets/translations/en.json';
import frjson from '../assets/translations/fr.json';
import dejson from '../assets/translations/de.json';

intervalPlural.setOptions({
  intervalSeparator: ';',
  intervalRegex: /\((\S*)\).*{((.|\n)*)}/,
  intervalSuffix: '_interval'
});

i18n.use(XHR)
  .use(initReactI18next)
  .use(intervalPlural)
  .init({
    lng: currentLang(),
    resources: {
      nl: {
        translation: nljson
      },
      uk: {
        translation: enjson
      },
      fr: {
        translation: frjson
      },
      de: {
        translation: dejson
      }
    },
    fallbackLng: currentLang(),
    debug: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ','
    },
    react: {
        useSuspense: true
    }
  });

export default i18n;