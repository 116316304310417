import React, { useEffect } from 'react';
import flatstore from 'flatstore';
import FarmerList from './FarmerList';
import FarmerDetail from './FarmerDetail';
import { includes, isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
import { currentLang } from '../../utils/functions';
import { siteInfo } from './../../utils/config';
import { useParams } from 'react-router-dom';

const Farmers = () => {
    const { t } = useTranslation();
    const animals = siteInfo[currentLang()]['animal_types'];
    let { slug, page } = useParams();
    let filter = null;
    let isDetailPage = true;

    if (slug) {
        if (parseInt(slug) > 0) {
            page = slug;
            isDetailPage = false;
        }
        if (includes(animals, t(slug))) {
            filter = t(slug);
            isDetailPage = false;
        }
    } else {
        isDetailPage = false;
    }

    useEffect(() => {
        if (!isDetailPage) {
            flatstore.set('list_active_page', page);
            if (filter) flatstore.set('list_filter_type', filter);
        }
    }, [isDetailPage, page, filter]);

    if (isDetailPage) {
        filter = page;
        return <FarmerDetail slug={slug} animal={filter} />;
    } else {
        return <FarmerList page={page ? page : 1} filter={filter} />;
    }
};

export default Farmers;