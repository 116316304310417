import React, {useEffect, useState, Fragment} from 'react';
import Pagination from '../../components/common/Pagination';
import flatstore from 'flatstore';
import parse from 'html-react-parser';
import Loader from '../../components/common/Loader';
import LoadModules from '../../components/modules/LoadModules';
import {getBreeds, getStaticPage} from '../../utils/services';
import DataFilter from '../../components/common/DataFilter';
import {currentLang, accentsTidy, breedURL, listFilter} from '../../utils/functions';
import {isNull, isNil} from 'lodash';
import { useTranslation } from 'react-i18next';
import SearchState from '../../components/common/SearchState';
import BreedItem from './BreedItem';
import ListStatic from '../inc/ListStatic';

const BreedsList = ({loading, breeds_data, breeds_list_static, page, filter, list_filter_type, list_search_status, list_filtered_data, list_active_page}) => {
	const [data, setData] = useState();
	const [filteredData, setFilteredData] = useState([]);
	const [staticData, setStaticData] = useState();
	const [modules, setModules] = useState(null);
	const [filterType, setFilterType] = useState(null);
	const [activePage, setActivePage] = useState(1);
	const [searchStatus, setSearchStatus] = useState(null);
	const { t } = useTranslation();
	
	const type = "breeds";

	useEffect(() => {
		if (isNil(breeds_data)) {
			getBreeds();
			getStaticPage('breeds', 'breeds_list_static');
		}

		if(breeds_data){
			setData(breeds_data.data);
			listFilter(type);
		}
		
	}, [breeds_data]);

	useEffect(() => {
		if(!isNil(breeds_list_static)){
			setStaticData(breeds_list_static);
			if(!isNil(breeds_list_static.modules) && !isNil(breeds_list_static.modules.module)){
				setModules(breeds_list_static.modules);
			}
		}
	}, [breeds_list_static])
	
	useEffect(() => {
		setFilteredData(list_filtered_data)
	}, [list_filtered_data])

	useEffect(() => {
		if(list_filter_type != filterType){
			setFilterType(list_filter_type);
			listFilter(type);
		}
	}, [list_filter_type])

	useEffect(() => {
		setActivePage(activePage);
		listFilter(type);
	}, [list_active_page])

	useEffect(() => {
		setSearchStatus(list_search_status);
	}, [list_search_status])


	if (loading) {
		return <Loader />
	} else {

		return (
			<Fragment>
			{modules && 
				<LoadModules modules={modules} type="top" />
			}
			<div className="container">
							
				{staticData && 
					<ListStatic data={staticData} curPage={list_active_page} filterType={filterType} />
				}

				<DataFilter type={type} filterType={filterType} data={data} />

				<div className="row">
					{filteredData && filteredData.map((item, index)=>{
						return (
							<div key={index} className="col-xs-12 col-md-6 col-lg-4">
								<BreedItem key={index} data={item} type={filterType} animal={t(filterType)} />
							</div>
						)
					})}
				</div>

				<SearchState status={searchStatus} />
				
				{data && filteredData && activePage && 
					<Pagination url={breedURL('')} data={filteredData} curPage={list_active_page} filterType={filterType} />
				}

			
			
		</div>
		{modules &&
			<LoadModules modules={modules} type="bottom" />
	    }
		</Fragment>



		)
	}
}

export default flatstore.connect(['loading', 'breeds_data', 'breeds_list_static', 'list_search_status', 'list_filtered_data', 'list_filter_type', 'list_active_page'])(BreedsList);