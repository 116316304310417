import React, { useState, Fragment } from 'react'
import Price from '../../../components/product/common/Price'
import Image from '../../../components/common/Image'
import FarmshopCartButton from './button'
import { Link } from 'react-router-dom'
import {farmshopProductURL, currentLang} from '../../../utils/functions'
import W from '../../../components/common/Word'
import { useTranslation } from 'react-i18next'


function FarmshopProductBox({data, quantity, addToCartCallback, view_button=false}) {
    const { t } = useTranslation();


    const productDateChecker = (date, daysBefore) => {
      const currentDate = new Date();
      const daysBeforePoint = new Date();
      daysBeforePoint.setDate(currentDate.getDate() - (daysBefore));
      return date > daysBeforePoint;
    }
    const inputDate = new Date(data.published_at);
    const isProductNew = productDateChecker(inputDate, 30);


    return (
      <>
      <div
        className={
          "farmshop__item " + "type--" + data.animal_type +
          (isProductNew ? " new" : "") +
          (data.stock_status != "instock" ? " out-of-stock" : "") +
          (data.is_organic != "" ? " organic" : "") +
          (data.seasonal_highlight != "no" ? " highlight-"+ data.seasonal_highlight : "") +
          (" " + currentLang())
        }
      >
        {data.stock_status != "instock" && (
          <Fragment>
            <Link to={farmshopProductURL(data.slug)} title={data.title}>
              <div className="farmshop__title">
                <span className='title'> {data.title} </span>
                {(t('farmshop.subtitle.' + (data.animal_type))) === ""
                    ? ''
                    : <>
                        <span className='color--artichoke-dark mt-1 bio-text'>
                          {t('farmshop.subtitle.' + (data.animal_type) + (data.is_organic != "" ? "Bio" : ""))}
                        </span>
                      </>
                }
              </div>
              <figure className="img m-0">
                <div className="farmshop_stock">
                  <span className="txt">
                    <W>farmshop.stock.out_of_stock</W>
                  </span>
                  <span className="link">
                    <W>farmshop.stock.out_of_stock_link</W>
                  </span>
                </div>
                <img
                  src="https://www.grutto.com/nl/shop/wp-content/plugins/crowdbutching-product-bundle/assets/grutto-meatcut-empty.jpg"
                  className="img-fluid"
                  title={t("farmshop.stock.out_of_stock")}
                  width=""
                  height=""
                  alt={t("farmshop.stock.out_of_stock")}
                />
              </figure>
            </Link>
          </Fragment>

        )}
        <Link to={farmshopProductURL(data.slug)} title={data.title}>
          {data.stock_status == "instock" && (
            <>
              <div className="farmshop__title">
                <span className='title'> {data.title} </span>                
                {(t('farmshop.subtitle.' + (data.animal_type))) === ""
                    ? ''
                    : <>
                        <span className='color--artichoke-dark mt-1 bio-text'>
                          {t('farmshop.subtitle.' + (data.animal_type) + (data.is_organic != "" ? "Bio" : ""))}
                        </span>
                      </>
                }
              </div>
              <figure className="img m-0">
                <Image
                  type="farmshop.list"
                  src={data.image_url}
                  alt={data.title}
                  title={data.title}
                  className="img-fluid"
                />
              </figure>
            </>
          )}
          <div className="farmshop__item__farmer px-2">
            <div className="farmshop_farmer_image">
              <Image
                type="farmers.card"
                alt={data.farmer_title}
                title={data.farmer_title}
                src={data.farmer_image_url}
              />
            </div>
            <div className="farmshop_farmer_name">
              <W>farmers.{data.animal_type}</W>: {data.farmer_title}
            </div>
          </div>
        </Link>
        <div
          className="body p-2"
          data-title={data.title}
          data-slug={data.relation_slug}
          data-price={data.price}
        >
          <Link to={farmshopProductURL(data.slug)} title={data.title}>
            <div className="farmshop__item__info">
              {data.price != data.regular_price && (
                <Fragment>
                  <div className="sale_price p-prev">
                    <Price value={data.price} withCurrency={false} />
                  </div>
                  <div className="sale_price price">
                    <Price value={data.regular_price} withCurrency={false} />
                  </div>
                </Fragment>
              )}
              {data.price == data.regular_price && (
                <Fragment>
                  <div className="price">
                    <Price value={data.price} />
                  </div>
                </Fragment>
              )}
              <span className="sep"> - </span>
              <span className="weight">
                {data['pieces_per_vac']} x {data['weight']} <W>farmshop.unit_txt.{data['unit_type'] ? data['unit_type'] : 'g'}</W>
              </span>
            </div>
          </Link>
          {(view_button) && (
            <Link
              to={farmshopProductURL(data.slug)}
              title={t("farmshop.view_product")}
              className="farmshop__item__view"
            >
              <W>farmshop.view_product</W>
            </Link>
          )}
          {data.stock_status == "instock" && !view_button && (
            <FarmshopCartButton
              bundle_id={data.bundled_item_id}
              quantity={quantity}
              addToCartCallback={addToCartCallback}
              slug={data.relation_slug}
              stock={data.stock}
              minStock={data.min_stock}
              title={data.title}
              price={data.price}
            />
          )}
          {data.stock_status != "instock" && !view_button && (
            <div className="out-of-stock__btn">
              <W>farmshop.stock.out_of_stock_button</W>
            </div>
          )}
        </div>
      </div>

      </>
    );
}

export default FarmshopProductBox