import React, {Fragment, useState} from 'react'
import { currentLang } from '../../../utils/functions'
import ProductCard from '../ProductCard';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';

function ProductHighlight({data, items, moduleClass}) {
    const configArray = data.config ? JSON.parse(data.config).map(Number) : [];

    if (!items) {
        return null; // Return null if items are not provided
    }

    // Sort the items based on the module.config and filter out undefined values
    const sortedItems = configArray
      .map((productId) => items.find((item) => item.product_id === productId))
      .filter((product) => product !== undefined);

    return (
        <section className={"module__product-highlight " + moduleClass}>
            {items &&
            <Fragment>
            {data.title &&
                <div className="module__title">
                    <h2 className="heading heading--h3"> {data.title} </h2>
                    {data.body && <p>{parse(data.body)}</p>}
                </div>
            }
            <div className="container">
                <div className="row">
                    {sortedItems.slice(0, data.total_show ?? 3).map((product, index)=>{
                        return (
                            <div key={"product-highlight-module-"+index} className="col-12 col-sm-6 col-md-6 col-xl-3"><ProductCard key={'product-highlight-'+index} data={product} /></div>
                        )
                    })}
                </div>
            </div>
            {data.button_text &&
                <div className="cta mt-3 text--center">
                    <Link to={data.button_link ?? '/'+currentLang()} className="btn btn--border btn--red">
                        {data.button_text}
                    </Link>
                </div>
            }
            </Fragment>
            }
        </section>
    )
}

export default ProductHighlight
