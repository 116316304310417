import React, {useState, useEffect, Fragment} from 'react'
//import coverImg from '../../../src/assets/img/coupons/coupons_cover.png'
import flatstore from 'flatstore';
import parse from 'html-react-parser';
import Loader from '../../components/common/Loader';
import LoadModules from '../../components/modules/LoadModules'
import {getCoupons, getStaticPage} from '../../utils/services';
import Meta from '../../components/common/Meta';
import {currentLang} from '../../utils/functions';
import {isNil, isNull, last} from 'lodash';
import { useTranslation } from 'react-i18next';
import CouponItem from './CouponItem';

const Coupons = ({loading, coupons_data, coupons_static}) => {
    const [data, setData] = useState(null);
    const [staticData, setStaticData] = useState();
	const [modules, setModules] = useState(null);
    const { t } = useTranslation();

    useEffect(() => {
		if (isNil(coupons_static) && isNil(coupons_static)) {
            getCoupons();
            var slug = last(window.location.href.replace(/\/$/, "").split('/'));
			getStaticPage(slug, 'coupons_static');
        }
        if(coupons_data && isNil(data)){
            setData(coupons_data);
        }
    }, [coupons_data])

    useEffect(() => {
		if(!isNil(coupons_static)){
			setStaticData(coupons_static);
			if(coupons_static.modules && coupons_static.modules.module !== null){
				setModules(coupons_static.modules);
			}
		}
    }, [coupons_static]);


    if (loading) {
		return <Loader />
	} else {

		return (
            <Fragment>
            <LoadModules modules={modules} type="top" />

            <div className="container coupons">
                {staticData &&
                    <Fragment>
                        <Meta
                            title={staticData.seo_title ? staticData.seo_title : staticData.title}
                            description={staticData.seo_description ? staticData.seo_description : staticData.body}
                        />
                        {staticData.body && parse(staticData.body)}
                    </Fragment>
                }
                <div className="row">
					{data && data.map((item, index)=>{
						return (
                            <CouponItem key={index} data={item} />
						)
                    })}
				</div>
            </div>
            <LoadModules modules={modules} type="bottom" />
            </Fragment>
		)
	}
}

export default flatstore.connect(['loading', 'coupons_data', 'coupons_static'])(Coupons);