import {isNil} from 'lodash';

export function setStorage(key, data, ttl){
    const now = new Date();
    //var ttl = 60; // 1 minute
    var dataWithExpiryTime = {
        value: data,
        expiry: now.getTime()+ttl
    }
    window.localStorage.setItem(key, JSON.stringify(dataWithExpiryTime));


}

export function getStorage(key) {
    if (typeof key !== 'string' || !key.trim()) {
        console.error('Invalid key provided');
        return null;
    }

    const now = new Date();

    try {
        const data = window.localStorage.getItem(key);
        if (data) {
            const item = JSON.parse(data);
            if (isValidItem(item, now)) {
                return item.value;
            }
        }
    } catch (error) {
        console.error('Error reading from localStorage:', error);
    }

    removeStorage(key);  // Make sure you have this function defined
    return null;
}

// remove storage key
export function removeStorage(key){
    window.localStorage.removeItem(key);
}

// clear all local storage data
export function clearStorage(){
    window.localStorage.clear();
}

// check storage expire date
export function checkStorage(key){

}

export function customStorageReturnValue(key, changedValue, ttl){
    var data = getStorage(key);
    if(data){
        return data;
    }else{
        setStorage(key, changedValue, ttl);
        return changedValue;
    }
}
export function isJSONstring(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

function isJSON(text) {
    if (/^[\],:{}\s]*$/.test(text.replace(/\\["\\\/bfnrtu]/g, '@').
        replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').
        replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {
            return true;
    }else{
            return false;
    }
}

function isObject(obj) {
    return obj !== null && typeof obj === 'object';
}

function isValidItem(item, now) {
    return isObject(item) &&
           typeof item.expiry === 'number' &&
           typeof item.value !== 'undefined' &&
           now.getTime() < item.expiry;
}