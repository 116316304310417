import React, { useState, useEffect, Fragment } from 'react'
import {Trans, useTranslation} from 'react-i18next';
import { isArray, join } from 'lodash';

const W = (data) => {
    const [word, setWord] = useState(null);
    const {children: text, count, type} = data;
    const { t } = useTranslation();
    useEffect(() => {
        if(isArray(text) && text.length > 1){
            setWord(join(text, ''));
        }else{
            setWord(text)
        }
    }, [])
    
    return (
        <Fragment>

            {type && type == 'interval' &&
                
                <Fragment>
                    {t(word+'_interval', {postProcess: 'interval', count: count})}
                </Fragment>
            }
            {word && !count && type != 'interval' &&
                <Trans>{word}</Trans>
            }
        
        </Fragment>
        
    )
}

export default W
