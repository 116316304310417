import React, {useState, useEffect, Fragment} from 'react'
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import W from '../../../common/Word';

const PackagePricePerMealPerPerson = ({value, prevPrice, mealsPerPerson}) => {
  const [data, setData] = useState(null)
  const { t } = useTranslation();

    useEffect(() => {
        //console.log(value);
        if(value && value.toString().indexOf('.') > -1){
            var priceArray = value.toString().split('.');
            setData({number: priceArray[0], decimal: priceArray[1]});
            if (prevPrice && prevPrice.toString().indexOf('.') > -1 && mealsPerPerson){
              var prevPricePerMeal = (prevPrice / mealsPerPerson).toFixed(2);
              var prevPriceArray = prevPricePerMeal.toString().split('.');
              setData({number: priceArray[0], decimal: priceArray[1], prevnumber: prevPriceArray[0], prevdecimal: prevPriceArray[1]});
            }
        }else{
            setData({number: value, decimal:'00'});
        }
    }, [value])
    return (
      <div className="package-per-person">
        {data &&
          <Fragment>
          <div className="amount">
              {data.prevnumber &&
                <span className="prev-price">{parse(t('price_format', {price: data.prevnumber, decimal:data.prevdecimal}))}</span>
              }
              <div className='p-price'>{parse(t('price_format', {price: data.number, decimal:data.decimal}))}</div>
            </div>
            <span className="description"><W>product.perperson</W><br /><W>product.permeal</W></span>
          </Fragment>
        }
      </div>
    )
}

export default PackagePricePerMealPerPerson
