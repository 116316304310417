import React, {Fragment, useEffect, useState} from 'react'
import flatstore from 'flatstore';
import { isNil } from 'lodash';
import {getBlogList, getCategories, getStaticPage} from '../../utils/services';
import parse from 'html-react-parser';
import {blogURL, listFilter} from '../../utils/functions';
import {Link, useLocation} from 'react-router-dom';
import ListStatic from '../inc/ListStatic';
import BlogDataFilter from '../../components/common/BlogDataFilter';
import {useTranslation} from 'react-i18next';
import SearchState from '../../components/common/SearchState';
import Pagination from '../../components/common/Pagination';
import BlogItem from './BlogItem';
import LoadModules from '../../components/modules/LoadModules';
import NotFound from '../static/404';
import BlogListLoader from '../../assets/contentLoader/BlogListLoader';

function BlogList({blog_data, blog_list_static, loading, page, list_filter_type, list_search_status, list_filtered_data, list_active_page}) {
    const [data, setData] = useState(null);
    const [staticData, setStaticData] = useState();
	const [modules, setModules] = useState(null);
	const [filteredData, setFilteredData] = useState([]);
	const [filterType, setFilterType] = useState(null);
	const [searchStatus, setSearchStatus] = useState(null);
	const [activePage, setActivePage] = useState(1);
	const { t } = useTranslation();
	const {pathname} = useLocation();

    const type = 'blog';

    useEffect(() => {
		if (isNil(blog_data) && isNil(blog_list_static)) {
			getBlogList();
            getCategories();
			getStaticPage('blog', 'blog_list_static');
		}
		if(blog_data){
			setData(blog_data);
            setFilterType(list_filter_type);
			setActivePage(page);
			listFilter(type);
		}

	}, [blog_data, pathname]);

    useEffect(() => {

		if(!isNil(blog_list_static)){
			setStaticData(blog_list_static);
			if(blog_list_static.modules && blog_list_static.modules.module !== null){
				setModules(blog_list_static.modules);
			}
		}
	}, [blog_list_static]);

    useEffect(() => {
		setFilteredData(list_filtered_data)
	}, [list_filtered_data])

    useEffect(() => {
		if(list_filter_type != filterType){
			setFilterType(list_filter_type);
			listFilter(type);
		}
	}, [list_filter_type])

    useEffect(() => {
		setActivePage(activePage);
		listFilter(type);
	}, [list_active_page])

    useEffect(() => {
		setSearchStatus(list_search_status);
	}, [list_search_status])


    if (loading) {
		return (
			<div className="container mt-5 mb-5">
				<div className="row">
				  <div className="col-12">
					  <BlogListLoader />
				  </div>
				</div>
			</div>
		);
	} else {

		return (
			<Fragment>
			{filteredData.total == 0 &&
				<NotFound />
			}
			{modules &&
				<LoadModules modules={modules} type="top" />
			}
            <div className='bg--vanilla-lightest'>
			<div className="container">

				{staticData &&
					<ListStatic data={staticData} />
				}

				<BlogDataFilter type={type} filterType={filterType} data={data} staticData={staticData} />

				<div className="row pb-5">
					{filteredData && filteredData.map((item, index)=>{
						return (
							<div key={index} className="col-xs-12 col-md-6 col-lg-4">
								<BlogItem data={item} catInfo={item.categories} />
							</div>
						)
					})}
				</div>

				<SearchState status={searchStatus} />

				{data && filteredData && activePage && !isNil(filterType) &&
					<div className='pb-5'>
						<Pagination url={blogURL(null, null)} data={filteredData} curPage={list_active_page} filterType={filterType} type="blog" />
					</div>
				}

			</div>
            </div>
			{modules &&
				<LoadModules modules={modules} type="bottom" />
			}
			</Fragment>
		)
	}
}

export default flatstore.connect(['loading', 'blog_data', 'blog_list_static', 'list_search_status', 'list_filtered_data', 'list_filter_type', 'list_active_page'])(BlogList);