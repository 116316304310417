import React, {useEffect, Fragment} from 'react'
import NotFoundImg from '../../../assets/img/404.svg';
import {useNavigate, useLocation, Navigate} from 'react-router-dom';
import { currentLang } from '../../../utils/functions';
import Meta from '../../../components/common/Meta';
import { useTranslation } from 'react-i18next';
import W from '../../../components/common/Word';

const NotFound = () => {
    //const history = useNavigate();
    const {pathname} = useLocation();
    const { t } = useTranslation();

    useEffect(() => {

        //history.push("/"+currentLang()+"/404");
    }, [pathname])
    return (
        <Fragment>
        {pathname.substring(4, 7) != "404" &&
            <meta httpEquiv="refresh" content={"0;url=/"+currentLang()+"/404"} />
        }
        <Meta
            title={t("404")}
            description=""
        />
        <div className="container">
            <div className="row">
                <div className="col page404">

                    <div className="img mb-4">
                        <img alt="Page not found" src={NotFoundImg} />
                    </div>
                    <h2 className="heading--h2">
                        <W>Notfound.title</W>
                    </h2>
                    <p><W>Notfound.subtitle</W></p>
                    <a href="/" className="btn btn--red btn--outline mt-4"><i className="icon-arrow-left mr-2"></i> <W>Notfound.button</W> </a>
                </div>
            </div>
        </div>
        </Fragment>
    )
}

export default NotFound
