import React, {useState, useEffect, Fragment, useRef} from 'react'
import {isNull, isNil, upperFirst, sortedUniq, isArray, uniq} from 'lodash';
import flatstore from 'flatstore';
import {useNavigate, useLocation} from 'react-router-dom';
import { currentLang, accentsTidy, listSearch, listFilter, blogURL } from './../../../utils/functions';
import Meta from '../../../components/common/Meta'
import { useTranslation } from 'react-i18next';
import W from '../Word';
import { Link } from 'react-router-dom';

const BlogDataFilter = ({type, filterType, staticData, data, list_filter_type, list_categories}) => {
  const [activeFilter, setActiveFilter] = useState(null);
  const [categories, setCategories] = useState(null);
  const [cat, setCat] = useState(null);
  const history = useNavigate();
  const { t } = useTranslation();
  const searchInputRef = useRef(null);
  const [toggled, setToggled] = useState(false);
  const [totalAnimalsClass, setTotalAnimalsClass] = useState(null);
  //const animalsFilter = siteInfo[currentLang()]['animal_types'];
  const {pathname} = useLocation();
  let toggledClass = (toggled) ? "open" : "";
  const [currentStaticData, setCurrentStaticData] = useState(null);

  useEffect(() => {
    if(list_categories){
        setCategories(list_categories);

        var dataFilterCustomClass;
        if(list_categories.length <= 4){
          dataFilterCustomClass = ' item4';
        }else if(list_categories.length <= 8){
          dataFilterCustomClass = ' item8';
        }else if(list_categories.length <= 10){
          dataFilterCustomClass = ' item10';
        }else if(list_categories.length > 10){
          dataFilterCustomClass = ' scroll';
        }
        setTotalAnimalsClass(dataFilterCustomClass);
    }
    if(staticData){
      setCurrentStaticData(staticData);
    }
  }, [list_categories, staticData])


  const selectCategory = value =>{
    if(!(activeFilter == value)){

      flatstore.set('list_filter_type', value);
      flatstore.set('list_active_page', 1);
      var url = "/"+currentLang()+"/";
      if (!isNil(value)) {
        setActiveFilter(value);
        url = blogURL(null, null);
        if(value)
          url += "/"+value;
        history(url);

      } else {
          setActiveFilter(null);
      }
    }
  }

  const search = str => {
    if(str.length < 1){
      flatstore.set('list_search_status', 'only3char');
      flatstore.set('list_filtered_data', []);
    }else if(str.length < 2){
      flatstore.set('list_search_status', '3char');
      flatstore.set('list_filtered_data', []);
    }else{
      flatstore.set('list_search_status', 'search_it');
      listSearch(type, accentsTidy(str))
    }



  }

  function searchClose(event) {
    event.preventDefault();
    if(toggled){
      document.querySelector('#dataFilterSearch').value = '';
      flatstore.set('list_filter_type', activeFilter);
      flatstore.set('list_search_status', 'search_close');
      listFilter(type);
    }else{
      flatstore.set('list_search_status', 'search_empty');
      flatstore.set('list_filtered_data', []);
      searchInputRef.current.focus();
    }
    setToggled(!toggled)

  }

  useEffect(() => {
      setActiveFilter(filterType);
  }, [filterType])

  useEffect(() => {
    if(isNil(list_filter_type) || (list_filter_type.length < 2)){
      setActiveFilter(null);
    }
  }, [list_filter_type, pathname])

  useEffect(() => {
    if(!isNil(activeFilter)){
      if(window.innerWidth <= 768){
        var activeItem = document.querySelector('.data-filter__item.filter__item.active');
        var header__top = document.querySelector('.header__top');
        var header_height = header__top ? header__top.offsetHeight : 0;
        if(!isNil(activeItem)){
          var navbar = activeItem.parentElement.parentElement
          window.scrollTo({
            top:  navbar.getBoundingClientRect().top + window.pageYOffset - header_height,
            behavior: 'smooth'
          });
          navbar.scrollTo({
            top:  0,
            left: activeItem.offsetLeft - ( navbar.offsetWidth - activeItem.offsetWidth) / 2,
            behavior: 'smooth'
          });
        }
      }
    }
  }, [activeFilter])

  if(categories && categories.length >= 2){
    if (Object.keys(categories).length >= 2){
      return (
        <div className="row">
          <div className="col-12">
            <div className={`data-filter search data-filter--white data-filter--noicon ${toggledClass}`+(totalAnimalsClass && totalAnimalsClass)}>
              <div className={"nav_holder"}>
                <nav>
                  {animals &&
                  <Fragment>
                  <ul className="data-filter__list">
                      <Link to={blogURL(null, null)}>
                      <li className={`data-filter__item filter__item-text ${isNull(activeFilter) ? "active" : ""}`} onClick={()=>{selectCategory(null)}}>
                        <W>{t("datafilter.newblogitems")}</W>
                      </li>
                      </Link>
                      {categories && categories.map((cat, index)=>{
                          return(
                            <Fragment key={index}>
                            <li className={`data-filter__item filter__item ${(activeFilter === cat.slug) ? "active" : ""}`} onClick={()=>{selectCategory(cat.slug)}}>
                              <i className={'icon-cat-'+ cat.slug}></i> {cat.title}
                            </li>
                            {activeFilter === cat.slug && currentStaticData &&
                            <Meta
                              title = {cat.seo_title ?? currentStaticData.seo_title}
                              description= {cat.seo_description ?? currentStaticData.seo_description}
                              type="category"
                            />}
                            </Fragment>
                          )
                      })}
                  </ul>
                  </Fragment>
                  }
                </nav>
              </div>
              <div className="data-filter__search">
                <div className="action" onClick={searchClose}>
                  <i className="icon-magnify"></i>
                </div>
                <div className="form">
                  <input type="text" id="dataFilterSearch" ref={searchInputRef} placeholder={t("search.placeholder")} onChange={(e)=>{search(e.target.value)}} />
                </div>
              </div>
            </div>
          </div>
        </div>

      )
    } else {
      return (
        <Fragment>
        </Fragment>
      )
    }
  } else {
    return (
      <Fragment>
      </Fragment>
    )
  }
}

export default flatstore.connect(['list_filter_type', 'list_active_page', 'list_search_status', 'list_categories'])(BlogDataFilter);