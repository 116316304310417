import React, {useState, useEffect, Fragment} from 'react'
import Image from '../../../common/Image'
import { useTranslation } from 'react-i18next'

import cow_grayscale from '../../../../assets/img/animals/cow/cow_grayscale.svg';
import cow_gray from '../../../../assets/img/animals/cow/cow_gray.svg';
import cow_chicken_grayscale from '../../../../assets/img/animals/cow_chicken/cow_chicken_grayscale.svg';
import cow_chicken_gray from '../../../../assets/img/animals/cow_chicken/cow_chicken_gray.svg';
import chicken_grayscale from '../../../../assets/img/animals/chicken/chicken_grayscale.svg';
import chicken_gray from '../../../../assets/img/animals/chicken/chicken_gray.svg';
import deer_grayscale from '../../../../assets/img/animals/deer/deer_grayscale.svg';
import deer_gray from '../../../../assets/img/animals/deer/deer_gray.svg';
import lamb_grayscale from '../../../../assets/img/animals/lamb/lamb_grayscale.svg';
import lamb_gray from '../../../../assets/img/animals/lamb/lamb_gray.svg';
import pig_grayscale from '../../../../assets/img/animals/pig/pig_grayscale.svg';
import pig_gray from '../../../../assets/img/animals/pig/pig_gray.svg';
import turkey_grayscale from '../../../../assets/img/animals/turkey/turkey_grayscale.svg';
import turkey_gray from '../../../../assets/img/animals/turkey/turkey_gray.svg';
import boar_grayscale from '../../../../assets/img/animals/boar/boar_grayscale.svg';
import boar_gray from '../../../../assets/img/animals/boar/boar_gray.svg';
import fish_grayscale from '../../../../assets/img/animals/fish/fish_grayscale.svg';
import fish_gray from '../../../../assets/img/animals/fish/fish_gray.svg';
import salmon_codfish_grayscale from '../../../../assets/img/animals/salmon_codfish/salmon_codfish_grayscale.svg';
import salmon_codfish_gray from '../../../../assets/img/animals/salmon_codfish/salmon_codfish_gray.svg';
import duck_grayscale from '../../../../assets/img/animals/duck/duck_grayscale.svg';
import duck_gray from '../../../../assets/img/animals/duck/duck_gray.svg';
import goose_grayscale from '../../../../assets/img/animals/goose/goose_grayscale.svg';
import goose_gray from '../../../../assets/img/animals/goose/goose_gray.svg';
import roe_grayscale from '../../../../assets/img/animals/roe/roe_grayscale.svg';
import roe_gray from '../../../../assets/img/animals/roe/roe_gray.svg';
import easter_pack_grayscale from '../../../../assets/img/animals/easter_pack/easter_pack_grayscale.svg';
import easter_pack_gray from '../../../../assets/img/animals/easter_pack/easter_pack_gray.svg';
import wine_grayscale from '../../../../assets/img/animals/wine/wine_grayscale.svg';
import wine_gray from '../../../../assets/img/animals/wine/wine_gray.svg';

import cow_chicken_pig_grayscale from '../../../../assets/img/animals/cow_chicken_pig/cow_chicken_pig_grayscale.svg';
import cow_chicken_pig_gray from '../../../../assets/img/animals/cow_chicken_pig/cow_chicken_pig_gray.svg';

import {animalPercent} from '../../../../utils/functions';

const PercentageBox = ({percentage, animal, category_slug, url}) => {
    const [data, setData] = useState(0);
    const [currentAnimal, setCurrentAnimal] = useState(null);
    percentage = animalPercent(percentage);
    const { t } = useTranslation();
    
    const animals_images = {
        cow:{
            fill: cow_grayscale,
            empty: cow_gray
        },
        cow_chicken:{
            fill: cow_chicken_grayscale,
            empty: cow_chicken_gray
        },
        cow_chicken_pig:{
            fill: cow_chicken_pig_grayscale,
            empty: cow_chicken_pig_gray
        },
        chicken:{
            fill: chicken_grayscale,
            empty: chicken_gray
        },
        deer:{
            fill: deer_grayscale,
            empty: deer_gray
        },
        lamb:{
            fill: lamb_grayscale,
            empty: lamb_gray
        },
        sheep:{
            fill: lamb_grayscale,
            empty: lamb_gray
        },
        pig:{
            fill: pig_grayscale,
            empty: pig_gray
        },
        turkey:{
            fill: turkey_grayscale,
            empty: turkey_gray
        },
        boar:{
            fill: boar_grayscale,
            empty: boar_gray
        },
        fish:{
            fill: fish_grayscale,
            empty: fish_gray
        },
        salmon_codfish:{
            fill: salmon_codfish_grayscale,
            empty: salmon_codfish_gray
        },
        salmon:{
            fill: fish_grayscale,
            empty: fish_gray
        },
        duck:{
            fill: duck_grayscale,
            empty: duck_gray
        },
        goose:{
            fill: goose_grayscale,
            empty: goose_gray
        },
        roe:{
            fill: roe_grayscale,
            empty: roe_gray
        },
        easter_pack:{
            fill: easter_pack_grayscale,
            empty: easter_pack_gray
        },
        wine:{
            fill: wine_grayscale,
            empty: wine_gray
        }
    }

    useEffect(() => {
        //setCurrentAnimal(null);
        //console.log(animal, currentAnimal)
        setCurrentAnimal(animal);
    }, [animal])
    
    useEffect(() => {
        setData(percentage)
        if (category_slug == 'grutto_combi_cow_chicken' || category_slug == 'grutto_bio_combi_cow_chicken' || category_slug == 'grutto_frozen_combi_cow_chicken') {
            setCurrentAnimal('cow_chicken')
        }else if (category_slug == 'grutto_fish_salmon_codfish') {
            setCurrentAnimal('salmon_codfish')
        }else if (category_slug == 'grutto_farmer_package') {
            setCurrentAnimal('easter_pack')
        }else if (category_slug == 'grutto_combi_cow_pig_chicken') {
            setCurrentAnimal('cow_chicken_pig')
        }
        else{
            setCurrentAnimal(animal);
        }

    }, [percentage])
    
    return (
        <Fragment>
        {currentAnimal && 
            <div className="percentage__fill">
                <div className="img__fill" style={{height: data+"%"}}>
                    <Image alt={t(currentAnimal)} title={t(currentAnimal)} width={460} height={210} src={animals_images[currentAnimal]['fill']} />
                </div>
                <div className="img__gray">
                    <Image alt={t(currentAnimal)} title={t(currentAnimal)} width={460} height={210} src={animals_images[currentAnimal]['empty']} />
                </div>
            </div>
        }
        </Fragment>
    )
}

export default PercentageBox
