import React, {useState, useEffect, Fragment} from 'react'
import flatstore from 'flatstore';
import axios from 'axios';
import parse from 'html-react-parser';
import {getMenu, getSettings} from '../../../utils/services';
import {currentLang, getPaymentLogos, setCookie, getUrlVars, objectToQueryString} from '../../../utils/functions';
import LogoDE from '../../../assets/img/logo/KaufneKuh_footer.svg';
import Logo from '../../../assets/img/logo/Grutto_footer.svg';
import { useTranslation } from 'react-i18next';
import W from '../../../components/common/Word';
import { Link,useLocation } from 'react-router-dom';

const Footer = ({settings_data, footer_menu_data}) => {

    let footerLogo = Logo;
    if(currentLang() == 'nl'){

    }
    if(currentLang() == 'de'){
        footerLogo = LogoDE;
    }
    const [menu, setMenu] = useState(null)
    const [settings, setSettings] = useState(null)
    const { t } = useTranslation();
    const paymentLogos = getPaymentLogos();
    const [inviteCodeStatus, setInviteCodeStatus] = useState(null);
    const [invitePopupShow, setInvitePopupShow] = useState(false);
    const [delayRender, setDelayRender] = useState(false);
    const {pathname} = useLocation();

    function togglePopup(){
        setInvitePopupShow(!invitePopupShow);
    }
    useEffect(() => {
        if(settings_data == undefined){
            getSettings(currentLang());
        }else{
            setSettings(settings_data);
        }

    }, [settings_data])

    useEffect(() => {
        if(footer_menu_data == undefined){
            getMenu('footer', 'footer_menu_data');
        }else{
            setMenu(footer_menu_data);
        }
    }, [footer_menu_data])

    useEffect(() => {
        if(delayRender)
            setDelayRender(false);

        setTimeout(() => {
            setDelayRender(true);
        }, 500);
    }, [pathname]);

    useEffect(() => {
        var url = window.location.href;
        var http_config = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
        if(url.indexOf('invite=') > 0){
            var inviteId = getUrlVars()['invite'];
            var postdata = {
              action: 'cb_add_coupon',
              couponcode: inviteId,
              shortinit: 'fast'
            }

            axios.post('/'+currentLang()+'/shop/ajaxflow/cb_add_coupon', objectToQueryString(postdata), http_config).then(function(response){
                  var data = response.data;
                  if(data.status)
                    setInvitePopupShow(true);
                    setInviteCodeStatus(data.status);
              });
        }
        if(url.indexOf('awc=') > 0){
            var awc = getUrlVars()['awc'];
            setCookie('awc', awc, 7, "/de"); // for AWIN tracking system
        }

      }, [])

    return (
        <Fragment>
        {delayRender &&
        <footer className={"footer" + (" " + currentLang())}>
            {inviteCodeStatus &&
            <div onClick={()=>togglePopup()} className={"modal "+(invitePopupShow ? 'show' : '')}>{/* add class --> show */}
                <div className="modal__body">
                    {inviteCodeStatus == 'success' &&
                        <Fragment>
                            <div className="modal__icon">
                                <span className="icon-checkmark green"></span>
                            </div>
                            <h5 className="modal__title"> <W>popup.success.title</W> </h5>
                            <div className="modal__text">
                                <W>popup.success.body</W>
                            </div>
                            <div className="modal__action">
                                <Link to={t("popup.success.package_url")} onClick={()=>togglePopup()} className="m-2 px-3 btn btn--border btn--red"> <i className='icon-package mr-2'></i> <W>popup.success.btn_box</W> </Link>
                                <Link to={t("popup.success.farmshop_url")} className="m-2 px-3 btn btn--border btn--red"> <i className='icon-meat mr-2'></i> <W>popup.success.btn_farmshop</W></Link>
                            </div>
                        </Fragment>
                    }
                    {inviteCodeStatus == 'failed' &&
                        <Fragment>
                            <div className="modal__icon">
                                <span className="icon-close red"></span>
                            </div>
                            <h5 className="modal__title red"> <W>popup.failed.title</W> </h5>
                            <div className="modal__text">
                                <W>popup.failed.body</W>
                            </div>
                            <div className="modal__action">
                                <a href="#" onClick={()=>togglePopup()} className="btn btn--border btn--red"><W>popup.failed.button</W></a>
                            </div>
                        </Fragment>
                    }
                </div>

            </div>
            }

            <section className="footer__middle">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-5 col-md-3 col-xl-3">
                            <div className="footer__logo">
                                <img src={footerLogo} alt={t("website_name")} title={t("website_name")} width="110" height="51" />
                            </div>
                            <div className="footer__address">
                                {parse(t('company.address'))}
                            </div>
                            <div className="footer__social">
                                {t('contact.facebook_url') != "" &&
                                    <a target="_blank" href={t("contact.facebook_url")} title="facebook"><i className="icon-facebook"></i> </a>
                                }
                                {t('contact.twitter_url') != "" &&
                                    <a target="_blank" href={t("contact.twitter_url")} title="twitter"><i className="icon-twitter"></i> </a>
                                }
                                {t('contact.instagram_url') != "" &&
                                    <a target="_blank" href={t("contact.instagram_url")} title="instagram"><i className="icon-instagram"></i></a>
                                }
                                {t('contact.pinterest_url') != "" &&
                                    <a target="_blank" href={t("contact.pinterest_url")} title="pinterest"><i className="icon-pinterest"></i></a>
                                }
                            </div>
                        </div>
                        <div className="col-sm-7 col-md-9 col-xl-9 footer__link">
                            <ul className="row footer__menu">
                                {settings && settings[currentLang()+'.certificates'] &&
                                    <li className="col-6 col-sm-6 col-md col-lg-3">
                                        <div className="certificate--box">
                                            <div className="heading"> <W>company.certification</W> </div>
                                            <div className="certificate">
                                                {parse(settings[currentLang()+'.certificates'])}
                                            </div>
                                        </div>
                                    </li>
                                }
                                {menu &&
                                    <Fragment>
                                        {menu.map((cat, index)=>{
                                            if(!cat.parent_id){
                                                return(
                                                <li key={'footer_menu_'+index} className="col-6 col-sm-6 col-md col-lg-3">
                                                    {!cat.parent_id &&
                                                        <div className="heading">{cat.title}</div>
                                                    }
                                                    <ul>
                                                    {menu.map((item, i)=>{
                                                        if(item.parent_id == cat.menu_item_id)
                                                        return (
                                                            <li key={'footerlink-' + i}>
                                                              {item.url.includes('/shop/') || !item.url.startsWith('/'+currentLang()+'/') ? (
                                                                <a href={item.url} title={item.title}>
                                                                  {item.title}
                                                                </a>
                                                              ) : (
                                                                <Link className="link" to={item.url} title={item.title}>
                                                                  {item.title}
                                                                </Link>
                                                              )}
                                                            </li>
                                                          );
                                                    })}
                                                    </ul>
                                                </li>
                                                )
                                            }
                                        })}
                                    </Fragment>
                                }
                            </ul>

                        </div>
                    </div>
                </div>
            </section>
            <section className="footer__copy">
                <div className="container">
                    <div className="row-item">
                        <div className="copyright">
                            {settings &&
                            <Fragment>
                                {settings[currentLang()+'.footer.google-review'] && parse(settings[currentLang()+'.footer.google-review'])}
                                <p> {parse(settings[currentLang()+'.footer.address'])} </p>
                            </Fragment>
                            }

                        </div>
                        <div className="payment-methods">
                            {paymentLogos && paymentLogos.map((item, index)=>{
                                return <i key={'paymentlogo_'+index} className={"icon-"+item}></i>
                            })}

                        </div>
                    </div>
                </div>
            </section>
        </footer>
        }
        </Fragment>
    )
}

export default flatstore.connect(['settings_data', 'footer_menu_data'])(Footer);

