import React, {useState, useEffect, Fragment} from 'react'
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

const Price = ({value, withCurrency}) => {
    const [data, setData] = useState(null);
    const { t } = useTranslation();
    const translationKey = 'price_format'+(withCurrency ? '_with_currency' : '');
    useEffect(() => {
        value = parseFloat(value).toFixed(2);
        if(value && value.toString().indexOf('.') > 0){
            var priceArray = value.toString().split('.');
            setData({number: priceArray[0], decimal: priceArray[1]});
        }else{
            setData({number: value, decimal:'00'});
        }
    }, [value])

    return (
        <Fragment>
            {data &&
                <div className="amount">
                    {parse(t(translationKey, {price: data.number, decimal:data.decimal}))}
                </div>
            }
        </Fragment>
    )
}

export default Price
