import { React, useState } from "react";
import FarmshopCartButton from "./button";
import Price from "../../../components/product/common/Price";
import { currentLang } from "../../../utils/functions";
import W from '../../../components/common/Word';

const FarmshopOverview = ({ dataInCart, quantities, addToCartCallback, isLoading }) => {
  // Calculate the total price
  const totalPrice = dataInCart.reduce((total, item, index) => {
    const price = parseFloat(item.price);
    return total + price * quantities[index];
  }, 0);

  let [ toggleItems, setToggleItems] = useState(false)

  return (
    <div className={"pack-overview"+ (toggleItems ? ' open' : '') + (isLoading ? ' is-loading' : '')}>
      <div className="cta">
        <div className="price" onClick={() => { setToggleItems(!toggleItems)}}>
          <div className="txt">
            <Price value={totalPrice} withCurrency={false} />
          </div>
        </div>
        {(dataInCart.length == 0) || (isLoading == true) ? (
          <div className="btn disabled"><W>farmshop.overview.cta</W></div>
        ) : (
          <a className="btn" href={"/" + currentLang() + "/shop/"}>
            <W>farmshop.overview.cta</W>
          </a>
        )}
      </div>
      <div className="overview-items">
        <div className="overview-items-holder">
          <div className="title">
            <W>farmshop.overview.title</W>
          </div>
          {(dataInCart.length == 0) &&
            <div className="empty-cart">
              <W>farmshop.overview.empty_cart</W>
            </div>
          }
          {dataInCart.map((item, index) => (
            <div className="itm" key={item.bundled_item_id}>
              <div className="h-title">
                <span className="name">{item.title}</span>
                <span className="info">{`~${item.weight}${item.unit_type || "g"} x ${item.pieces_per_vac} `} <W>farmshop.overview.pcs</W></span>
              </div>
              <FarmshopCartButton
                bundle_id={item.bundled_item_id}
                quantity={quantities[index]}
                addToCartCallback={addToCartCallback}
                slug={item.relation_slug}
                stock={item.stock}
                minStock={item.min_stock}
                title={item.title}
                price={item.price}
              />
            </div>
          ))}

          {/* {dataInCart.length > 0 && currentLang() == "de" && (
            <div className="itm">
              <div className="h-title">
                <span className="name"> <W>farmshop.overview.gift_item_title</W> </span>
                <span className="info"> <W>farmshop.overview.gift_item_info</W> </span>
              </div>
              <div>
                <i className="icon-gift"></i>
              </div>
            </div>
          )} */}

        </div>
      </div>
    </div>
  );
};

export default FarmshopOverview;
