import React, { useState, useEffect } from 'react';
import flatstore from 'flatstore';
import { useParams, useLocation } from 'react-router-dom';
// Import your actual components
import SubscriptionOption from './steps/SubscriptionOption';
import SubscriptionSize from './steps/SubscriptionSize';
import CustomizeSubscription from './steps/CustomizeSubscription';
import CuratedSubscription from './steps/CuratedSubscription';
import Meta from '../../components/common/Meta';
import { isNil } from 'lodash';
import {getStaticPage} from '../../utils/services';
import LoadModules from '../../components/modules/LoadModules';
import { getSubscriptionPaths } from '../../utils/functions';

const Subscription = ({ loading, subscription_static_data }) => {
  const [data, setData] = useState(null);
  const [modules, setModules] = useState(null);
  const { filter } = useParams();
  const location = useLocation();
  const paths = getSubscriptionPaths();
  // Updated to handle trailing slash
  const parts = location.pathname.endsWith('/') ? location.pathname.slice(0, -1).split('/') : location.pathname.split('/');
  const latestPath = parts[parts.length - 1];

  const matchedStep = Object.keys(paths.steps).find(step => paths.steps[step].endsWith(latestPath));


  useEffect(() => {
      subscription_static_data = null;
  }, [])

  useEffect(() => {
      if (isNil(subscription_static_data)) {
        getStaticPage("subscription", "subscription_static_data");
      }
      if (!data) {
        setData(subscription_static_data);
      }

      if (subscription_static_data && subscription_static_data.modules !== null) {
        setModules(subscription_static_data.modules);
      }
  }, [subscription_static_data]);

  // Dynamically select the component based on the last URL segment
  const renderComponentBasedOnPath = () => {
    switch (matchedStep) {
      case 'size':
        return <SubscriptionSize />;
      case 'customize':
        return <CustomizeSubscription />;
      case 'curated':
        return <CuratedSubscription />;
      default:
        return (
          <>
            {modules && <LoadModules modules={modules} filter={filter} moduleData={null} type="top" />}
            <SubscriptionOption />
            {modules && <LoadModules modules={modules} filter={filter} moduleData={null} type="bottom" />}
          </>
        );
    }
  };

  return (
    <>
      {data && <Meta title={data.seo_title ?? data.title} description={data.seo_description ?? data.body} />}
      {renderComponentBasedOnPath()}
    </>
  );

};

export default flatstore.connect(['loading','subscription_static_data'])(Subscription);
