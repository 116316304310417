import React from 'react'
import flatstore from 'flatstore'
import parse from 'html-react-parser';
import { currentLang } from '../../../utils/functions';
import { siteInfo } from '../../../utils/config';
import { lowerCase } from 'lodash';

function Reviews({settings_data, items}) {

    return (
        <section className={"reviews" + (" " + currentLang())}>
            
            {['nl', 'fr'].includes(currentLang()) && 
                <div className="reviews__title google">
                    <div className="rate">
                        {settings_data && settings_data[currentLang()+'.reviews_num']}
                    </div>
                    <div className="logo_box">
                        <div className="google_logo"></div>
                        {settings_data && settings_data[currentLang()+'.reviews_desc'] && parse(settings_data[currentLang()+'.reviews_desc'])}
                    </div>
                </div>
            }

            {['de', 'uk'].includes(currentLang()) && 
                <div className="reviews__title trustedshops">
                    <div className="trustedshops_logo"> </div>
                    <div className="trustedshops__box">
                        <div className="trustedshops__rate"> {settings_data && settings_data[currentLang()+'.reviews_num']} <span> / 5.00 </span> </div>
                        <div className="trustedshops__star">  </div>
                        <div className="trustedshops__rate-name"> {settings_data && settings_data[currentLang()+'.reviews_desc'] && parse(settings_data[currentLang()+'.reviews_desc'])} </div>
                    </div>
                </div>
            }

            
            <div className="container">
                <div className="row">
                    {items && items.map((rev, index)=>{
                        return(
                            <div key={'review_'+index} className="col-lg-4 col-md-6">
                                <div className="reviews__item">
                                    <div className="reviews__head">
                                        <div className="reviews__user">
                                            <span className="name">{rev.reviewer}:</span>
                                            <span className="star"></span>
                                        </div>
                                    </div>
                                    <div className="reviews__body">
                                        {rev.description && parse(rev.description)}
                                    </div>
                                </div>
                                <script type="application/ld+json">
                                    {JSON.stringify({
                                        "@context": "https://schema.org/",
                                        "@type": "Review",
                                        "reviewRating": {
                                            "@type": "Rating",
                                            "ratingValue": "5"
                                        },
                                        "author": {
                                            "@type": "Person",
                                            "name": rev.reviewer
                                        },
                                        "reviewBody": rev.description
                                    })}
                                </script>
                            </div>
                        )
                    })}
                </div>
                <div className="mt-4 text--center">
                    <a href={"https://www.google.com/shopping/customerreviews/merchantreviews?q="+lowerCase(siteInfo[currentLang()]['domain'])} target="_blank" rel="noopener noreferrer" className="btn"> 
                        {settings_data && settings_data[currentLang()+'.reviews_btn']}
                    </a>
                </div>
            </div>
        </section>
    )
}

export default flatstore.connect(['settings_data'])(Reviews);
