import React, { useEffect, Fragment } from 'react'
import flatstore from 'flatstore';
import BreedsList from './BreedsList'
import BreedsDetails from './BreedsDetails'
import { includes, isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
import { currentLang } from '../../utils/functions';
import { siteInfo } from './../../utils/config';
import { useParams } from 'react-router-dom';

const Breeds = () => {
    const { t } = useTranslation();
    const animals = siteInfo[currentLang()]['animal_types'];
    let { slug, page } = useParams();
    let filter = null;
    let detailPage = true;

    if (slug) {
        if (parseInt(slug) > 0) {
            page = slug;
            detailPage = null;
        }
        if (includes(animals, t(slug))) {
            filter = t(slug);
            detailPage = null;
        }
    } else {
        detailPage = null;
    }

    useEffect(() => {
        if (!detailPage) {
            flatstore.set('list_active_page', page);

            if (filter) {
                flatstore.set('list_filter_type', filter);
            }
        }
    }, [detailPage, page, filter]);

    if (detailPage) {
        filter = page;
        return (
            <Fragment>
                <BreedsDetails slug={slug} animal={filter} />
            </Fragment>
        );
    } else {
        return (
            <Fragment>
                <BreedsList page={page} filter={filter} />
            </Fragment>
        );
    }
}

export default Breeds;
