import React, { useEffect } from 'react';
import flatstore from 'flatstore';
import RecipeList from './RecipeList';
import RecipeDetail from './RecipeDetail';
import { includes, isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
import { currentLang } from '../../utils/functions';
import { siteInfo } from './../../utils/config';
import { useParams } from 'react-router-dom';


const Recipes = () => {
    const { t } = useTranslation();
    const animals = siteInfo[currentLang()]['animal_types'];
    let { slug, page } = useParams();
    let filter = null;
    let detailPage = true;

    if (slug) {
        if (parseInt(slug) > 0) {
            page = slug;
            detailPage = null;
        }
        if (includes(animals, t(slug))) {
            filter = t(slug);
            detailPage = null;
        }
    } else {
        detailPage = null;
    }

    useEffect(() => {
        if (!detailPage) {
            if (isNil(page)) page = 1;
            flatstore.set('list_active_page', page);
            flatstore.set('list_filter_type', filter);
        }
    }, [detailPage, page, filter]);

    if (detailPage) {
        filter = page;
        return <RecipeDetail slug={slug} animal={filter} />;
    } else {
        return <RecipeList page={page} filter={filter} />;
    }
};

export default Recipes;