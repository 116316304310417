import React, {useState, useEffect, Fragment} from 'react'
import BlogItem from '../../../pages/Blog/BlogItem'
import {blogURL, calculateTimeToRead, currentLang} from '../../../utils/functions';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import W from '../../common/Word';
import Image from '../../../components/common/Image';

function BlogHighlight({data}) {
  const [items, setItems] = useState([]);
  const [pageType, setPageType] = useState('blog-normal');

  useEffect(() => {
    if (data && data.items) {
      setItems(data.items);
      setPageType(data.items.length < 3 ? 'blog-special' : 'blog-normal');
    }
  }, [data]);

  if (items.length === 0) return <div></div>;

  const showCategories = categories => {
    if(categories){
      return categories.map((category, index) => {
        // check if last item
        var seprator = ' , ';
        if(index === categories.length - 1){
          seprator = '';
        }
        return (
          <Fragment key={index}>
            <span className='blog_category'> {category.title} </span> {seprator}
          </Fragment>
        )
      })
    }
  }

  return (
      <div className={"x-scroll " +pageType}>
      <Fragment>
          {pageType == 'blog-special' && items.map((item, index)=>{
            var publish_date = new Date(item.publish_datetime).toLocaleDateString(currentLang(), { day:"numeric", year:"numeric", month:"long" } );
            var category = item.categories ?? null;
            return (
            <div key={index} className='blog__special-post'>
                  <div className='image'>
                    <Link to={blogURL(item.slug, category)} title={item.title}>
                      <Image src={item.image_url} className='img-fluid' alt={item.title} title={item.title} />
                    </Link>
                  </div>
                  <div className='text'>
                      <div className='meta-info'>
                          <div className='post-data'>
                              <span className='time'>{calculateTimeToRead(item.body)} min</span>
                              <span className='sep'> | </span>
                              <span className='author'> {item.categories && showCategories(item.categories)} </span>
                              <span className='sep'> | </span>
                              <span className='date'> {publish_date} </span>
                          </div>
                          <h2 className='heading'><Link to={blogURL(item.slug, category)} title={item.title}>{item.title}</Link></h2>
                          <p className='excerpt'>{item.summary && parse(item.summary)}</p>
                          <div className='read-more'>
                              <Link to={blogURL(item.slug, category)}><W>blog.read_more</W></Link>
                          </div>
                      </div>
                  </div>
              </div>
            )
          })}
        {pageType == 'blog-normal' &&
            <Fragment>
                {data.title &&
                    <div className="module__title">
                        <h2 className="heading heading--h3"> {data.title} </h2>
                        <p className='m-0'> {data.body} </p>
                    </div>
                }
              <div className='container'>
                <div className='row justify-content-between align-items-top'>
                  {items.slice(0, data.total_show ?? 3).map((item, index)=>{
                      return (
                          <div className="col-md-6 col-lg-4" key={index}>
                              <BlogItem data={item} catInfo={item.categories} />
                          </div>
                      )
                  })}
                </div>
              </div>
              {data.button_text &&
                    <div className="see-all">
                          <a href={data.button_link ?? '/'+currentLang()} title={data.button_text} className="btn btn--red btn--outline">
                              {data.button_text}
                          </a>
                    </div>
              }
            </Fragment>
          }
    </Fragment>
    </div>
  )
}

export default BlogHighlight