import React, {Fragment, useState, useEffect} from 'react'
import { useTranslation } from 'react-i18next';
import { recipeURL } from '../../../utils/functions';
import {getRelatedRecipes} from '../../../utils/services';
import flatstore from 'flatstore';
import Image from '../../common/Image';
import {isNil} from 'lodash';
import W from '../../common/Word';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';

function RelatedRecipes({relatedType, relatedSlug, related_recipes_data, data}) {
    const [items, setItems] = useState(null);
    const { t } = useTranslation();
    //const url = recipeURL(recipe.slug)
    useEffect(() => {
        related_recipes_data = null;
    }, [])

    useEffect(() => {
        if(isNil(related_recipes_data) && isNil(items)){
            getRelatedRecipes(relatedType, relatedSlug);
        }
        if(related_recipes_data && related_recipes_data.length > 0 && isNil(items)){
            setItems(related_recipes_data);
        }
    }, [related_recipes_data])

    return (
        <Fragment>
        {items &&
        <section className="module__related-recipes">
            {data.title &&
                <div className="module__related-recipes--title">
                    <h2 className="heading heading--h3"> {data.title}</h2>
                    {data.body && <p>{parse(data.body)}</p>}
                </div>
            }
            <div className="container">
                <div className="row justify-content-center">
                    {items.slice(0, data.total_show ?? 3).map(recipe=>{
                        //var url = '/'+currentLang()+'/'+t("menu.recipes")+'/'+recipe.slug;
                        var url = recipeURL(recipe.slug);

                        return (
                            <div className="col-xs-12 col-md-6 col-lg-4">
                                <div className="list__item">
                                    <div className="list__body">
                                        <div className="list__image"><Link className="read-more" to={url} title={recipe.title}><Image src={recipe.image_url} type="recipes.list" alt={recipe.title} title={recipe.title} /></Link>
                                            <div className="list__info">
                                                <div className="badge"></div>
                                            </div>
                                        </div>
                                        <div className="list__description">
                                            <div>
                                                <div className="title"> 
                                                    <Link className="link" to={url} title={recipe.title}>{recipe.title}</Link>
                                                </div>
                                            </div>
                                            <Link className="read-more" to={url} title={t("recipes.view_recipe_button")}><W>recipes.view_recipe_button</W></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            {data.button_text &&
                <div className="see-all">
                    <Link to={data.button_link ?? recipeURL(null)} className="btn btn--red"> {data.button_text} </Link>
                </div>
            }
        </section>
        }
        </Fragment>
    )
}

export default flatstore.connect(['related_recipes_data'])(RelatedRecipes);