import React, {Fragment, useState} from 'react'
import SchemaProduct from './SchemaProduct';
import SchemaRecipe from './SchemaRecipe';
import SchemaFarmshopProducts from './SchemaFarmshopProducts';
import SchemaBlog from './SchemaBlog';
import SchemaMeatcut from './SchemaMeatcut';
import {currentLang} from './../../../utils/functions';
import {siteInfo} from './../../../utils/config';
import {truncate} from 'lodash';
import { useTranslation } from 'react-i18next';
import flatstore from 'flatstore';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';


const Meta = ({type, title, description, robots, url, site_name, fbadmins, image, card, type_data}) => {
    const { t } = useTranslation();
    var {pathname} = useLocation();
    var canonicalurl = window.location.origin+window.location.pathname.replace(/\/$/, "");

    const is_homepage = pathname == '/'+currentLang() && pathname !== '/'+currentLang()+'/';

    // remove animal from urls
    var animalIndex = canonicalurl.indexOf('/'+t('menu.animal'));
    if(animalIndex > 1){
        canonicalurl = canonicalurl.substr(0, animalIndex);
    }
    var page_canonical = flatstore.get('canonical_url');
    if(page_canonical)
        canonicalurl = window.location.origin+page_canonical;

    // remove html from description
    description = description ? description.replace(/<[^>]+>/g, '') : "";

    return (
        <Fragment>
            <Helmet defer={true}>
                <title>{title+" | "+siteInfo[currentLang()]['title']}</title>
                {is_homepage && <link rel="alternate" href="https://www.grutto.com/global/" hreflang="x-default"></link>}
                {is_homepage && <link rel="alternate" href="https://www.grutto.com/nl" hreflang="nl-nl"></link>}
                {is_homepage && <link rel="alternate" href="https://www.grutto.com/fr" hreflang="fr-fr"></link>}
                {is_homepage && <link rel="alternate" href="https://www.grutto.com/uk" hreflang="en-gb"></link>}
                {is_homepage && <link rel="alternate" href="https://www.kaufnekuh.de/de" hreflang="de-de"></link>}
                <link rel="canonical" href={canonicalurl.toLowerCase()} ></link>
                <meta name="description" content={truncate(description, {'length':150})}/>
                {robots && <meta name="robots" content={robots} />}
                <meta property="og:locale" content={siteInfo[currentLang()]['locale']} />
                <meta property="og:type" content={type} />
                <meta property="og:title" content={title+" "+siteInfo[currentLang()]['title']+"!"}/>
                <meta property="og:description" content={truncate(description, {'length':150})}/>
                <meta property="og:url" content={canonicalurl.toLowerCase()}/>
                <meta property="og:site_name" content={siteInfo[currentLang()]['domain']}/>
                <meta property="fb:admins" content={siteInfo[currentLang()]['fbadmins']} />
                {image && <meta property="og:image" content={image}/> }
                <meta name="twitter:card" content={card ? card : "summary"} />
                <meta name="twitter:description" content={truncate(description, {'length':150})}/>
                <meta name="twitter:title" content={siteInfo[currentLang()]['twtitle']+" "+title}/>
                <meta name="twitter:site" content={siteInfo[currentLang()]['twitter']} />
                {image && <meta name="twitter:image" content={image}/> }
            </Helmet>

            {type == "product" &&
                <SchemaProduct data={type_data} />
            }
            {type == "recipe" &&
                <SchemaRecipe data={type_data} />
            }
            {type == "farmshop" &&
                <SchemaFarmshopProducts data={type_data} />
            }
            {type == "blog" &&
                <SchemaBlog data={type_data} />
            }
            {type == "meatcut" &&
                <SchemaMeatcut data={type_data} />
            }
        </Fragment>
    )
}

export default Meta
