import React, {Fragment, useEffect, useState} from 'react'
import parse from 'html-react-parser';
import {blogURL, calculateTimeToRead, currentLang} from '../../utils/functions';
import {Link} from 'react-router-dom';
import Image from '../../components/common/Image'

function BlogItem({data, catInfo=null}) {
  const [publishDate, setPublishDate] = useState(null);
  useEffect(() => {
        if(data){
          var newdate = new Date(data.publish_datetime).toLocaleDateString(currentLang(), { day:"numeric", year:"numeric", month:"long" } );
          setPublishDate(newdate);
        }
  }, [data]);
  
  const showCategories = () => {
    if(catInfo){
      data.categories = catInfo;
    }
    if(data.categories){
      return data.categories.map((category, index) => {
        // check if last item
        var seprator = ' , ';
        if(index === data.categories.length - 1){
          seprator = '';
        }
        return (
          <Fragment key={index}>
            <span className='blog_category'> {category.title} </span> {seprator}
          </Fragment>
        )
      })
    }
  }
  return (
    
    <div className='blog__post'>
        {data &&
        <Fragment>
        <figure className='post-image'>
            <Link to={blogURL(data.slug, catInfo)} title={data.title}>
              <span className="time"> <span className='icon-time'></span> {calculateTimeToRead(data.body)} min. </span>
              <Image type="blog.list" src={data.image_url} className='img-fluid' alt={data.title} title={data.title} />
            </Link>
        </figure>
        <div className='meta-info'>
            <div className='heading'><Link to={blogURL(data.slug, catInfo)} title={data.title}>{data.title}</Link></div>
            <div className='excerpt'>{data.summary && parse(data.summary)}</div>
            <div className='post-data'>
                <span className='categories'> {catInfo && showCategories()} </span>
                <span className='sep'> | </span>
                <span className='date'> {publishDate && publishDate}</span>
            </div>
        </div>
        </Fragment>
        }
    </div>
  )
}

export default BlogItem