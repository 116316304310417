import ContentLoader from 'react-content-loader';
import React, { useState, useEffect } from 'react';

const ProductDetailLoader = () => {
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 992);

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 992);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {isWideScreen ? (
        <ContentLoader viewBox="0 0 1500 1024" speed={2} backgroundColor="#d9d9d9" foregroundColor="#ededed"  style={{ width: '100%' }}>
          <rect x="645.5" width="209" height="8" rx="4"/>
          <rect x="550" y="60" width="400" height="28" rx="5"/>
          <rect x="625" y="100" width="250" height="8" rx="4"/>
          <rect x="650" y="120" width="200" height="8" rx="4"/>
          <rect x="625.5" y="140" width="249" height="8" rx="4"/>
          <circle cx="343.5" cy="326" r="128"/>
          <rect x="9" y="554" width="139" height="11" rx="4"/>
          <rect x="55" y="571" width="47" height="11" rx="4"/>
          <circle cx="80" cy="523" r="19"/>
          <rect x="239" y="554" width="31" height="11" rx="4"/>
          <rect x="230" y="571" width="49" height="11" rx="4"/>
          <circle cx="256" cy="523" r="19"/>
          <rect x="407" y="554" width="49" height="11" rx="4"/>
          <rect x="402" y="571" width="59" height="11" rx="4"/>
          <circle cx="433" cy="523" r="19"/>
          <rect x="575" y="554" width="67" height="11" rx="4"/>
          <rect x="575" y="571" width="67" height="11" rx="4"/>
          <circle cx="610" cy="523" r="19"/>
          <rect y="632" width="687" height="392"/>
          <rect x="837" y="198" width="80" height="20" rx="5"/>
          <rect x="932" y="208" width="205" height="10" rx="5"/>
          <rect x="837" y="241" width="663" height="44"/>
          <rect x="837" y="308" width="22" height="22"/>
          <rect x="868" y="314" width="243" height="11" rx="4"/>
          <rect x="837" y="339" width="22" height="22"/>
          <rect x="837" y="370" width="22" height="22"/>
          <rect x="837" y="401" width="22" height="22"/>
          <rect x="868" y="376" width="217" height="11" rx="4"/>
          <rect x="868" y="345" width="275" height="11" rx="4"/>
          <rect x="868" y="407" width="302" height="11" rx="4"/>
          <rect x="837" y="446" width="190" height="24" rx="4"/>
          <circle cx="873" cy="968" r="19"/>
          <rect x="902" y="962" width="417" height="12" rx="4"/>
          <path d="M1463.94 973.561C1464.53 974.146 1465.47 974.146 1466.06 973.561L1475.61 964.015C1476.19 963.429 1476.19 962.479 1475.61 961.893C1475.02 961.308 1474.07 961.308 1473.49 961.893L1465 970.379L1456.51 961.893C1455.93 961.308 1454.98 961.308 1454.39 961.893C1453.81 962.479 1453.81 963.429 1454.39 964.015L1463.94 973.561ZM1463.5 971.5V972.5H1466.5V971.5H1463.5Z"/>
          <rect x="839" y="939" width="663" height="2"/>
          <rect x="839" y="940" width="2" height="56"/>
          <rect x="1498" y="940" width="2" height="56"/>
          <rect x="839" y="995" width="663" height="2"/>
          <circle cx="871" cy="523" r="19"/>
          <rect x="900" y="517" width="288" height="12" rx="4"/>
          <path d="M1461.94 528.561C1462.53 529.146 1463.47 529.146 1464.06 528.561L1473.61 519.015C1474.19 518.429 1474.19 517.479 1473.61 516.893C1473.02 516.308 1472.07 516.308 1471.49 516.893L1463 525.379L1454.51 516.893C1453.93 516.308 1452.98 516.308 1452.39 516.893C1451.81 517.479 1451.81 518.429 1452.39 519.015L1461.94 528.561ZM1461.5 526.5V527.5H1464.5V526.5H1461.5Z"/>
          <rect x="837" y="494" width="663" height="2"/>
          <rect x="837" y="495" width="2" height="56"/>
          <rect x="1498" y="495" width="2" height="56"/>
          <rect x="837" y="550" width="663" height="2"/>
          <circle cx="871" cy="603" r="19"/>
          <rect x="900" y="588" width="369" height="12" rx="4"/>
          <rect x="900" y="605" width="134" height="12" rx="4"/>
          <path d="M1461.94 608.561C1462.53 609.146 1463.47 609.146 1464.06 608.561L1473.61 599.015C1474.19 598.429 1474.19 597.479 1473.61 596.893C1473.02 596.308 1472.07 596.308 1471.49 596.893L1463 605.379L1454.51 596.893C1453.93 596.308 1452.98 596.308 1452.39 596.893C1451.81 597.479 1451.81 598.429 1452.39 599.015L1461.94 608.561ZM1461.5 606.5V607.5H1464.5V606.5H1461.5Z"/>
          <rect x="837" y="574" width="663" height="2"/>
          <rect x="837" y="575" width="2" height="342"/>
          <rect x="1498" y="575" width="2" height="342"/>
          <rect x="837" y="630" width="663" height="2"/>
          <rect x="837" y="665" width="663" height="2"/>
          <rect x="852" y="644" width="62" height="10" rx="4"/>
          <rect x="1193" y="644" width="62" height="10" rx="4"/>
          <rect x="1373" y="644" width="62" height="10" rx="4"/>
          <rect x="852" y="685.5" width="111" height="10" rx="4"/>
          <rect x="1183" y="677" width="97" height="28"/>
          <rect x="1363" y="685.5" width="107" height="10" rx="4"/>
          <rect x="837" y="715" width="663" height="2"/>
          <rect x="852" y="735.5" width="111" height="10" rx="4"/>
          <rect x="1183" y="727" width="97" height="28"/>
          <rect x="1363" y="735.5" width="107" height="10" rx="4"/>
          <rect x="837" y="765" width="663" height="2"/>
          <rect x="852" y="785.5" width="111" height="10" rx="4"/>
          <rect x="1183" y="777" width="97" height="28"/>
          <rect x="1363" y="785.5" width="107" height="10" rx="4"/>
          <rect x="837" y="815" width="663" height="2"/>
          <rect x="852" y="835.5" width="111" height="10" rx="4"/>
          <rect x="1183" y="827" width="97" height="28"/>
          <rect x="1363" y="835.5" width="107" height="10" rx="4"/>
          <rect x="837" y="865" width="663" height="2"/>
          <rect x="852" y="885.5" width="111" height="10" rx="4"/>
          <rect x="1183" y="877" width="97" height="28"/>
          <rect x="1363" y="885.5" width="107" height="10" rx="4"/>
          <rect x="837" y="915" width="663" height="2"/>
        </ContentLoader>
      ) : (
        <ContentLoader viewBox="0 0 600 1090" speed={2} backgroundColor="#d9d9d9" foregroundColor="#ededed"  style={{ width: '100%' }}>
          <rect x="195.5" width="209" height="8" rx="4"/>
          <rect x="195" y="60" width="210" height="28" rx="5"/>
          <rect x="175" y="100" width="250" height="8" rx="4"/>
          <rect x="200" y="120" width="200" height="8" rx="4"/>
          <rect x="175.5" y="140" width="249" height="8" rx="4"/>
          <circle cx="300" cy="298" r="100"/>
          <rect x="21" y="478" width="139" height="11" rx="4"/>
          <rect x="67" y="495" width="47" height="11" rx="4"/>
          <circle cx="92" cy="447" r="19"/>
          <rect x="225" y="478" width="31" height="11" rx="4"/>
          <rect x="216" y="495" width="49" height="11" rx="4"/>
          <circle cx="242" cy="447" r="19"/>
          <rect x="366" y="478" width="49" height="11" rx="4"/>
          <rect x="361" y="495" width="59" height="11" rx="4"/>
          <circle cx="392" cy="447" r="19"/>
          <rect x="507" y="478" width="67" height="11" rx="4"/>
          <rect x="507" y="495" width="67" height="11" rx="4"/>
          <circle cx="542" cy="447" r="19"/>
          <rect y="556" width="600" height="277"/>
          <rect y="857" width="22" height="22"/>
          <rect x="31" y="863" width="243" height="11" rx="4"/>
          <rect y="888" width="22" height="22"/>
          <rect y="919" width="22" height="22"/>
          <rect y="950" width="22" height="22"/>
          <rect x="31" y="925" width="217" height="11" rx="4"/>
          <rect x="31" y="894" width="275" height="11" rx="4"/>
          <rect x="31" y="956" width="302" height="11" rx="4"/>
          <rect y="995" width="190" height="24" rx="4"/>
          <rect x="598" y="1034" width="2" height="56"/>
          <circle cx="34" cy="1062" r="19"/>
          <rect x="63" y="1056" width="417" height="12" rx="4"/>
          <path d="M570.939 1067.56C571.525 1068.15 572.475 1068.15 573.061 1067.56L582.607 1058.01C583.192 1057.43 583.192 1056.48 582.607 1055.89C582.021 1055.31 581.071 1055.31 580.485 1055.89L572 1064.38L563.515 1055.89C562.929 1055.31 561.979 1055.31 561.393 1055.89C560.808 1056.48 560.808 1057.43 561.393 1058.01L570.939 1067.56ZM570.5 1065.5V1066.5H573.5V1065.5H570.5Z"/>
          <rect y="1033" width="600" height="2"/>
          <rect y="1034" width="2" height="56"/>
          <rect y="1089" width="600" height="2"/>
        </ContentLoader>
      )}
    </>
  );
};

export default ProductDetailLoader;