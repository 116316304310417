import React, {Fragment, useState} from 'react'
import {currentLang} from './../../../utils/functions';
import {siteInfo} from './../../../utils/config';
import { Helmet } from 'react-helmet-async';

function SchemaMeatcut({data}) {
    let jsonld = {
        "@context": "https://schema.org/",
        "@type": "ItemPage",
        "mainEntity": {
           "@type": "Product",
           "image": [data.image_url],
           "name": data.seo_title ?? data.title,
           "description": data.seo_description ?? (data.body?.replace(/<[^>]+>/g, '') || ""),
           "brand": {
            "@type": "Brand",
            "name": siteInfo[currentLang()]['domain']
            },
           "sku": data.slug,
        }
     }

    return (
        <Helmet>
        <script type="application/ld+json">
            {JSON.stringify(jsonld)}
        </script>
        </Helmet>
    )
}

export default SchemaMeatcut
