import ContentLoader from 'react-content-loader';
import React, { useState, useEffect } from 'react';

const BlogListLoader = () => {
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 992);

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 992);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {isWideScreen ? (
        <ContentLoader viewBox="0 0 1500 995" speed={2} backgroundColor="#d9d9d9" foregroundColor="#ededed"  style={{ width: '100%' }}>
          <rect x="550" width="400" height="28" rx="5"/>
          <rect x="363.5" y="78" width="112.167" height="61"/>
          <rect x="481.667" y="78" width="112.167" height="61"/>
          <rect x="599.833" y="78" width="112.167" height="61"/>
          <rect x="718" y="78" width="112.167" height="61"/>
          <rect x="836.167" y="78" width="112.167" height="61"/>
          <rect x="954.333" y="78" width="112.167" height="61"/>
          <rect x="1072.5" y="78" width="64" height="61"/>
          <rect x="1" y="190" width="478" height="386"/>
          <rect x="1" y="190" width="478" height="386"/>
          <rect x="511" y="190" width="478" height="386"/>
          <rect x="511" y="190" width="478" height="386"/>
          <rect x="1021" y="190" width="478" height="386"/>
          <rect x="1021" y="190" width="478" height="386"/>
          <rect x="1" y="608" width="478" height="386"/>
          <rect x="1" y="608" width="478" height="386"/>
          <rect x="511" y="608" width="478" height="386"/>
          <rect x="511" y="608" width="478" height="386"/>
          <rect x="1021" y="608" width="478" height="386"/>
          <rect x="1021" y="608" width="478" height="386"/>
        </ContentLoader>
      ) : (
        <ContentLoader viewBox="0 0 600 889" speed={2} backgroundColor="#d9d9d9" foregroundColor="#ededed"  style={{ width: '100%' }}>
          <rect x="156" width="288" height="28" rx="5"/>
          <rect y="78" width="128" height="61"/>
          <rect x="134" y="78" width="128" height="61"/>
          <rect x="268" y="78" width="128" height="61"/>
          <rect x="402" y="78" width="128" height="61"/>
          <rect x="536" y="78" width="64" height="61"/>
          <rect x="1" y="190" width="598" height="338"/>
          <rect x="1" y="190" width="598" height="338"/>
          <rect x="1" y="560" width="598" height="338"/>
          <rect x="1" y="560" width="598" height="338"/>
        </ContentLoader>
      )}
    </>
  );
};

export default BlogListLoader;