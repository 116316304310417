
import productionUrlMaps from '../assets/urlMaps/production.json';
import stagingUrlMaps from '../assets/urlMaps/staging.json';

if(window.location.href.includes('alpha') || window.location.href.includes('local')){
    console.log('alpha or local');

    const hours = 1; // Reset when storage is more than {expire_time} hours
    const now = new Date().getTime();
    const setupTime = localStorage.getItem('setupTime');
    if (setupTime == null) {
        localStorage.setItem('setupTime', now)
    } else {
        if(now-setupTime > hours*60*60*1000) {
            localStorage.clear()
            localStorage.setItem('setupTime', now);
        }
    }
}

/**
 * Site Info
 */
export const siteInfo = {
    "nl": {
        "theme":"nl",
        "title": "Grutto",
        "language":"dutch",
        "lang": "nl-NL",
        "currency":"EUR",
        "locale": "nl_NL",
        "domain":"Grutto.com",
        "url":"https://www.grutto.com/nl/",
        "url_staging":"https://staging.grutto.com/nl/",
        "url_local":"http://grutto.local/nl/",
        "logo":"logo.svg",
        "fbadmins":"528605767",
        "twtitle":"Grutto",
        "twitter":"@grutto",
        "categories":{"cow":"koe1", "chicken":"kip1", "deer":"hert1"},
        "payments":["ideal", "mastercard", "visa", "amex", "paypal", "klarna", "google-pay", "apple-pay"],
        "animal_types":["cow", "chicken", "pig", "game", "deer", "boar", "turkey", "sheep", "fish", "salmon", "duck", "codfish"]
        },
    "de": {
        "theme":"de",
        "title": "Kaufnekuh",
        "language":"german",
        "lang": "de-DE",
        "currency":"EUR",
        "locale": "de_DE",
        "domain":"Kaufnekuh.de",
        "url":"https://www.kaufnekuh.de/de/",
        "url_staging":"https://staging.kaufnekuh.de/de/",
        "url_local":"http://grutto.local/de/",
        "logo":"logo.svg",
        "fbadmins":"528605767",
        "twtitle":"Grutto",
        "twitter":"@grutto",
        "categories":{"cow":"koe1", "chicken":"kip1", "deer":"hert1"},
        "payments":["dpd", "paypal", "mastercard", "visa", "amex", "sofort", "giropay", "klarna", "google-pay", "apple-pay"],
        "animal_types":["cow", "chicken", "pig", "game", "deer", "roe", "boar", "turkey", "sheep", "fish", "salmon", "codfish", "duck", "goose"]
    },
    "uk": {
        "theme":"uk",
        "title": "Grutto",
        "language":"english",
        "lang": "en-GB",
        "currency":"GPB",
        "locale": "en_GB",
        "domain":"Grutto.com",
        "url":"https://www.grutto.com/uk/",
        "url_staging":"https://staging.grutto.com/uk/",
        "url_local":"http://grutto.local/uk/",
        "logo":"logo.svg",
        "fbadmins":"528605767",
        "twtitle":"Grutto",
        "twitter":"@grutto",
        "categories":{"cow":"koe1", "chicken":"kip1", "deer":"hert1"},
        "payments":["mastercard", "visa", "amex", "paypal", "klarna"],
        "animal_types":["cow", "chicken", "pig", "turkey"]
    },
    "fr": {
        "theme":"fr",
        "title": "Grutto",
        "language":"french",
        "lang": "fr-FR",
        "currency":"EUR",
        "locale": "fr_FR",
        "domain":"Grutto.com",
        "url":"https://www.grutto.com/fr/",
        "url_staging":"https://staging.grutto.com/fr/",
        "url_local":"http://grutto.local/fr/",
        "logo":"logo.svg",
        "fbadmins":"528605767",
        "twtitle":"Grutto",
        "twitter":"@grutto",
        "categories":{"cow":"koe1", "chicken":"kip1", "deer":"hert1"},
        "payments":["mastercard", "visa", "amex", "paypal", "klarna", "kadeos"],
        "animal_types":["cow","pig", "chicken", "sheep"]
    }
}

export const defaultLang = process.env.REACT_APP_LANG;

export const isEnvironment = (envs) => envs.some(env => window.location.href.includes(env));

export const urlMaps = isEnvironment(['alpha', 'local', 'staging'])  ? stagingUrlMaps : productionUrlMaps;