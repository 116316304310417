import React, {Fragment} from 'react';
import Image from '../../components/common/Image';
import {farmerURL} from '../../utils/functions';
import {uniqueId} from 'lodash';
import { useTranslation } from 'react-i18next';
import W from '../../components/common/Word';
import { Link } from 'react-router-dom';

const FarmerItem = ({data, type, animal}) => {
    const { t } = useTranslation();
    const url = farmerURL(data.slug+(data.slug == t("farmers.newfarmerslug") ? "/"+animal : ""));
    const farmer_image_type = (data.slug != t("farmers.newfarmerslug") ? "farmers.list" : null);
    return (
        <div className="list__item" key={uniqueId('farmerItem_')}>
            <div className="list__body">
                <div className="list__image">
                    <Link className="read-more text--primary" to={url} title={data.title}><Image type={farmer_image_type} src={data.image_url} alt={data.title} title={data.title} /></Link>
                    {data.location &&
                    <div className="list__info">
                        <div className="badge">
                            <div className="badge__item location"> <span className="icon-location"></span> {data.location} </div>
                        </div>
                    </div>
                    }
                    <div className="list__badges">
                        {data.quality_levels && data.quality_levels.includes('premium') &&
                            <div className="badge badge--premium">
                                Premium
                            </div>
                        }
                        {data.is_organic == 1 &&
                            <div className="badge badge--bio">
                                <W>farmers.bio</W>
                            </div>
                        }
                        {data.is_msc == 1 &&
                            <div className="badge badge--msc">
                                MSC
                            </div>
                        }
                        {data.has_label_rouge == 1 &&
                            <div className="badge badge--label-rouge">
                                Label Rouge
                            </div>
                        }
                    </div>
                </div>
                <div className="list__description">
                    <div>
                        <div className="title"> <Link className="link" to={url} title={data.title}>{data.title}</Link> </div>
                        <div className="excerpt">{data.summary}</div>
                    </div>
                    <Link className="read-more" to={url}>
                        {['game', 'roe', 'deer', 'boar'].includes(type) &&
                            <Fragment>
                            {t("farmers.readmoreaboutfarmer_deer")}
                            </Fragment>
                        }
                        {['fish', 'salmon'].includes(type) &&
                            <Fragment>
                            {t("farmers.readmoreaboutfarmer_fish")}
                            </Fragment>
                        }
                        {!['game', 'roe', 'deer', 'boar', 'fish', 'salmon'].includes(type) &&
                            <Fragment>
                                {t("farmers.readmoreaboutfarmer")}
                            </Fragment>
                        }
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default FarmerItem
