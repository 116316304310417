import React, {useEffect, useState, Fragment, useRef } from 'react';
import RecipeItem from './RecipeItem';
import Pagination from '../../components/common/Pagination'
import flatstore from 'flatstore';
import LoadModules from '../../components/modules/LoadModules'
import {getRecipes, getStaticPage} from '../../utils/services';
import DataFilter from '../../components/common/DataFilter';
import SearchState from '../../components/common/SearchState';
import {currentLang, accentsTidy, recipeURL, listSearch, listFilter} from '../../utils/functions';
import {isNil, isNull} from 'lodash';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ListStatic from '../inc/ListStatic';
import RecipeListLoader from '../../assets/contentLoader/RecipeListLoader';

const RecipeList = ({loading, recipes_data, recipes_list_static, page, list_filter_type, list_search_status, list_filtered_data, list_active_page}) => {
	const [data, setData] = useState();
	const [staticData, setStaticData] = useState();
	const [modules, setModules] = useState(null);
	const [filteredData, setFilteredData] = useState([]);
	const [filterType, setFilterType] = useState(null);
	const [searchStatus, setSearchStatus] = useState(null);
	const [activePage, setActivePage] = useState(1);
	const { t } = useTranslation();
	const location = useLocation();

	const type = 'recipes';

	useEffect(() => {
		if (isNil(recipes_data) && isNil(recipes_list_static)) {
			getRecipes();
			getStaticPage(t("menu.recipes"), 'recipes_list_static');
		}
		if(recipes_data && !isNil(recipes_data.data)){
			setData(recipes_data.data);
			listFilter(type);
		}

	}, [recipes_data, location.pathname]);

	useEffect(() => {

		if(!isNil(recipes_list_static)){
			setStaticData(recipes_list_static);
			if(recipes_list_static.modules && recipes_list_static.modules.module !== null){
				setModules(recipes_list_static.modules);
			}
		}
	}, [recipes_list_static]);

	useEffect(() => {
		setFilteredData(list_filtered_data)
	}, [list_filtered_data])

	useEffect(() => {
		if(list_filter_type != filterType){
			setFilterType(list_filter_type);
			listFilter(type);
		}
	}, [list_filter_type])

	useEffect(() => {
		setActivePage(activePage);
		listFilter(type);
	}, [list_active_page])

	useEffect(() => {
		setSearchStatus(list_search_status);
	}, [list_search_status])

	//Creating a Refrence for the DataFilter Section
	const dataFilterRef = useRef(null);

	//Scrolling to the refrence point
	const scrollToFilter = () => {
		setTimeout(function() {
			if (dataFilterRef.current) {  // Check if the reference is not null
				window.scrollTo({
					top: dataFilterRef.current.offsetTop,
					behavior: 'smooth',
				});
			}
		}, 1);
	};
	if (loading) {
		return (
			<div className="container mt-5 mb-5">
				<div className="row">
					<div className="col-12">
						<RecipeListLoader />
					</div>
				</div>
			</div>
		)
	} else {

		return (
			<Fragment>
			{modules &&
				<LoadModules modules={modules} type="top" />
			}
			<div className="container">

				{staticData &&
					<ListStatic data={staticData} curPage={list_active_page} filterType={filterType} />
				}

				{/* This div is creating a refrence point to be used later for scrolling */}
				<div className='filter-ref' ref={dataFilterRef}> </div>
				<DataFilter type={type} filterType={filterType} data={data} />

				<div className="row">
					{filteredData && filteredData.map((item, index)=>{
						return (
							<div key={index} className="col-xs-12 col-md-6 col-lg-4">
								<RecipeItem detail={item} />
							</div>
						)
					})}
				</div>

				<SearchState status={searchStatus} />

				{data && filteredData && activePage &&
					<Pagination url={recipeURL('')} data={filteredData} curPage={list_active_page} filterType={filterType} scrollToFilter={scrollToFilter} />
				}

			</div>
			{modules &&
				<LoadModules modules={modules} type="bottom" />
			}
			</Fragment>
		)
	}
}

export default flatstore.connect(['loading', 'recipes_data', 'recipes_list_static', 'list_search_status', 'list_filtered_data', 'list_filter_type', 'list_active_page'])(RecipeList);
