import ContentLoader from 'react-content-loader';
import React, { useState, useEffect } from 'react';

const FarmshopListLoader = () => {
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 992);

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 992);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {isWideScreen ? (
        <ContentLoader viewBox="0 0 1420 1006" speed={2} backgroundColor="#F7F3E8" foregroundColor="#ffffff"  style={{ width: '100%' }}>
          <rect x="460" width="500" height="23" rx="5"/>
          <rect x="605.5" y="31" width="209" height="8" rx="4"/>
          <rect x="585" y="47" width="250" height="8" rx="4"/>
          <rect x="585.5" y="63" width="249" height="8" rx="4"/>
          <rect x="420.5" y="101" width="97" height="61"/>
          <rect x="523.5" y="101" width="97" height="61"/>
          <rect x="626.5" y="101" width="97" height="61"/>
          <rect x="729.5" y="101" width="97" height="61"/>
          <rect x="832.5" y="101" width="97" height="61"/>
          <rect x="935.5" y="101" width="64" height="61"/>
          <rect y="192" width="90" height="20" rx="5"/>
          <rect x="100" y="201" width="1320" height="2" rx="1"/>
          <rect width="236.5" height="262" transform="translate(0 242)"/>
          <rect width="236.5" height="262" transform="translate(266.5 242)"/>
          <rect width="236.5" height="262" transform="translate(533 242)"/>
          <rect width="236.5" height="262" transform="translate(799.5 242)"/>
          <rect y="534" width="1036" height="180"/>
          <rect width="236.5" height="262" transform="translate(0 744)"/>
          <rect width="236.5" height="262" transform="translate(266.5 744)"/>
          <rect width="236.5" height="262" transform="translate(533 744)"/>
          <rect width="236.5" height="262" transform="translate(799.5 744)"/>
          <rect x="1086" y="242" width="334" height="182"/>
        </ContentLoader>
      ) : (
        <ContentLoader viewBox="0 0 600 1238" speed={2} backgroundColor="#F7F3E8" foregroundColor="#ffffff"  style={{ width: '100%' }}>
          <rect x="50" width="500" height="23" rx="5"/>
          <rect x="195.5" y="31" width="209" height="8" rx="4"/>
          <rect x="175" y="47" width="250" height="8" rx="4"/>
          <rect x="175.5" y="63" width="249" height="8" rx="4"/>
          <rect y="101" width="101.2" height="61"/>
          <rect x="107.2" y="101" width="101.2" height="61"/>
          <rect x="214.4" y="101" width="101.2" height="61"/>
          <rect x="321.6" y="101" width="101.2" height="61"/>
          <rect x="428.8" y="101" width="101.2" height="61"/>
          <rect x="536" y="101" width="64" height="61"/>
          <rect y="192" width="90" height="20" rx="5"/>
          <rect x="100" y="201" width="500" height="2" rx="1"/>
          <rect width="285" height="262" transform="translate(0 242)"/>
          <rect width="285" height="262" transform="translate(315 242)"/>
          <rect y="524" width="600" height="150"/>
          <rect width="285" height="262" transform="translate(0 694)"/>
          <rect width="285" height="262" transform="translate(315 694)"/>
          <rect width="285" height="262" transform="translate(0 976)"/>
          <rect width="285" height="262" transform="translate(315 976)"/>
        </ContentLoader>
      )}
    </>
  );
};

export default FarmshopListLoader;