import React, {useState, Fragment, useEffect} from 'react';
import {getStorage, setStorage} from './../../../utils/storage';
//import { LazyLoadImage } from 'react-lazy-load-image-component';
import {isNil} from 'lodash';

const Image = ({src, className, alt, title, width, height, type}) => {
    const [image, setImage] = useState(null);
    const fileExt = src && src.split('.').pop();
    var version = false;
    const size = {
        products: {
            slider:{
                desktop: '',
                tablet: '',
                mobile: '',
            },
            card:{
                desktop: 'fit-340x210',
                tablet: 'fit-340x210',
                mobile: 'fit-340x210',
            },
            breed:{
                desktop: 'breed',
                tablet: 'breed',
                mobile: 'breed',
            },
            breed_profile:{
                desktop: 'breed_profile',
                tablet: 'breed_profile',
                mobile: 'breed_profile'
            },
            meatcut:{
                desktop: 'meatcut',
                tablet: 'meatcut',
                mobile: 'meatcut',
            }
        },
        farmers: {
            list:{
                desktop: 'fit-460x210',
                tablet: 'fit-340x210',
                mobile: 'fit-340x210',
            },
            cover:{
                desktop: 'fit-460x210',
                tablet: 'fit-340x210',
                mobile: 'fit-340x210',
            },
            card:{
                desktop: 'fit-50x50',
                tablet: 'fit-50x50',
                mobile: 'fit-50x50',
            },
            avatar:{
                desktop: 'fit-250x250',
                tablet: 'fit-250x250',
                mobile: 'fit-250x250',
            },
            module:{
                desktop: 'fit-200x200',
                tablet: 'fit-200x200',
                mobile: 'fit-200x200',
            },
            header:{
                desktop: 'fit-1600x500',
                tablet: 'fit-992x400',
                mobile: 'fit-767x250',
            }
        },
        recipes: {
            list:{
                desktop: 'fit-460x210',
                tablet: 'fit-340x210',
                mobile: 'fit-340x210',
            },
            header:{
                desktop: 'resize-800',
                tablet: 'resize-710',
                mobile: 'resize-500',
            }
        },
        meatcuts:{
            full:{
                desktop: 'fit-710x470',
                tablet: 'fit-710x470',
                mobile: 'fit-500x330',
            },
            default:{
                desktop: 'resize-710',
                tablet: 'resize-710',
                mobile: 'resize-710',
            }
        },
        breeds:{
            full:{
                desktop: 'fit-710x470',
                tablet: 'fit-710x470',
                mobile: 'fit-500x330',
            },
            product:{
                desktop: 'fit-340x225',
                tablet: 'fit-340x225',
                mobile: 'fit-340x225'
            },
            avatar:{
                desktop: 'fit-250x250',
                tablet: 'fit-250x250',
                mobile: 'fit-250x250',
            },
            card:{
                desktop: 'fit-50x50',
                tablet: 'fit-50x50',
                mobile: 'fit-50x50'
            }
        },
        farmshop:{
            list:{
                desktop: 'fit-340x225',
                tablet: 'fit-340x225',
                mobile: 'fit-340x225',
            },
            full:{
                desktop: '',
                tablet: '',
                mobile: '',
            }
        },
        blog:{
            list:{
                desktop: 'fit-460x210',
                tablet: 'fit-460x210',
                mobile: 'fit-460x210',
            },
            full:{
                desktop: 'resize-1200',
                tablet: 'resize-1200',
                mobile: 'resize-1200',
            }
        },
        modules:{
            slider:{
                desktop: 'large',
                tablet: 'medium',
                mobile: 'small',
            }
        }
    }
    const get = (t, path) => path.split(".").reduce((r, k) => r?.[k], t);
    const device = (window.innerWidth >= 992) ? 'desktop' : (window.innerWidth > 768) ? 'tablet' : 'mobile';


    const renameImage = (fileName, device, type=null) => {
        var imageSize = null;
        if(type){
            imageSize = get(size, type)[device];
        }
        if(fileName && imageSize){
            return fileName.replace('.'+fileExt, '-'+imageSize+'.'+fileExt);
        }else{
            return fileName;
        }
    }

    const loadWebp = (image) => {
        var webp_disable = getStorage('webp_disable');
        if(webp_disable){
            return image;
        }else{
            // Decode the URL first to handle any pre-encoded parts
            let decodedImage = decodeURIComponent(image);
            // Replace the file extension and then encode the URI
            let updatedImage = decodedImage.replace('.' + fileExt, '.webp');
            return encodeURI(updatedImage);
        }
    }

    useEffect(() => {
        if(src){
            setImage(renameImage(src, device, type));
        }
    }, [src])

    const webp_support = () => {
        var webp_disable = getStorage('webp_disable');
        if(webp_disable){
            return false;
        }else{
            return true;
        }
    }



    return (
        <Fragment>
            {fileExt == 'svg' &&
                <img src={src} className={(!isNil(className)? className : "")} width={width ? width : ''} height={height ? height : ''} alt={alt ? alt : ''} title={title ? title : ''} />
            }
            {fileExt != 'svg' &&
                <Fragment>
                {image &&
                    <picture>
                        {webp_support() &&
                            <source srcSet={image && loadWebp(image)} type="image/webp" />
                        }
                        <source srcSet={encodeURI(image)} type="image/jpeg" />
                        <img src={image && loadWebp(image)} className={(!isNil(className)? className : "")} width={width ? width : ''} height={height ? height : ''} alt={alt ? alt : ''} title={title ? title : ''} />
                    </picture>
                }
                </Fragment>
            }
        </Fragment>
    )
}

export default Image
