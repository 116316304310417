import React, {useState, Fragment}from 'react'
import Image from '../../../common/Image'
import parse from 'html-react-parser';
import {farmerURL} from '../../../../utils/functions';
import { useTranslation } from 'react-i18next';
import {isNil, upperFirst} from 'lodash';
import W from '../../../common/Word';
import { Link } from 'react-router-dom';

const ProfileFarmer = ({name, body, link, image, title, location, earnumber, breeds, isnew, animal}) => {
  const [toggled, setToggled] = useState(false);
  const { t } = useTranslation();
  const url = farmerURL(((isnew == 1) ? t("farmers.newfarmerslug")+"/"+t(animal) : link));

  var characterClass = '';
  if(title && title.split(" ")[0].length >= 11){ // total characters of first word
    characterClass = ' long-name';
  }

  function HandleTooltipPopup(event) {
    if(window.innerWidth < 992){
      //Array.prototype.forEach.call(document.querySelectorAll('.open'), (el)=>el.classList.remove('open'))
      setToggled(!toggled)
    }
  }


  
  let toggledClass = (toggled) ? "open" : "";
  //console.log(link);
  return (
    <Fragment>
    <div className="item item__farmer">
      <div className={`farmer tooltip tooltip--left`} onClick={HandleTooltipPopup}>
        <div className={`profile tooltip__info ${toggledClass}`}>
          <div className="avatar">
            {image ? <Image type="farmers.card" src={image} alt={title} title={title} /> : <span className="icon-farmers"></span>}
          </div>
          <div className={`tooltip__content tooltip__farmers`}>
            <a className="icon-close align__right" onClick={HandleTooltipPopup}></a>
            <div className="tooltip__content--info famers__info">
              <div className="row">
                <div className="col-4 tooltip__content--icon famers__info">
                  <span className="icon-farmers"></span>
                </div>
                <div className="col-8 tooltip__content--list famers__info">

                    {!['game', 'deer', 'boar', 'roe'].includes(animal) &&
                      <div className="farmers--name">
                        <span> {upperFirst(t('farmers.'+animal))}: </span>
                        <div className='heading'>{title}</div>
                      </div>
                    }
                    {['game', 'deer', 'boar', 'roe'].includes(animal) &&
                      <div className="farmers--location">
                        <span> {upperFirst(t('farmers.location'))}: </span>
                        <div className='heading'>{location}</div>
                      </div>
                    }

                </div>
              </div>
            </div>

            <div className="desc">
              <ul className="list list--no-list">
                {!['game', 'deer', 'boar', 'roe'].includes(animal) &&
                  <li className="farmers--location">{upperFirst(t('farmers.location'))}: <strong>{location}</strong> </li>
                }
                {breeds &&
                <li className="farmers--breed">{upperFirst(t('farmers.breeds'))}:
                <strong>
                  {
                    breeds && " "+breeds
                  }
                </strong>
                </li>
                }
                {earnumber &&
                <li className="farmers--ear-number">{upperFirst(t('product.earnumber.'+animal))}: <strong>{earnumber}</strong> </li>
                }
              </ul>
            </div>

            <div className="body">{body && parse(body)}</div>

              {['game', 'roe', 'deer', 'boar'].includes(animal) &&
                  <Link to={url} title={t("farmers.readmoreaboutfarmer_deer")}>
                    <W>farmers.readmoreaboutfarmer_deer</W>
                  </Link>
              }
              {['fish', 'salmon'].includes(animal) &&
                  <Link to={url} title={t("farmers.readmoreaboutfarmer_fish")}>
                    <W>farmers.readmoreaboutfarmer_fish</W>
                  </Link>
              }
              {!['game', 'roe', 'deer', 'boar', 'fish', 'salmon'].includes(animal) &&
                  <Link to={url} title={t("farmers.readmoreaboutfarmer")}>
                    <W>farmers.readmoreaboutfarmer</W>
                  </Link>
              }
          </div>
        </div>
        <div className="tooltip__title">
          <span className={"title"+characterClass}>
            {!['game', 'deer', 'boar', 'roe'].includes(animal) &&
                <span className="gray"> {upperFirst(t('farmers.'+animal))}: </span>
            }
            {['game', 'deer', 'boar', 'roe'].includes(animal) &&
                <span className="gray"> {upperFirst(t('farmers.location'))}: </span>
            }
            {title} 
          </span>
        </div>
      </div>
    </div>
  </Fragment>
  )
}

export default ProfileFarmer
