import React, {Fragment, useState} from 'react'
import {currentLang} from './../../../utils/functions';
import {siteInfo} from './../../../utils/config';
import {truncate, split, toNumber, isEmpty} from 'lodash';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

const SchemaRecipe = ({data}) => {
    const { t } = useTranslation();
    let jsonld = {
            "@context": "https://schema.org/",
            "@type": "Recipe",
            "name": data.seo_title ?? data.title,
            "image": [data.image_url],
            "author": {
                "@type": "Organization",
                "name": t('website_name')
            },
            "datePublished": split(data.update_at, ' ')[0],
            "description": data.seo_description ?? data.body,
            "recipeYield": (data.portion_amount && toNumber(data.portion_amount) > 0) ? toNumber(data.portion_amount) : "2",

        };

    var recipe_times = {};
    let recipeIngredients = [];
    let recipeInstructions = [];


    if(data.prep_time){
        let prep_time = data.prep_time.replace(/^(.*-)?(\d+).*$/g, '$2');
        recipe_times.prep_time_num = toNumber(prep_time);
        if(recipe_times.prep_time_num > 0){
            recipe_times.prep_time_iso = 'PT' + prep_time + 'M';
            jsonld['prepTime'] = recipe_times.prep_time_iso;
        }
    }

    if(data.cook_time){
        let cook_time = data.cook_time.replace(/^(.*-)?(\d+).*$/g, '$2');
        recipe_times.cook_time_num = toNumber(cook_time);
        if(recipe_times.cook_time_num > 0){
            recipe_times.cook_time_iso = 'PT' + cook_time + 'M';
            jsonld['cookTime'] = recipe_times.cook_time_iso;
        }
    }

    if(data.prep_time || data.cook_time){
        let prep_time = recipe_times.prep_time_num ? recipe_times.prep_time_num : 0;
        let cook_time = recipe_times.cook_time_num ? recipe_times.cook_time_num : 0;
        recipe_times.total_time_iso = 'PT' + (prep_time + cook_time) + 'M' ;
        jsonld['totalTime'] = recipe_times.total_time_iso;
    }

    if(data.calories) jsonld['nutrition'] = {"@type": "NutritionInformation", "calories": data.calories};

    if(data.ingredients){
        data.ingredients.map(item=>{
            var ingredientText = (item.amount ? item.amount+' ' : '' )+item.title;
            recipeIngredients.push(ingredientText);
        });
        jsonld["recipeIngredient"] = recipeIngredients;
    }
    if(data.instructions){
        data.instructions.map(item=>{
            recipeInstructions.push({
                "@type": "HowToStep",
                "text": item.body,
                "image": item.image_url
            });
        });

        jsonld["recipeInstructions"] = recipeInstructions
    }

    return (
        <Helmet>
            <script type="application/ld+json">
                {JSON.stringify(jsonld)}
            </script>
        </Helmet>
    )
}

export default SchemaRecipe
