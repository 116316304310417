import React from 'react';
import FarmshopBundleBox from './FarmshopBundleBox';
import W from '../../../components/common/Word';
import { useLocation } from 'react-router-dom';

const FarmshopBundles = ({ data, farmshop_data, setCartHandler }) => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const initialBundle = params.get('bundle');

    if (!Array.isArray(data) || data.length === 0) {
        return;
    }

    return (
        <>
            <div className='animal_group animal_group_bundles'>
                <a name="products_bundles"></a>
                <h2 className='title m-0'>
                    <span className='name'><W>farmshop.bundle.title</W></span>
                    <span className="sep">-</span>
                    <span className="desc desc1"><W>farmshop.bundle.desc</W></span>
                </h2>
                <hr id="animal_type_bundles"></hr>
            </div>
            {data.map((bundle, index) => (
                <FarmshopBundleBox
                    key={index}
                    bundle={bundle}
                    farmshop_data={farmshop_data}
                    setCartHandler={setCartHandler}
                    initialBundle={initialBundle}
                />
            ))}
        </>
    );
};

export default FarmshopBundles;