import React, { useState, Fragment }from 'react'
import parse from 'html-react-parser';
import Image from '../../../common/Image'
import W from '../../../common/Word';
import {isNil, upperFirst} from 'lodash';
import { useTranslation } from 'react-i18next';
import { breedURL } from '../../../../utils/functions';
import { Link } from 'react-router-dom';

const ProfileBreed = ( {title, slug, image, body } ) => {
  const [toggled, setToggled] = useState(false)
  const { t } = useTranslation();
  const url = breedURL(slug);

  var characterClass = '';
  if(title && title.split(" ")[0].length >= 11){ // total characters of first word
    characterClass = ' long-name';
  }

  function HandleTooltipPopup(event) {
    if(window.innerWidth < 992){
      //Array.prototype.forEach.call(document.querySelectorAll('.open'), (el)=>el.classList.remove('open'))
      setToggled(!toggled)
    }
  }

  let toggledClass = (toggled) ? "open" : "";
  
    return (
      <Fragment>
        {title && 
          <div className="item profile-breed">
            <div className={`tooltip breed tooltip--center`} onClick={HandleTooltipPopup}>
              <div className={`profile tooltip__info ${toggledClass}`}>
                <a className="tooltip__info--link"></a>
                <div className="avatar">
                  {image ? <Image type="breeds.card" alt={title} title={title} src={image} /> : <span className={"icon-breed"}></span>}
                </div>
                <div className={`tooltip__content tooltip__breed ${toggledClass}`}>
                    {image ? <Link to={url} title={title}><Image type="breeds.product" src={image} alt={title} title={title} className="img-fluid" /></Link> : ""}
                    <div className="breed--title"><Link to={url} title={title}>{title}</Link></div>
                    <Link to={url} title={t("breeds.readmore_infobox")}><W>breeds.readmore_infobox</W></Link>
                </div>
              </div>
              <div className="tooltip__title">
                  <span className={"title"+characterClass}>
                      <span className="icon-info"></span> <span className="gray">{upperFirst(t("product.breed"))}</span><br />
                      {title}
                  </span>
              </div>
            </div>
          </div>
        }
    </Fragment>
    )
}

export default ProfileBreed
