import ContentLoader from 'react-content-loader';
import React, { useState, useEffect } from 'react';

const SubscriptionCuratedLoader = () => {
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 992);

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 992);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {isWideScreen ? (
        <ContentLoader viewBox="0 0 1500 649" speed={2} backgroundColor="#F7F3E8" foregroundColor="#ffffff" style={{ width: '100%' }}>
          <rect x="550" width="400" height="28" rx="5"/>
          <rect x="324" y="40" width="852" height="8" rx="4"/>
          <rect x="1" y="100" width="478" height="247" rx="19"/>
          <rect x="1" y="100" width="478" height="247" rx="19"/>
          <rect x="511" y="100" width="478" height="247" rx="19"/>
          <rect x="511" y="100" width="478" height="247" rx="19"/>
          <rect x="1021" y="100" width="478" height="247" rx="19"/>
          <rect x="1021" y="100" width="478" height="247" rx="19"/>
        </ContentLoader>
      ) : (
        <ContentLoader viewBox="0 0 520 1314" speed={2} backgroundColor="#F7F3E8" foregroundColor="#ffffff" style={{ width: '100%' }}>
          <rect x="96" width="328" height="28" rx="5"/>
          <rect x="30" y="40" width="460" height="8" rx="4"/>
          <rect x="30" y="60" width="460" height="8" rx="4"/>
          <rect x="21" y="100" width="478" height="186" rx="19"/>
          <rect x="21" y="100" width="478" height="186" rx="19"/>
          <rect x="21" y="518" width="478" height="186" rx="19"/>
          <rect x="21" y="518" width="478" height="186" rx="19"/>
          <rect x="21" y="308" width="478" height="186" rx="19"/>
          <rect x="21" y="308" width="478" height="186" rx="19"/>
        </ContentLoader>
      )}
    </>
  );
};

export default SubscriptionCuratedLoader;