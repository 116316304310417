import React, {useState, useEffect, Fragment} from 'react'
import flatstore from 'flatstore';
import Loader from '../../../components/common/Loader';
import LoadModules from '../../../components/modules/LoadModules'
import {getMeatcutSync} from '../../../utils/services';
import Meta from '../../../components/common/Meta';
import NotFound from '../404';
import parse from 'html-react-parser';
import Image from '../../../components/common/Image';
import { apiURL, getUrlVars } from '../../../utils/functions';
import Traceability from '../../../components/product/packages/Traceability';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useParams } from 'react-router-dom';


function Meatcuts({meatcut_data, loading, err_data}) {
    const [data, setData] = useState(null);
    const [modules, setModules] = useState(0);
    const [productsData, setProductsData] = useState(null);
    const [hasFetched, setHasFetched] = useState(false);

    let {slug} = useParams();
    let moduleData = {"relatedSlug":slug, "relatedType": "meatcut"};
    const {t} = useTranslation();
    let traceability_data = {
        shop_brand_slug:'',
        shop_animal_nr: new Set(),
        title:new Set(),
        farmer_title:new Set(),
        image_url:new Set(),
        slug:new Set(),
        summary:new Set(),
        location:new Set(),
        is_organic:new Set(),
        is_msc:new Set(),
        has_label_rouge:new Set(),
        shop_birth_country:new Set(),
        shop_fattening_country:new Set(),
        shop_slaughter_place:new Set(),
        shop_portioning_place:new Set(),
        breed_title:new Set()
    };
    useEffect(() => {
        meatcut_data = null;

        var urls = getUrlVars();
        if(urls && (urls['product_id'] || urls['category_slug'])){

            var api_url = apiURL()+"/products";

            if(urls['product_id']){
                api_url += "/product-id/"+urls['product_id'];
            }else if(urls['category_slug']){
                api_url += "/category/"+urls['category_slug']+"/"+slug+"/"+urls['delivery_year']+"/"+urls['delivery_week']+"/"+urls['is_organic'];
            }

            try{
                axios.get(api_url, {}).then(function(response){
                    if(response.data){
                        setProductsData(response.data);
                    }
                });
            }catch(err){
                console.log(err);
            }
        }
    }, [])

    useEffect(() => {

		if (!loading && slug && !hasFetched) {
            getMeatcutSync(slug);
            setHasFetched(true); // set hasFetched to true after fetching
        }

    }, [loading, slug]);


    useEffect(() => {

        setData(meatcut_data);

		if(meatcut_data && meatcut_data.modules !== null){
            setModules(meatcut_data.modules);
        }

    }, [meatcut_data]);


    if (loading) {
		return <Loader />
	} else {
        return (
            <Fragment>
                <LoadModules modules={modules} moduleData={moduleData} type="top" />
                {!data && err_data && err_data == '404' &&
                    <NotFound />
                }
                {data &&
                    <Fragment>
                        <Meta
                            title={data.seo_title ?? data.title}
                            description={data.seo_description ?? data.body}
                            type="meatcut"
                            type_data={data}
                		    image={data.image_url}
                        />
                        <div className='anchor-box'>
                            <a name="meatcut-content"></a>
                        </div>
                        <div className="container meatcut__page my-6">
                            <div className="row">
                                <div className="col-12 mb-3">
                                    <h2 className="heading--h2">{data.title && data.title}</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-lg-5 order-lg-1 order-2">
                                    {data.body && parse(data.body)}
                                </div>
                                <div className="col-12 col-lg-6 offset-lg-1 order-lg-2 order-1 meatcut__img">
                                    <Image type="meatcuts.default" className="img" src={data.image_url} alt={data.title} title={data.title} />
                                </div>
                            </div>
                        </div>
                        {productsData &&
                            <div className='meatcut__traceability--row'>
                                <div className='container'>
                                    <div className='row'>
                                        <div className="col meatcut__traceability">
                                                {productsData.map((item, index)=>{
                                                        traceability_data.shop_brand_slug = item.animal_type;
                                                        traceability_data.shop_animal_nr.add(item.shop_animal_nr);
                                                        traceability_data.title.add(item.farmer_title);
                                                        traceability_data.image_url.add(item.image_url);
                                                        traceability_data.farmer_title.add(item.farmer_title);
                                                        traceability_data.slug.add(item.slug);
                                                        traceability_data.summary.add(item.summary);
                                                        traceability_data.location.add(item.location);
                                                        traceability_data.is_organic.add(item.is_organic);
                                                        traceability_data.is_msc.add(item.is_msc);
                                                        traceability_data.has_label_rouge.add(item.has_label_rouge);
                                                        traceability_data.shop_birth_country.add(item.shop_birth_country);
                                                        traceability_data.shop_fattening_country.add(item.shop_fattening_country);
                                                        traceability_data.shop_slaughter_place.add(item.shop_slaughter_place);
                                                        traceability_data.shop_portioning_place.add(item.shop_portioning_place);
                                                        traceability_data.breed_title.add(item.breed_title ? item.breed_title : item.breed);
                                                })}
                                                <Traceability type="meatcuts" data={traceability_data} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </Fragment>
                 }
                 {moduleData &&
                    <LoadModules modules={modules} moduleData={moduleData} type="bottom" />
                 }

            </Fragment>
        )
    }
}

export default flatstore.connect(['loading', 'meatcut_data', 'err_data'])(Meatcuts);
