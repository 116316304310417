import React, {useState, useEffect, Fragment} from 'react'
import flatstore from 'flatstore';
import Loader from '../../../components/common/Loader';
import LoadModules from '../../../components/modules/LoadModules'
import {getStatic} from '../../../utils/services';
import Meta from '../../../components/common/Meta';
import {isNil} from 'lodash';



const Mission = ({loading, static_data}) => {
    const [data, setData] = useState(null);
    const [modules, setModules] = useState(0);
    useEffect(() => {
        static_data = null;
    }, [])

    useEffect(() => {

		if (isNil(static_data)) {
            getStatic('mission', 'static_data');
        }
        if(!data){
            setData(static_data);
        }
        
		if(static_data && static_data.modules !== null){
            setModules(static_data.modules);
		}
		
    }, [static_data]);
    
    if (loading) {
		return <Loader />
	} else {

        return (
            <Fragment>
                <LoadModules modules={modules} type="top" />
                {data &&
                    <Meta
                        title={data.seo_title ? data.seo_title : data.title}
                        description={data.seo_description}
                    />
                }

                <LoadModules modules={modules} type="bottom" />

            </Fragment>
        )
    }
}

export default flatstore.connect(['loading', 'static_data'])(Mission);
