import React, {useEffect, useState, Fragment} from 'react';
import flatstore from 'flatstore';
import { Link } from 'react-router-dom';
import Loader from '../../components/common/Loader';
import Image from '../../components/common/Image'
import parse from 'html-react-parser';
import LoadModules from '../../components/modules/LoadModules';
import {getFarmerInfo, getStaticPage} from '../../utils/services';
import {isArray, upperFirst, filter, includes} from 'lodash';
import {breedURL, currentLang} from '../../utils/functions';
import NotFound from '../static/404';
import Meta from '../../components/common/Meta';
import { useTranslation } from 'react-i18next';
import W from '../../components/common/Word';
import {siteInfo} from './../../utils/config';
import FarmerDetailLoader from '../../assets/contentLoader/FarmerDetailLoader';


const FarmerDetail = ({slug, animal, loading, farmer_data, farmer_static, err_data}) => {
	const [data, setData] = useState(null);
	const [staticData, setStaticData] = useState(null)
	const [modules, setModules] = useState(0);
	const { t } = useTranslation();
	const [hasFetched, setHasFetched] = useState(false);
	const animals = siteInfo[currentLang()]['animal_types'];
	let moduleData = {"relatedSlug":slug, "relatedType": "farmer"};
	let firstAnimalType = '';

    useEffect(() => {
        if (loading === false && slug && !hasFetched) {
            if(slug === t('farmers.newfarmerslug')) // kalak rashti - Akbar should fix it later
                slug = 'nieuwe-boeren';
            getFarmerInfo(slug);
            getStaticPage(t('farmers.newfarmerslug'), 'farmer_static');
            setHasFetched(true); // set hasFetched to true after fetching
        }
    }, [loading, slug]);

	useEffect(() => {
		setData(farmer_data);

		if(farmer_data && farmer_data.modules !== null){
			setModules(farmer_data.modules);
		}

	}, [farmer_data]);

	useEffect(() => {
		if(farmer_static){
			setStaticData(farmer_static);
		}
	}, [farmer_static])

	if (typeof data !== 'undefined' && data !== null){
		if (!includes(animals,data.animal_types)){
			// If the farmer is new and has not sold animals yet, default animal-type to cow.
			firstAnimalType = 'cow';
			if (data.animal_types !== null && typeof data.animal_types !== 'undefined'){
				var allAnimalTypes = data.animal_types.split(',');
				firstAnimalType = allAnimalTypes[0];
			}
		} else {
			firstAnimalType = data.animal_types;
		}
	}

	if (loading) {
		return (
			<div className="container mt-5 mb-5">
				<div className="row">
					<div className="col-12">
						<FarmerDetailLoader />
					</div>
				</div>
			</div>
		)
	} else {

		return (
			<Fragment>
				{!data && err_data && err_data == '404' &&
					<NotFound />
				}

			{data && isArray(data) &&
					<div className="container">
						{staticData &&
							<Meta
								title={staticData.seo_title ? staticData.seo_title : staticData.title}
								description={staticData.seo_description && staticData.seo_description}
								type="website"
                				image={staticData.image_url}
							/>
						}
						{staticData &&
							<div className="row">
								<div className="col-12 col-md-6 offset-md-3 pt-70">
									<h1 className="heading--h1">{staticData.title}</h1>
									{staticData &&
										<p>
											{parse(staticData.body)}
										</p>
									}
								</div>
							</div>
						}


						<div className="row row__spacing">
							{animals.map(animalItem=>{
								if(animal && animal != t(animalItem)){
									return false;
								}
								var checkAnimal = filter(data, {"animal_types": animalItem});
								return (

									<Fragment>
									{checkAnimal && checkAnimal.length > 0 &&
										<div className="col-12 col-md-6 offset-md-3 new-farmers__title">
											<h3> <span className={"icon icon-cat-" + animalItem}></span> {t('datafilter.'+animalItem)}</h3>
										</div>
									}
									{data.map((item, index)=>{
										if(item.animal_types == animalItem){
											return (
												<div key={index} className="col-12 col-md-6 offset-md-3 new-farmers">
													<div className="new-farmers__img">
														<span className="icon-farmers"></span>
													</div>
													<div className="new-farmers__body">
														<h3 className="heading heading--h5">{item.title}</h3>
														{item.location && <div className="location"><strong><W>farmers.location</W></strong> {item.location}</div> }
														<div className="breeds"><strong><W>farmers.breeds</W>:</strong> {item.breeds_full && item.breeds_full.map((breedInfo)=>{
															return(
																<span>
																	{breedInfo.slug ? <Link to={breedURL(breedInfo.slug)}>{breedInfo.title}</Link> : breedInfo.title}
																</span>
															)
														})}</div>
														{item.body && <p className="mb-0 text--black"> {parse(item.body)} </p>}
													</div>
												</div>
											)
										}
									})}
									</Fragment>
								)
							})}
						</div>
					</div>
			}
			{data && !isArray(data) &&
				<Fragment>
					<LoadModules modules={modules} moduleData={moduleData} type="top" />
					<Meta
						title={data.seo_title ? data.seo_title : data.title}
						description={data.seo_description && data.seo_description}
						type="website"
                		image={data.image_url}
					/>
					<div className={"cover__farmer" + (" " + currentLang())}>
						<div className="container">
							<div className="row">
								<div className="col-12 body">
									<div className="farmer-user">
										<div className="avatar"><Image type="farmers.avatar" src={data.image_url} alt={data.title} title={data.title} /></div>
										<div className="title">
											<div className="specs">
												<div className="location">
													<span className="icon-location"></span>
													{data.location}
												</div>

												{data.is_organic == 1 &&
													<div className="bio"><span className="logo"></span></div>
												}
												{data.is_msc == 1 &&
													<div className="msc"><span className="logo"></span></div>
												}
												{data.has_label_rouge == 1 &&
													<div className="label-rouge"><span className="logo"></span></div>
												}

											</div>
											<h1 className="heading heading--h1">{data.title}</h1>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="cover__bg">
							<div className="gradient"></div>
							<Image type="farmers.header" src={data.header_image_url} alt={data.title} title={data.title} />
						</div>
					</div>
					<div className="container">
						<div className="row">
							<div className="col-12">
								<div className="farmer__body">
								<div className="desc__info bg--caramel mb-4">
									<ul>
										{data.animal_types != 'game' &&
											<Fragment>
											<li className="fld"><W>farmers.{firstAnimalType}</W></li>
											<li className="val">{data.title}</li>
											</Fragment>
										}
										<li className="fld"><W>farmers.location</W></li>
										<li className="val">{data.location}</li>

										<li className="fld"><W>farmers.breeds</W></li>
										<li className="val">
											{data.breeds_full && data.breeds_full.map((breedInfo, index)=>{
												return(
													<span key={"breed_info_"+index}>
														{breedInfo.slug ? <Link to={breedURL(breedInfo.slug)}>{breedInfo.title}</Link> : breedInfo.title}
													</span>
												)
											})}
										</li>
									</ul>
								</div>
								<div className="bio-label"></div>
								<div className="excerpt--full"> {data.body && parse(data.body)} </div>
								</div>
							</div>
						</div>
					</div>
				</Fragment>
			}

			<LoadModules modules={modules} moduleData={moduleData} type="bottom" />


			</Fragment>
		)
	}
}

export default flatstore.connect(['loading', 'farmer_data', 'farmer_static', 'err_data'])(FarmerDetail);
