import { useTranslation } from 'react-i18next';
import Button from '../../../../components/common/Button';

const PackageCta = ({stockStatus, shopUrl, btnDisable}) => {
  const { t } = useTranslation();
    
  return (
    <>
      {stockStatus != "instock" &&
        <Button
            disable={true}
            title={t("shop.status." + stockStatus)}
            type="addtocart"
            url="#"
        />
      }
      {stockStatus == "instock" &&
        <Button
            disable={btnDisable}
            title={t('shop.status.' + stockStatus)}
            type="addtocart"
            url={shopUrl}
        />
      }
    </>
  )
}

export default PackageCta