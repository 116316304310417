import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Image from "../../common/Image";
import parse from "html-react-parser";
import W from "../../common/Word";

function Navigator({ data }) {
  const { t } = useTranslation();
  const [showTraceability, setShowTraceability] = useState(false);

  useEffect(() => {
    // check if there is a "product_id" or "delivery_week" in the URL
    const params = new URLSearchParams(window.location.search);
    const productId = params.get("product_id");
    const deliveryWeek = params.get("delivery_week");
    setShowTraceability(productId !== null || deliveryWeek !== null);
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <div className="desc">
            <div className="text">
              <h1 className="heading--h2">{data.title && data.title}</h1>
              <p className="text--justify">{data.body && parse(data.body)}</p>
              {data.link_1 && (
                <ul className="navigator-menu">
                  {data.link_1 && (
                    <li>
                      <a href={data.link_1}>{data.link_1_title}</a>
                    </li>
                  )}
                  {data.link_2 && (
                    <li>
                      <a href={data.link_2}>{data.link_2_title}</a>
                    </li>
                  )}
                  {data.link_3 && (
                    <li>
                      <a href={data.link_3}>{data.link_3_title}</a>
                    </li>
                  )}
                  {showTraceability && (
                    <li>
                      <a href="#traceability-box">
                        <W>traceability.title</W>
                      </a>
                    </li>
                  )}
                </ul>
              )}
            </div>
            {data.image_url && (
              <div className="pic">
                <Image
                  src={data.image_url}
                  className="img-fluid"
                  alt={data.title && data.title}
                  title={data.title && data.title}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navigator;
