import React, {useState, useEffect, Fragment} from 'react'
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import W from '../../../common/Word';

const PackagePrice = ({fullPrice, mealPrice, prevPrice}) => {
  const [data, setData] = useState(null)
  const { t } = useTranslation();
  
    useEffect(() => {
        if(fullPrice && fullPrice.toString().indexOf('.') > -1 && mealPrice && mealPrice.toString().indexOf('.') > -1){
            var fullPriceArray = fullPrice.toString().split('.');
            var mealPriceArray = mealPrice.toString().split('.');
            if(prevPrice && prevPrice.toString().indexOf('.') > -1){
              var prevPriceArray = prevPrice.toString().split('.');
              setData({fullnumber: fullPriceArray[0], fulldecimal: fullPriceArray[1], mealnumber: mealPriceArray[0], mealdecimal: mealPriceArray[1], prevnumber: prevPriceArray[0], prevdecimal: prevPriceArray[1]});
            } else {
              setData({fullnumber: fullPriceArray[0], fulldecimal: fullPriceArray[1], mealnumber: mealPriceArray[0], mealdecimal: mealPriceArray[1]});
            }
        }else{
          setData({fullnumber: fullPrice, fulldecimal:'00', mealnumber: mealPrice , mealdecimal:'00'});
        }
      }, [fullPrice]);

    return (
      <div className="item full-price">
      <div className="package-per-person">
        {data &&
          <Fragment>
              <div className="amount">
                {data && data.prevnumber &&
                  <span className="prev-price">{parse(t('price_format', {price: data.prevnumber, decimal:data.prevdecimal}))}</span>
                }
                <div className='pack-price'>{parse(t('price_format', {price: data.fullnumber, decimal:data.fulldecimal}))}</div>
              </div>
              <span className="description">{parse(t('price_format', {price: data.mealnumber, decimal:data.mealdecimal}))} <W>product.perperson</W><br /><W>product.permeal</W></span>
          </Fragment>
        }
      </div>
      </div>
    )
}

export default PackagePrice
