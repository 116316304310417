import React, {useState, useEffect, Fragment} from 'react'
import { addClass, removeClass } from '../../../utils/functions';
import { setStorage, getStorage } from '../../../utils/storage';
import parse from 'html-react-parser';
import jsonp from 'jsonp';
import { useTranslation } from 'react-i18next';
import { isNil } from 'lodash';

function NewsletterPopup({data}) {

    // Don't show the slide in if the user has closed it or subscribed within a year
    if ( getStorage( 'newsletter_popup_dismiss' ) ) {
        return (<Fragment></Fragment>);
    }

    // Show the slide in after a delay
    useEffect(() => {
        const timer = setTimeout(() => {
            var panel = document.getElementsByClassName('module__newsletter-popup')[0];
            if ( panel ) {
                addClass( panel, 'show' );
            }
        } , 15000);
        return () => clearTimeout(timer);
    }, [] );

    const closePopup = ( e, dismiss_duration ) => {
        e && e.preventDefault();
        var panel = document.getElementsByClassName('module__newsletter-popup')[0];
        if ( panel ) {
            removeClass( panel, 'show' );
        }
        setStorage('newsletter_popup_dismiss', '1', 60 * 60 * 24 * dismiss_duration );
    }

    // Handle the subscribe event 
    const [email, setEmail] = useState('');
    const [messageType, setMessageType] = useState(null);
    const [message, setMessage] = useState(null);
    const { t } = useTranslation();
    let {mailchimpUrl, mailchimpGroupId, mailchimpId, buttonText, position, className, body, body2} = data;

    const handleSubmit = e => {
        e.preventDefault();
        if(email){
            setMessageType(null);
            mailchimpUrl = mailchimpUrl.replace('/post?', '/post-json?');
            var formKeyName = encodeURIComponent("group["+mailchimpGroupId+"]["+mailchimpId+"]");
            mailchimpUrl += "&"+formKeyName+"="+mailchimpId;
            mailchimpUrl += "&EMAIL="+encodeURIComponent(email);
            mailchimpUrl += "&subscribe=Subscribe";
            
            jsonp(mailchimpUrl, { param: "c" }, (err, data) => {
                if (err) {
                    setMessageType('error');
                } else if (data.result !== 'success') {
                    setMessageType('error');
                } else {
                    setMessageType('success' );
                    closePopup( null, 365 );
                };
                setMessage(data.msg);
            });
        }
    }

    return (
        <Fragment>
        {mailchimpUrl && mailchimpGroupId && mailchimpId &&
            <div className={"module__newsletter-popup" + ( ! isNil( className ) ? " " + className : "" ) + ( ! isNil( position ) ? " newsletter__text-"+position : "" ) }>
                <div className="container">
                    <a href="" className="newsletter__close" onClick={(e)=>{closePopup(e,30)}}><i className="icon-close"></i></a>
                    <div className="row newsletter__holder">
                        <div className="body">
                            {!isNil(body) && parse(body)}
                        </div>
                        <div className="form">
                            <div className="form__body">
                                {!isNil(body2) && parse(body2)}
                            </div>
                            <form method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" noValidate onSubmit={(e)=>{handleSubmit(e)}}>    
                                <div id="mc_embed_signup_scroll">
                                    <div className="mc-field-group">
                                        <input type="email" value={email ? email : ''} placeholder={t('newsletter.placeholder')} name="EMAIL" className="required email form-control" id="mce-EMAIL" onChange={(e)=>{setEmail(e.target.value)}} />
                                        <input type="submit" value={buttonText} name="subscribe" id="mc-embedded-subscribe" className="btn" />
                                    </div>
                                    {messageType && message &&
                                    <div className={"message message--"+messageType}>
                                        {parse(message)}
                                    </div>
                                    }
                                    <div id="mce-responses" className="clear">
                                        <div className="response" id="mce-error-response" style={{display: "none"}}></div>
                                        <div className="response" id="mce-success-response" style={{display: "none"}}></div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        }
        </Fragment>
    )
}

export default NewsletterPopup
