import React, {useEffect, useState, Fragment} from 'react';
import flatstore from 'flatstore';
import Loader from '../../components/common/Loader';
import Image from '../../components/common/Image'
import parse from 'html-react-parser';
import LoadModules from '../../components/modules/LoadModules';
import {getBreedInfo, getStaticPage} from '../../utils/services';
import {isArray, upperFirst, filter} from 'lodash';
import {currentLang} from '../../utils/functions';
import NotFound from '../static/404';
import Meta from '../../components/common/Meta';
import { useTranslation } from 'react-i18next';
import {siteInfo} from './../../utils/config';

const BreedsDetails = ({slug, animal, loading, breed_data, breed_static, err_data}) => {
	const [data, setData] = useState(null);
	const [staticData, setStaticData] = useState(null)
	const [modules, setModules] = useState(0);
	const { t } = useTranslation();
	const animals = siteInfo[currentLang()]['animal_types'];
	const [hasFetched, setHasFetched] = useState(false);
    let moduleData = {"relatedSlug":slug, "relatedType": "breed"};
    //let moduleData = {}

    useEffect(() => {
        if (loading === false && slug && !hasFetched) {
            getBreedInfo(slug);
			setHasFetched(true); // set hasFetched to true after fetching
        }
    }, [loading, slug]);

	useEffect(() => {
		setData(breed_data);

		if(breed_data && breed_data.modules !== null){
			setModules(breed_data.modules);
		}

	}, [breed_data]);

	if (loading) {
		return <Loader />
	} else {

		return (
			<Fragment>
				{!data && err_data && err_data == '404' &&
					<NotFound />
				}

			{data &&
				<Fragment>
					<LoadModules modules={modules} moduleData={moduleData} type="top" />
					<Meta
						title={data.seo_title ?? data.title}
						description={data.seo_description ?? data.body}
						type="website"
                		image={data.image_url}
					/>
					<div className="container my-5">
						<div className="row">
							<div className="col-12 mb-4">
								<h1 className="heading heading--h1">{data.title}</h1>
							</div>
						</div>
						<div className="row">
							<div className="col-12 col-lg-5 order-lg-1 order-2">
								<div className="excerpt--full text--justify"> {data.body && parse(data.body)} </div>
							</div>
							<div className="col-12 col-lg-6 offset-lg-1 order-lg-2 order-1">
								<Image type="breeds.full" src={data.image_url} className="img-fluid mb-4" />
							</div>
						</div>
					</div>
				</Fragment>
			}

			<LoadModules modules={modules} moduleData={moduleData} type="bottom" />


			</Fragment>
		)
	}
}

export default flatstore.connect(['loading', 'breed_data', 'breed_static', 'err_data'])(BreedsDetails);
