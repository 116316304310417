import ContentLoader from 'react-content-loader';
import React, { useState, useEffect } from 'react';

const FAQLoader = () => {
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 992);

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 992);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {isWideScreen ? (
        <ContentLoader viewBox="0 0 1420 1039" speed={2} backgroundColor="#d9d9d9" foregroundColor="#ededed"  style={{ width: '100%' }}>
          <rect x="402.5" width="189" height="28" rx="5"/>
          <rect x="402.5" y="40" width="615" height="8" rx="4"/>
          <rect x="402.5" y="60" width="249" height="8" rx="4"/>
          <rect x="214.5" y="118" width="97" height="61"/>
          <rect x="317.5" y="118" width="97" height="61"/>
          <rect x="420.5" y="118" width="97" height="61"/>
          <rect x="523.5" y="118" width="97" height="61"/>
          <rect x="626.5" y="118" width="97" height="61"/>
          <rect x="729.5" y="118" width="97" height="61"/>
          <rect x="832.5" y="118" width="97" height="61"/>
          <rect x="935.5" y="118" width="97" height="61"/>
          <rect x="1038.5" y="118" width="97" height="61"/>
          <rect x="1141.5" y="118" width="64" height="61"/>
          <rect x="20" y="253" width="302" height="12" rx="4"/>
          <path d="M778.939 264.561C779.525 265.146 780.475 265.146 781.061 264.561L790.607 255.015C791.192 254.429 791.192 253.479 790.607 252.893C790.021 252.308 789.071 252.308 788.485 252.893L780 261.379L771.515 252.893C770.929 252.308 769.979 252.308 769.393 252.893C768.808 253.479 768.808 254.429 769.393 255.015L778.939 264.561ZM778.5 262.5V263.5H781.5V262.5H778.5Z"/>
          <rect y="287" width="820" height="2"/>
          <rect y="229" width="2" height="60"/>
          <rect x="818" y="229" width="2" height="60"/>
          <rect y="229" width="820" height="2"/>
          <rect x="20" y="328" width="144" height="12" rx="4"/>
          <path d="M778.939 339.561C779.525 340.146 780.475 340.146 781.061 339.561L790.607 330.015C791.192 329.429 791.192 328.479 790.607 327.893C790.021 327.308 789.071 327.308 788.485 327.893L780 336.379L771.515 327.893C770.929 327.308 769.979 327.308 769.393 327.893C768.808 328.479 768.808 329.429 769.393 330.015L778.939 339.561ZM778.5 337.5V338.5H781.5V337.5H778.5Z"/>
          <rect y="362" width="820" height="2"/>
          <rect y="304" width="2" height="60"/>
          <rect x="818" y="304" width="2" height="60"/>
          <rect y="304" width="820" height="2"/>
          <rect x="20" y="403" width="245" height="12" rx="4"/>
          <path d="M778.939 414.561C779.525 415.146 780.475 415.146 781.061 414.561L790.607 405.015C791.192 404.429 791.192 403.479 790.607 402.893C790.021 402.308 789.071 402.308 788.485 402.893L780 411.379L771.515 402.893C770.929 402.308 769.979 402.308 769.393 402.893C768.808 403.479 768.808 404.429 769.393 405.015L778.939 414.561ZM778.5 412.5V413.5H781.5V412.5H778.5Z"/>
          <rect y="437" width="820" height="2"/>
          <rect y="379" width="2" height="60"/>
          <rect x="818" y="379" width="2" height="60"/>
          <rect y="379" width="820" height="2"/>
          <rect x="20" y="478" width="372" height="12" rx="4"/>
          <path d="M778.939 489.561C779.525 490.146 780.475 490.146 781.061 489.561L790.607 480.015C791.192 479.429 791.192 478.479 790.607 477.893C790.021 477.308 789.071 477.308 788.485 477.893L780 486.379L771.515 477.893C770.929 477.308 769.979 477.308 769.393 477.893C768.808 478.479 768.808 479.429 769.393 480.015L778.939 489.561ZM778.5 487.5V488.5H781.5V487.5H778.5Z"/>
          <rect y="512" width="820" height="2"/>
          <rect y="454" width="2" height="60"/>
          <rect x="818" y="454" width="2" height="60"/>
          <rect y="454" width="820" height="2"/>
          <rect x="20" y="553" width="230" height="12" rx="4"/>
          <path d="M778.939 564.561C779.525 565.146 780.475 565.146 781.061 564.561L790.607 555.015C791.192 554.429 791.192 553.479 790.607 552.893C790.021 552.308 789.071 552.308 788.485 552.893L780 561.379L771.515 552.893C770.929 552.308 769.979 552.308 769.393 552.893C768.808 553.479 768.808 554.429 769.393 555.015L778.939 564.561ZM778.5 562.5V563.5H781.5V562.5H778.5Z"/>
          <rect y="587" width="820" height="2"/>
          <rect y="529" width="2" height="60"/>
          <rect x="818" y="529" width="2" height="60"/>
          <rect y="529" width="820" height="2"/>
          <rect x="20" y="628" width="538" height="12" rx="4"/>
          <path d="M778.939 639.561C779.525 640.146 780.475 640.146 781.061 639.561L790.607 630.015C791.192 629.429 791.192 628.479 790.607 627.893C790.021 627.308 789.071 627.308 788.485 627.893L780 636.379L771.515 627.893C770.929 627.308 769.979 627.308 769.393 627.893C768.808 628.479 768.808 629.429 769.393 630.015L778.939 639.561ZM778.5 637.5V638.5H781.5V637.5H778.5Z"/>
          <rect y="662" width="820" height="2"/>
          <rect y="604" width="2" height="60"/>
          <rect x="818" y="604" width="2" height="60"/>
          <rect y="604" width="820" height="2"/>
          <rect x="20" y="703" width="372" height="12" rx="4"/>
          <path d="M778.939 714.561C779.525 715.146 780.475 715.146 781.061 714.561L790.607 705.015C791.192 704.429 791.192 703.479 790.607 702.893C790.021 702.308 789.071 702.308 788.485 702.893L780 711.379L771.515 702.893C770.929 702.308 769.979 702.308 769.393 702.893C768.808 703.479 768.808 704.429 769.393 705.015L778.939 714.561ZM778.5 712.5V713.5H781.5V712.5H778.5Z"/>
          <rect y="737" width="820" height="2"/>
          <rect y="679" width="2" height="60"/>
          <rect x="818" y="679" width="2" height="60"/>
          <rect y="679" width="820" height="2"/>
          <rect x="20" y="778" width="253" height="12" rx="4"/>
          <path d="M778.939 789.561C779.525 790.146 780.475 790.146 781.061 789.561L790.607 780.015C791.192 779.429 791.192 778.479 790.607 777.893C790.021 777.308 789.071 777.308 788.485 777.893L780 786.379L771.515 777.893C770.929 777.308 769.979 777.308 769.393 777.893C768.808 778.479 768.808 779.429 769.393 780.015L778.939 789.561ZM778.5 787.5V788.5H781.5V787.5H778.5Z"/>
          <rect y="812" width="820" height="2"/>
          <rect y="754" width="2" height="60"/>
          <rect x="818" y="754" width="2" height="60"/>
          <rect y="754" width="820" height="2"/>
          <rect x="20" y="853" width="507" height="12" rx="4"/>
          <path d="M778.939 864.561C779.525 865.146 780.475 865.146 781.061 864.561L790.607 855.015C791.192 854.429 791.192 853.479 790.607 852.893C790.021 852.308 789.071 852.308 788.485 852.893L780 861.379L771.515 852.893C770.929 852.308 769.979 852.308 769.393 852.893C768.808 853.479 768.808 854.429 769.393 855.015L778.939 864.561ZM778.5 862.5V863.5H781.5V862.5H778.5Z"/>
          <rect y="887" width="820" height="2"/>
          <rect y="829" width="2" height="60"/>
          <rect x="818" y="829" width="2" height="60"/>
          <rect y="829" width="820" height="2"/>
          <rect x="20" y="928" width="642" height="12" rx="4"/>
          <path d="M778.939 939.561C779.525 940.146 780.475 940.146 781.061 939.561L790.607 930.015C791.192 929.429 791.192 928.479 790.607 927.893C790.021 927.308 789.071 927.308 788.485 927.893L780 936.379L771.515 927.893C770.929 927.308 769.979 927.308 769.393 927.893C768.808 928.479 768.808 929.429 769.393 930.015L778.939 939.561ZM778.5 937.5V938.5H781.5V937.5H778.5Z"/>
          <rect y="962" width="820" height="2"/>
          <rect y="904" width="2" height="60"/>
          <rect x="818" y="904" width="2" height="60"/>
          <rect y="904" width="820" height="2"/>
          <rect x="20" y="1003" width="302" height="12" rx="4"/>
          <path d="M778.939 1014.56C779.525 1015.15 780.475 1015.15 781.061 1014.56L790.607 1005.01C791.192 1004.43 791.192 1003.48 790.607 1002.89C790.021 1002.31 789.071 1002.31 788.485 1002.89L780 1011.38L771.515 1002.89C770.929 1002.31 769.979 1002.31 769.393 1002.89C768.808 1003.48 768.808 1004.43 769.393 1005.01L778.939 1014.56ZM778.5 1012.5V1013.5H781.5V1012.5H778.5Z"/>
          <rect y="1037" width="820" height="2"/>
          <rect y="979" width="2" height="60"/>
          <rect x="818" y="979" width="2" height="60"/>
          <rect y="979" width="820" height="2"/>
          <rect x="970" y="229" width="450" height="2"/>
          <rect x="1030" y="251" width="330" height="24" rx="4"/>
          <rect x="970" y="295" width="450" height="2"/>
          <circle cx="1007" cy="345" r="17"/>
          <rect x="1040" y="317" width="140" height="12" rx="6"/>
          <rect x="1040" y="339" width="329" height="12" rx="6"/>
          <rect x="1040" y="361" width="168" height="12" rx="6"/>
          <rect x="970" y="393" width="450" height="2"/>
          <circle cx="1007" cy="432" r="17"/>
          <rect x="1040" y="426" width="168" height="12" rx="6"/>
          <rect x="970" y="469" width="450" height="2"/>
          <circle cx="1007" cy="508" r="17"/>
          <rect x="1040" y="502" width="238" height="12" rx="6"/>
          <rect x="970" y="545" width="450" height="2"/>
          <circle cx="1007" cy="584" r="17"/>
          <rect x="1040" y="578" width="121" height="12" rx="6"/>
          <rect x="970" y="621" width="450" height="2"/>
          <circle cx="1007" cy="660" r="17"/>
          <rect x="1040" y="654" width="59" height="12" rx="6"/>
          <rect x="970" y="697" width="450" height="2"/>
          <circle cx="1007" cy="736" r="17"/>
          <rect x="1040" y="730" width="87" height="12" rx="6"/>
          <rect x="970" y="773" width="450" height="2"/>
          <rect x="1418" y="229" width="2" height="544"/>
          <rect x="970" y="229" width="2" height="544"/>
        </ContentLoader>
      ) : (
        <ContentLoader viewBox="0 0 520 1031" speed={2} backgroundColor="#d9d9d9" foregroundColor="#ededed"  style={{ width: '100%' }}>
          <rect width="174" height="28" rx="5"/>
          <rect y="40" width="520" height="8" rx="4"/>
          <rect y="60" width="249" height="8" rx="4"/>
          <rect y="118" width="108" height="61"/>
          <rect x="114" y="118" width="108" height="61"/>
          <rect x="228" y="118" width="108" height="61"/>
          <rect x="342" y="118" width="108" height="61"/>
          <rect x="456" y="118" width="64" height="61"/>
          <rect x="20" y="245" width="302" height="12" rx="4"/>
          <path d="M478.939 256.561C479.525 257.146 480.475 257.146 481.061 256.561L490.607 247.015C491.192 246.429 491.192 245.479 490.607 244.893C490.021 244.308 489.071 244.308 488.485 244.893L480 253.379L471.515 244.893C470.929 244.308 469.979 244.308 469.393 244.893C468.808 245.479 468.808 246.429 469.393 247.015L478.939 256.561ZM478.5 254.5V255.5H481.5V254.5H478.5Z"/>
          <rect y="279" width="520" height="2"/>
          <rect y="221" width="2" height="60"/>
          <rect x="518" y="221" width="2" height="60"/>
          <rect y="221" width="520" height="2"/>
          <rect x="20" y="320" width="144" height="12" rx="4"/>
          <path d="M478.939 331.561C479.525 332.146 480.475 332.146 481.061 331.561L490.607 322.015C491.192 321.429 491.192 320.479 490.607 319.893C490.021 319.308 489.071 319.308 488.485 319.893L480 328.379L471.515 319.893C470.929 319.308 469.979 319.308 469.393 319.893C468.808 320.479 468.808 321.429 469.393 322.015L478.939 331.561ZM478.5 329.5V330.5H481.5V329.5H478.5Z"/>
          <rect y="354" width="520" height="2"/>
          <rect y="296" width="2" height="60"/>
          <rect x="518" y="296" width="2" height="60"/>
          <rect y="296" width="520" height="2"/>
          <rect x="20" y="395" width="245" height="12" rx="4"/>
          <path d="M478.939 406.561C479.525 407.146 480.475 407.146 481.061 406.561L490.607 397.015C491.192 396.429 491.192 395.479 490.607 394.893C490.021 394.308 489.071 394.308 488.485 394.893L480 403.379L471.515 394.893C470.929 394.308 469.979 394.308 469.393 394.893C468.808 395.479 468.808 396.429 469.393 397.015L478.939 406.561ZM478.5 404.5V405.5H481.5V404.5H478.5Z"/>
          <rect y="429" width="520" height="2"/>
          <rect y="371" width="2" height="60"/>
          <rect x="518" y="371" width="2" height="60"/>
          <rect y="371" width="520" height="2"/>
          <rect x="20" y="470" width="372" height="12" rx="4"/>
          <path d="M478.939 481.561C479.525 482.146 480.475 482.146 481.061 481.561L490.607 472.015C491.192 471.429 491.192 470.479 490.607 469.893C490.021 469.308 489.071 469.308 488.485 469.893L480 478.379L471.515 469.893C470.929 469.308 469.979 469.308 469.393 469.893C468.808 470.479 468.808 471.429 469.393 472.015L478.939 481.561ZM478.5 479.5V480.5H481.5V479.5H478.5Z"/>
          <rect y="504" width="520" height="2"/>
          <rect y="446" width="2" height="60"/>
          <rect x="518" y="446" width="2" height="60"/>
          <rect y="446" width="520" height="2"/>
          <rect x="20" y="545" width="230" height="12" rx="4"/>
          <path d="M478.939 556.561C479.525 557.146 480.475 557.146 481.061 556.561L490.607 547.015C491.192 546.429 491.192 545.479 490.607 544.893C490.021 544.308 489.071 544.308 488.485 544.893L480 553.379L471.515 544.893C470.929 544.308 469.979 544.308 469.393 544.893C468.808 545.479 468.808 546.429 469.393 547.015L478.939 556.561ZM478.5 554.5V555.5H481.5V554.5H478.5Z"/>
          <rect y="579" width="520" height="2"/>
          <rect y="521" width="2" height="60"/>
          <rect x="518" y="521" width="2" height="60"/>
          <rect y="521" width="520" height="2"/>
          <rect x="20" y="620" width="326" height="12" rx="4"/>
          <path d="M478.939 631.561C479.525 632.146 480.475 632.146 481.061 631.561L490.607 622.015C491.192 621.429 491.192 620.479 490.607 619.893C490.021 619.308 489.071 619.308 488.485 619.893L480 628.379L471.515 619.893C470.929 619.308 469.979 619.308 469.393 619.893C468.808 620.479 468.808 621.429 469.393 622.015L478.939 631.561ZM478.5 629.5V630.5H481.5V629.5H478.5Z"/>
          <rect y="654" width="520" height="2"/>
          <rect y="596" width="2" height="60"/>
          <rect x="518" y="596" width="2" height="60"/>
          <rect y="596" width="520" height="2"/>
          <rect x="20" y="695" width="372" height="12" rx="4"/>
          <path d="M478.939 706.561C479.525 707.146 480.475 707.146 481.061 706.561L490.607 697.015C491.192 696.429 491.192 695.479 490.607 694.893C490.021 694.308 489.071 694.308 488.485 694.893L480 703.379L471.515 694.893C470.929 694.308 469.979 694.308 469.393 694.893C468.808 695.479 468.808 696.429 469.393 697.015L478.939 706.561ZM478.5 704.5V705.5H481.5V704.5H478.5Z"/>
          <rect y="729" width="520" height="2"/>
          <rect y="671" width="2" height="60"/>
          <rect x="518" y="671" width="2" height="60"/>
          <rect y="671" width="520" height="2"/>
          <rect x="20" y="770" width="253" height="12" rx="4"/>
          <path d="M478.939 781.561C479.525 782.146 480.475 782.146 481.061 781.561L490.607 772.015C491.192 771.429 491.192 770.479 490.607 769.893C490.021 769.308 489.071 769.308 488.485 769.893L480 778.379L471.515 769.893C470.929 769.308 469.979 769.308 469.393 769.893C468.808 770.479 468.808 771.429 469.393 772.015L478.939 781.561ZM478.5 779.5V780.5H481.5V779.5H478.5Z"/>
          <rect y="804" width="520" height="2"/>
          <rect y="746" width="2" height="60"/>
          <rect x="518" y="746" width="2" height="60"/>
          <rect y="746" width="520" height="2"/>
          <rect x="20" y="845" width="200" height="12" rx="4"/>
          <path d="M478.939 856.561C479.525 857.146 480.475 857.146 481.061 856.561L490.607 847.015C491.192 846.429 491.192 845.479 490.607 844.893C490.021 844.308 489.071 844.308 488.485 844.893L480 853.379L471.515 844.893C470.929 844.308 469.979 844.308 469.393 844.893C468.808 845.479 468.808 846.429 469.393 847.015L478.939 856.561ZM478.5 854.5V855.5H481.5V854.5H478.5Z"/>
          <rect y="879" width="520" height="2"/>
          <rect y="821" width="2" height="60"/>
          <rect x="518" y="821" width="2" height="60"/>
          <rect y="821" width="520" height="2"/>
          <rect x="20" y="920" width="314" height="12" rx="4"/>
          <path d="M478.939 931.561C479.525 932.146 480.475 932.146 481.061 931.561L490.607 922.015C491.192 921.429 491.192 920.479 490.607 919.893C490.021 919.308 489.071 919.308 488.485 919.893L480 928.379L471.515 919.893C470.929 919.308 469.979 919.308 469.393 919.893C468.808 920.479 468.808 921.429 469.393 922.015L478.939 931.561ZM478.5 929.5V930.5H481.5V929.5H478.5Z"/>
          <rect y="954" width="520" height="2"/>
          <rect y="896" width="2" height="60"/>
          <rect x="518" y="896" width="2" height="60"/>
          <rect y="896" width="520" height="2"/>
          <rect x="20" y="995" width="302" height="12" rx="4"/>
          <path d="M478.939 1006.56C479.525 1007.15 480.475 1007.15 481.061 1006.56L490.607 997.015C491.192 996.429 491.192 995.479 490.607 994.893C490.021 994.308 489.071 994.308 488.485 994.893L480 1003.38L471.515 994.893C470.929 994.308 469.979 994.308 469.393 994.893C468.808 995.479 468.808 996.429 469.393 997.015L478.939 1006.56ZM478.5 1004.5V1005.5H481.5V1004.5H478.5Z"/>
          <rect y="1029" width="520" height="2"/>
          <rect y="971" width="2" height="60"/>
          <rect x="518" y="971" width="2" height="60"/>
          <rect y="971" width="520" height="2"/>
        </ContentLoader>
      )}
    </>
  );
};

export default FAQLoader;