import React, {useState, useEffect, Fragment} from 'react';
import { breedURL } from '../../../utils/functions';
import { useTranslation } from 'react-i18next';
import Image from '../../common/Image';
import { Link } from 'react-router-dom';

const BreedsOverview = ({items, data}) => {
    const { t } = useTranslation();

    return (
        <div className="container">
        {items &&
            <Fragment>
                {data.title &&
                    <div className="module__title">
                        <h3 className="heading heading--h3"> {data.title} </h3>
                    </div>
                }
                <div className="row justify-content-center">
                    {items.slice(0, data.total_show ?? 3).map((item, index)=>{
                        var url = breedURL(item.slug);
                        return (
                            <div key={index} className="col-xs-12 col-md-6 col-lg-4">
                                <div className="list__item">
                                    <div className="list__body">
                                        <div className="list__image">
                                            <Link className="read-more" to={url} title={item.title}>
                                                <Image src={item.image_url} type="breeds.product" alt={item.title} title={item.title} />
                                            </Link>
                                        </div>
                                        <div className="list__description">
                                            <div>
                                                <h3 className="title">
                                                    <Link className="link" to={url} title={item.title}>{item.title}</Link>
                                                </h3>
                                            </div>
                                            <Link className="read-more" to={url} title={t('breeds.readmore')}>{t('breeds.readmore')}</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </Fragment>
        }
        </div>
    )
}

export default BreedsOverview;