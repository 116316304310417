import React, {useState, useEffect, Fragment} from 'react'
import {Link, useNavigate} from 'react-router-dom';
import flatstore from 'flatstore';
import { blogURL, currentLang } from '../../../utils/functions';
import { useTranslation } from 'react-i18next';

const Pagination = ({url, data, filterType, curPage, type=null, scrollToFilter }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [pages, setPages] = useState([]);
    const [totalPages, setTotalPages] = useState(0)
    const history = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        const total = data.total;
        const perPage = data.per_page;
        setTotalPages(Math.ceil(total/perPage));

    }, [data])

    useEffect(() => {
        if(totalPages){
            setPages(new Array(totalPages).fill(0));
        }

    }, [totalPages])

    useEffect(() => {
        if(curPage){
            setCurrentPage(parseInt(curPage));
            flatstore.set('list_active_page', parseInt(curPage));
        }else{
            setCurrentPage(1);
            flatstore.set('list_active_page', 1);
        }

    }, [curPage])

    const handleClick = (pageNumber) =>{
        if(pageNumber > 0 && pageNumber != currentPage && pageNumber <= totalPages){
            flatstore.set('list_active_page', pageNumber);
            setCurrentPage(pageNumber);
            var pageURL = url;
            if(type == 'blog'){
                pageURL += "/"+(filterType ? filterType+"/" : "")
            }else{
                if(filterType){
                    pageURL += t(filterType)+"/";
                }
            }


            // Update the URL path to "/" if the user clicks on the pagination link for the first page
            if (pageNumber === 1) { // remove the trailing slash
                const url = pageURL.endsWith("/") ? pageURL.slice(0, -1) : pageURL;
                history(url);
              } else {
                history(`${pageURL}${pageNumber}`);
              }

            // Call the scrollToFilter function to scroll to the top of the filter section
            if (scrollToFilter) {
                scrollToFilter();
            }

        }
    }

    return (
        <Fragment>
            {pages && totalPages > 1 &&
            <ul className="pagination">
                <li onClick={()=>handleClick(currentPage-1)}>
                    <a className={`pagination__prev ${currentPage === 1 ? "disabled" : ""}`}>
                        <i className="icon-back"></i>
                    </a>
                </li>

                    <Fragment>
                    {pages.map((item, index)=>{
                        var i = index+1;
                        return (
                            <Fragment key={i}>
                                {type == 'blog' &&
                                    <li className={currentPage === i ? "active number" : "number"} onClick={()=>handleClick(i)}><Link to={url+(filterType ? "/"+filterType+"/" : "/")+i} onClick={(e)=>{e.preventDefault()}} className="pagination__number">{i}</Link></li>
                                }
                                {type != 'blog' &&
                                    <li className={currentPage === i ? "active number" : "number"} onClick={()=>handleClick(i)}><a href={url+(filterType ? t(filterType)+"/" : "")+i} onClick={(e)=>{e.preventDefault()}} className="pagination__number">{i}</a></li>
                                }
                            </Fragment>
                        )
                    })}
                    </Fragment>

                <li onClick={()=>handleClick(currentPage+1)}><a className={`pagination__next ${currentPage === totalPages ? "disabled" : ""}`}><i className="icon-back"></i></a></li>
            </ul>
            }
        </Fragment>
    )
}

export default Pagination
