import React, {useEffect, useState, Fragment} from 'react';
import flatstore from 'flatstore';
import {getRecipeInfo} from '../../utils/services';
import parse from 'html-react-parser';
import LoadModules from '../../components/modules/LoadModules'
import Meta from '../../components/common/Meta';
import NotFound from '../static/404';
import W from '../../components/common/Word';
import Image from '../../components/common/Image';
import {Link} from 'react-router-dom';
import RecipeDetailLoader from '../../assets/contentLoader/RecipeDetailLoader';
import SocialMediaShareButtons from '../../utils/social';  


const RecipeDetail = ({slug, loading, recipe_data, err_data}) => {
	const [data, setData] = useState(null);
	const [modules, setModules] = useState(0);
	const [hasFetched, setHasFetched] = useState(false);

	let moduleData = {"relatedSlug":slug, "relatedType": "recipe"};

	useEffect(() => {
		if (slug && !loading && !hasFetched) {
			getRecipeInfo(slug);
			setHasFetched(true);
		}
	}, [slug, loading]);

	useEffect(() => {
		// Separate effect for handling data updates.
		setData(recipe_data);

		if(recipe_data && recipe_data.modules !== null){
			setModules(recipe_data.modules);
		}

	}, [recipe_data]);

	const isExternalURL = (url) => {
		if(url.includes('http')){
		  return true;
		}else{
		  return false;
		}
	  }

	if (loading) {
		return (
			<div className="container mt-5 mb-5">
				<div className="row">
					<div className="col-12">
						<RecipeDetailLoader />
					</div>
				</div>
			</div>
		)
	} else {
		return (
			<Fragment>
			<LoadModules modules={modules} type="top" />
			{!data && err_data && err_data == '404' &&
				<NotFound />
			}
			{data &&
				<Fragment>
				<Meta
                    title={data.seo_title ?? data.title}
                    description={data.seo_description ?? data.body}
					type="recipe"
					type_data={data}
					image={data.image_url}
				/>
				<div className='module__navigator'>
					<div className='container'>
						<div className='row'>
							<div className='col'>
								<div className='desc'>
									<div className='text'>
										<h1 className="heading heading--h4">{data.title}</h1>
										{data.author &&
											<div className="author">
												<W>general.by</W> {data.author}
											</div>
										}
										<div className="body">
											{data.body && parse(data.body)}
										</div>
										<ul className="recipe__info">
											{data.prep_time &&
												<Fragment>
													<li className="fld"><W>recipes.prep_time</W></li>
													<li className="val">{data.prep_time} <W>minutes</W></li>
												</Fragment>
											}
											{data.cook_time &&
												<Fragment>
													<li className="fld"><W>recipes.cook_time</W></li>
													<li className="val">{data.cook_time} <W>minutes</W></li>
												</Fragment>
											}

											{data.portion_amount &&
												<Fragment>
													<li className="fld"><W>recipes.portion_amount</W></li>
													<li className="val">{data.portion_amount}</li>
												</Fragment>
											}
											{data.calories &&
												<Fragment>
													<li className="fld"><W>recipes.calories</W></li>
													<li className="val">{data.calories} kcal</li>
												</Fragment>
											}
										</ul>
									</div>
									<div className='pic'>
										<Image type="recipes.header" className="img" width="440" height="300" src={data.image_url} alt={data.title} title={data.title} />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				{data.instructions && data.instructions.length > 0 &&
				<div className="meatcut__baking">
					<div className='container py-5'>
						<div className="row">
							<div className='col-lg-3 col-md-4 mb-4 mb-md-0'>
								{data.ingredients && data.ingredients.length > 0 &&
									<div className='metadata-box'>
										<h2 className="heading--h6 mb-4 text--center"><W>recipes.ingredients</W></h2>
										{data.ingredients.map((item, index) => {
											var ing_title = item.url ? (isExternalURL(item.url)) ? <a href={item.url} key={"ing-link"+index}>{item.title && parse(item.title)}</a> : <Link key={"ing-link"+index} to={item.url}>{item.title && parse(item.title)}</Link> : item.title && parse(item.title);
											return <div className='recipe-ingredient-item' key={index}> <span className='amount'>{item.amount}</span> <span className='name'>{ing_title}</span> </div>
										})}
									</div>
								}
								{data.requirements && data.requirements.length > 0 &&
									<div className='metadata-box'>
										<h2 className='heading--h6 mb-4 text--center'><W>basic_recipe.items</W></h2>
										<ul className='list'>
											{data.requirements.map((item, index) => {
												return ( <li key={"basic_recipe_items_"+index}>{item.body && item.body}</li>)
											})}
										</ul>
									</div>
								}
							</div>
							<div className='col-lg-8 col-md-7 offset-md-1'>
								<h2 className="heading--h6 mb-4 text--center"> <W>recipes.stepbystep</W></h2>
								<ul className="recipe-steps">
									{data.instructions.map((item, index) => {
										var with_image = item.image !== null && item.image_url !== null;
										return (
											<li className={with_image ? 'with-image' : ''} key={"recipe_instruction_"+index}>
												{with_image &&
													<div className="image">
														<Image src={item.image_url} width="200" height="100" className="img-fluid" alt={item.body && parse(item.body).substr(0, 50)} title={item.body && parse(item.body).substr(0, 50)} />
													</div>
												}
												<div className='number'>
													<span>{index + 1}</span>
												</div>
												<div className="text">
													{item.body && parse(item.body)}
												</div>
											</li>
										)
									})}
								</ul>
							</div>
						</div>
					</div>
				</div>
			}
		</Fragment>
		}

	  {data ? 
        <SocialMediaShareButtons url={window.location.href} title={data.title} />
       : (
        <p>...</p>
      )}


		<LoadModules modules={modules} moduleData={moduleData} type="bottom" />
		</Fragment>
		)

	}


}

export default flatstore.connect(['loading', 'recipe_data', 'err_data'])(RecipeDetail);
