import React, {Fragment} from 'react';
import Image from '../../components/common/Image';
import {breedURL} from '../../utils/functions';
import {uniqueId} from 'lodash';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const BreedItem = ({data, type, animal}) => {
    const { t } = useTranslation();
    //const url = "/"+currentLang()+"/"+t("menu.breeds")+"/"+data.slug;
    const url = breedURL(data.slug);
    return (
        <div className="list__item" key={uniqueId('breedItem_')}>
            <div className="list__body">
                <div className="list__image">
                    <Link className="read-more text--primary" to={url} title={data.title}><Image type="breeds.product" src={data.image_url} alt={data.title} title={data.title} /></Link>
                </div>
                <div className="list__description">
                    <div>
                        <h3 className="title"> <Link className="link" to={url} title={data.title}>{data.title}</Link> </h3>
                        <div className="excerpt">{data.summary}</div>
                    </div>
                    <Link className="read-more" to={url} title={t("breeds.readmore")}>
                        <Fragment> 
                            {t("breeds.readmore")}
                        </Fragment>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default BreedItem
