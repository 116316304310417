import React, {useState, useEffect, Fragment, useRef} from 'react'
import {isNull, isNil, upperFirst, sortedUniq, isArray, uniq} from 'lodash';
import flatstore from 'flatstore';
import {useNavigate, useLocation} from 'react-router-dom';
import { currentLang, accentsTidy, listSearch, listFilter } from './../../../utils/functions';
import {siteInfo} from './../../../utils/config';
import { useTranslation } from 'react-i18next';
import Meta from '../../../components/common/Meta'
import W from '../Word';

const DataFilter = ({type, filterType, data, filteredData, staticData, list_filter_type, list_active_page, list_search_status}) => {
  const [activeFilter, setActiveFilter] = useState(null);
  const [pageLoad, setPageLoad] = useState(0);
  const [animals, setAnimals] = useState(null);
  const history = useNavigate();
  const { t } = useTranslation();
  const searchInputRef = useRef(null);
  const [toggled, setToggled] = useState(false);
  const [totalAnimalsClass, setTotalAnimalsClass] = useState(null);
  const animalsFilter = siteInfo[currentLang()]['animal_types'];
  const {pathname} = useLocation();
  let toggledClass = (toggled) ? "open" : "";
  const [activeFilteredData, setActiveFilteredData] = useState(null);
  const [currentStaticData, setCurrentStaticData] = useState(null);

  useEffect(() => {
      setAnimals(checkFilter());
      if(filteredData){
        setActiveFilteredData(filteredData);
      }
      if(staticData){
        setCurrentStaticData(staticData);
      }
  }, [data,filteredData,staticData]);

  const selectCategory = value =>{
    if(!(activeFilter == value)){
      if(type == 'faq' && isNil(value)){
        flatstore.set('list_filter_type', "general");
      }else{
        flatstore.set('list_filter_type', value);
      }
      flatstore.set('list_active_page', 1);
      var url = "/"+currentLang()+"/";
      if (!isNil(value)) {
        setActiveFilter(value);

        url += t("menu."+type)+"/";
        history(url+t(value));

      } else {
          setActiveFilter(null);
          if(['faq', 'recipes', 'farmers', 'breeds'].includes(type)){
              url += t('menu.'+type);
              history(url);
          }
      }
    }
  }

  const search = str => {
    if(str.length < 1){
      flatstore.set('list_search_status', 'only3char');
      flatstore.set('list_filtered_data', []);
    }else if(str.length < 2){
      flatstore.set('list_search_status', '3char');
      flatstore.set('list_filtered_data', []);
    }else{
      flatstore.set('list_search_status', 'search_it');
      listSearch(type, accentsTidy(str))
    }



  }

  function searchClose(event) {
    event.preventDefault();
    if(toggled){
      document.querySelector('#dataFilterSearch').value = '';
      flatstore.set('list_filter_type', activeFilter);
      flatstore.set('list_search_status', 'search_close');
      listFilter(type);
    }else{
      flatstore.set('list_search_status', 'search_empty');
      flatstore.set('list_filtered_data', []);
      searchInputRef.current.focus();
    }
    setToggled(!toggled)

  }

  const checkFilter = () =>{
      if(data){
        var existAnimals = [];
        data.map(item=>{
            if(type == 'faq')
                item.animal_types = item.animal_type;
            if(type == 'breeds')
                item.animal_types = item.animal_type;

            if(!isNil(item.animal_types)){
              if(isArray(item.animal_types)){
                existAnimals = existAnimals.concat(item.animal_types);
              }else{
                existAnimals.push(item.animal_types);
              }
            }
        });
        existAnimals = uniq(existAnimals);
        var dataFilterCustomClass;
        if(existAnimals.length <= 4){
          dataFilterCustomClass = ' item4';
        }else if(existAnimals.length <= 8){
          dataFilterCustomClass = ' item8';
        }else if(existAnimals.length <= 10){
          dataFilterCustomClass = ' item10';
        }else if(existAnimals.length > 10){
          dataFilterCustomClass = ' scroll';
        }

        setTotalAnimalsClass(dataFilterCustomClass);

        return existAnimals;
      }
  }

  useEffect(() => {
      setActiveFilter(filterType);
  }, [filterType])

  useEffect(() => {
    if(isNil(list_filter_type) || (list_filter_type.length < 2)){
      setActiveFilter(null);
    }
  }, [list_filter_type, pathname])

  useEffect(() => {
    if(!isNil(activeFilter)){
      if(window.innerWidth <= 768){
        var activeItem = document.querySelector('.data-filter__item.filter__item.active');
        var header__top = document.querySelector('.header__top');
        var header_height = header__top ? header__top.offsetHeight+27 : 0;
        if(!isNil(activeItem)){
          var navbar = activeItem.parentElement.parentElement
          window.scrollTo({
            top:  navbar.getBoundingClientRect().top + window.pageYOffset - header_height,
            behavior: 'smooth'
          });
          navbar.scrollTo({
            top:  0,
            left: activeItem.offsetLeft - ( navbar.offsetWidth - activeItem.offsetWidth) / 2,
            behavior: 'smooth'
          });
        }
      }
    }
    if(activeFilter == 'general'){
      setActiveFilter(null);
    }
  }, [activeFilter])

  if(animalsFilter.length >= 2 && animals !== null && typeof animals !== 'undefined'){
    if (Object.keys(animals).length >= 2){
      return (
        <div className="row">
          <div className="col-12">
            <div className={`data-filter search ${toggledClass}`+(totalAnimalsClass && totalAnimalsClass)}>
              <div className={"nav_holder"}>
              <nav>
                  {animals &&
                  <Fragment>
                  <ul className="data-filter__list">
                      <li className={`data-filter__item filter__item-text ${isNull(activeFilter) ? "active" : ""}`} onClick={()=>{selectCategory(null)}}>
                        <W>{t("datafilter.all"+type)}</W>
                      </li>
                      {animalsFilter.map((animal, index)=>
                        animals.includes(animal) &&
                        <Fragment key={index}>
                          <li className={`data-filter__item filter__item ${(activeFilter === animal) ? "active" : ""}`} onClick={()=>{selectCategory(animal)}}>
                            <i className={'icon-cat-'+ animal}></i> {t("datafilter."+animal)}
                          </li>
                          {activeFilter === animal && currentStaticData && activeFilteredData?.length > 0 &&
                            <Meta
                              title = {activeFilteredData[0].animal_seo_title ?? currentStaticData.seo_title}
                              description= {activeFilteredData[0].animal_seo_description ?? currentStaticData.seo_description}
                              type="animal"
                            />}
                        </Fragment>
                      )}
                  </ul>
                  </Fragment>
                  }
                </nav>
              </div>
              <div className="data-filter__search">
                <div className="action" onClick={searchClose}>
                  <i className="icon-magnify"></i>
                </div>
                <div className="form">
                  <input type="text" id="dataFilterSearch" ref={searchInputRef} placeholder={t("search.placeholder")} onChange={(e)=>{search(e.target.value)}} />
                </div>
              </div>
            </div>
          </div>
        </div>

      )
    } else {
      return (
        <Fragment>
        </Fragment>
      )
    }
  } else {
    return (
      <Fragment>
      </Fragment>
    )
  }
}

export default flatstore.connect(['list_filter_type', 'list_active_page', 'list_search_status'])(DataFilter);