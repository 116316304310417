import React, { Fragment } from 'react';
import BlogItem from './BlogItem';

function RelatedBlogs({ data }) {

  // Check if related_blogs is available and sort by publish_datetime descendingly
  const relatedBlogs = data.related_blogs || [];
  relatedBlogs.sort((a, b) => new Date(b.publish_datetime) - new Date(a.publish_datetime));
  const items = relatedBlogs.slice(0, 3);

  return (
    <Fragment>
      {items.map((item, index) => (
        <div className="col-md-6 col-lg-4" key={index}>
          <BlogItem data={item} catInfo={data.categories} />
        </div>
      ))}
    </Fragment>
  );
}

export default RelatedBlogs;
