import React, {useState, useEffect, Fragment} from 'react';
import flatstore from 'flatstore';
import { currentLang } from '../../../utils/functions';
import {getRelatedProducts} from '../../../utils/services';
import ProductCard from '../ProductCard';
import {isNil, isArray} from 'lodash';
import W from '../../common/Word';
import parse from 'html-react-parser';

function RelatedProducts({relatedType, relatedSlug, related_products_data, data}) {
    const [items, setItems] = useState(null);
    useEffect(() => {
        related_products_data = null;
    }, [])

    useEffect(() => {
        if(isNil(related_products_data) && isNil(items)){
            getRelatedProducts(relatedType, relatedSlug);
        }
        if(related_products_data && related_products_data.length > 0 && isNil(items)){
            setItems(related_products_data);
        }
    }, [related_products_data])

    return (
        <Fragment>
            {items && isArray(items) && items.length > 0 &&
            <section className="module__related-products">
                {data.title &&
                    <div className="module__related-products--title">
                        <h2 className="heading heading--h3 px-4"> {data.title} </h2>
                        {data.body && <p className='m-0'>{parse(data.body)}</p>}
                    </div>
                }
                <div className="related__item">
                    <div className="container">
                        <div className="row justify-content-center">
                            {items.sort((a, b) => {
                                return a.shop_product_stock_status != "instock" ? 1 : -1;
                            }).slice(0, data.total_show ?? 3).map((item, index)=>{
                                return <div key={"product_card_"+index} className="col-sm-6 col-md-6 col-xl-3"><ProductCard key={index} data={item} /></div>
                            })}
                        </div>
                    </div>
                </div>
                {data.button_text &&
                <div className="mt-3 text--center see-all">
                    <a href={data.button_link ?? '/'+currentLang()} className="btn btn--border btn--red">
                        {data.button_text}
                    </a>
                </div>
                }
            </section>
            }
        </Fragment>
    )
}

export default flatstore.connect(['related_products_data'])(RelatedProducts);
