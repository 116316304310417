import React, {useState, useEffect, Fragment} from 'react'
import flatstore from 'flatstore';
import { useLocation, useNavigate } from 'react-router-dom';
import Loader from '../../../components/common/Loader';
import LoadModules from '../../../components/modules/LoadModules'
import {getStaticSync} from '../../../utils/services';
import Meta from '../../../components/common/Meta';
import {replace, isNil} from 'lodash';
import { currentLang } from '../../../utils/functions';
import NotFound from '../404';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

function Statics({static_data, loading, err_data}) {
    const [data, setData] = useState(null);
    const [modules, setModules] = useState(null);
    const { t } = useTranslation();
    const history = useNavigate();
    let {pathname} = useLocation();
    let urlpaths = replace(pathname, '/'+currentLang()+'/', '').split('/');
    let is404 = false;
    
    let slug = urlpaths[0];
    if(urlpaths[1]){
        if(urlpaths[1] == t("menu.animal") && urlpaths[2]){
            var filter = urlpaths[2];
        }else{
            is404 = true;
        }
    }
    
    useEffect(() => {
        if((static_data && slug != static_data.slug ) || static_data && history.action == "POP"){
            static_data = null;
            setData(null);
        }
    }, [pathname])
    
    useEffect(() => {
		if (!is404 && isNil(static_data)) {
            getStaticSync(slug, 'static_data');
        }
        if(static_data && isNil(data)){
            setData(static_data);
        }
        
		if(static_data && static_data.modules !== null){
            setModules(static_data.modules);
        }
        
    }, [static_data, pathname]);
    
    if (loading) {
		return <Loader />
	} else {
        return (
            <Fragment>
                {is404 && <NotFound />}
                {modules &&
                    <LoadModules modules={modules} filter={filter} type="top" />
                }
                {err_data && err_data == '404' &&
                    <NotFound />
                }
                {data &&
                    <Meta
                        title={data.seo_title ? data.seo_title : data.title}
                        description={data.seo_description ? data.seo_description : data.body}
                    />
                 }
                {data && data.body &&
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-xxl-6 col-lg-8 my-5">
                                <h1 className="heading--h1">{data.title}</h1>
                                <div className="page__body">{parse(data.body)}</div>
                            </div>
                        </div>
                    </div>
                }
                {modules &&
                    <LoadModules modules={modules} filter={filter} type="bottom" />
                }
          
            </Fragment>
        )
    }
}

export default flatstore.connect(['loading', 'static_data', 'err_data'])(Statics);
