import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

const AnimalTag = ({country, amount: number, currentAnimal}) => {
    const { t } = useTranslation();

    return (
        <div className="animal-tag">
            <div className="animal-tag__body">
                <div className="country">{country}</div>
                <div className="desc">{t("animal_tag.desc." + currentAnimal)}</div>
                <div className="amount">{number}</div>
            </div>
        </div>
    )
}

export default AnimalTag
