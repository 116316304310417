import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { farmshopProductURL, currentLang } from '../../utils/functions';
import { useTranslation } from 'react-i18next';

const FarmshopDetail = () => {
  const { t } = useTranslation();
  const iframeUrl = currentLang() === 'de' ? 'https://www.videoask.com/fuz06w6y7' : 'https://www.videoask.com/fk4a5hl2f';
  // set the iframe URL based on the current language

  return (
    <Fragment>
      <div className="farmshop__full">
        <div className="container all-pack">
          <div className="row">
            <div className="col text-center">
              <Link
                to={farmshopProductURL("")}
                title={t("farmshop.list_title")}
              >
                <i className="icon-arrow-left"></i> {t("farmshop.list_title")}
              </Link>
            </div>
          </div>
        </div>
        <div className="row justify-content-center pb-6">
          <div className="col">
            <div className="feedback-box">
              <iframe
                id="feedback_iframe"
                src={iframeUrl}
                allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
                width="100%"
                height="500px"
                ></iframe>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default FarmshopDetail;
