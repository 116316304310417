import React from 'react';

const SocialMediaShareButtons = ({ url, title }) => {
  const encodedUrl = encodeURIComponent(url);
  const encodedTitle = encodeURIComponent(title);

  return (
    
    <div className='blog__share'>
      <a href={"https://www.facebook.com/sharer/sharer.php?u=" + encodedUrl} target="_blank">
        <i className="icon-facebook"></i>
      </a>
      <a href={`https://twitter.com/intent/tweet?url=${encodedUrl}&text=${encodedTitle}`} target="_blank" data-show-count='false'>
        <i className="icon-twitter"></i>
      </a>
      <a href={"https://pinterest.com/pin/create/link/?url=" + encodedUrl + "&amp;description=" + encodedTitle} target="_blank">
        <i className="icon-pinterest"></i>
      </a>
      <a href={`https://wa.me/?text=${encodeURIComponent(title + " recipe: " + url)}`} target="_blank">
        <i className="icon-whatsapp"></i>
      </a>
      <a href={`mailto:?subject=${title} recipe&body=${encodeURIComponent(title + " recipe: " + url)}`} target="_blank">
        <i className="icon-email"></i>
      </a>
    </div>
  );
};

export default SocialMediaShareButtons;
