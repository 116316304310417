/*
  Main entry point of the application. This script initializes the React application
  and renders the main App component into the DOM.
*/

// Core Library Imports
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

// Component Imports
import App from './init/App';

// Utility Imports
import './init/i18n';

// Style Imports
import './assets/scss/styles.scss';

// Create a root container where the app will be mounted
const container = document.getElementById('root');
const root = createRoot(container);

// Wrap the application inside the HelmetProvider for managing changes to the
// document head and BrowserRouter for routing capabilities
root.render(
  <HelmetProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </HelmetProvider>
);