// CuratedSubscription.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { localStorageUtil, getSubscriptionPaths, currentLang } from '../../../utils/functions';
import W from '../../../components/common/Word';
import { useTranslation } from 'react-i18next';
import SubscriptionCuratedLoader from '../../../assets/contentLoader/SubscriptionCuratedLoader';
import flatstore from 'flatstore';
import {
  getSubscriptionProductList
} from "../../../utils/services";
import {isNil} from 'lodash';

function CuratedSubscription({subscription}) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const paths = getSubscriptionPaths();
    const [loading, setLoading] = useState(false); // State to track loading state

    const size = localStorageUtil.getItem('subscription')?.size || '';
    const period = localStorageUtil.getItem('subscription')?.period || '';
    const type = localStorageUtil.getItem('subscription')?.type || 'curated';

    const [submissionError, setSubmissionError] = useState('');
    const [selectedOption, setSelectedOption] = useState(localStorageUtil.getItem('subscription')?.curated || '');

    const [data, setData] = useState([]);

    useEffect(() => {
        if( ! type ){
            navigate(paths.base || '/');
        } else if( ! size || ! period ){
            navigate(paths.steps['size'] || '/');
        }
    }, []);


    useEffect(() => {
        if (isNil(subscription)) {
            getSubscriptionProductList();
        }
        if (subscription && !isNil(subscription)) {
            setData(subscription);
        }
    }, [subscription]);

    // hide submissionError after certain time
    useEffect(() => {
        let timeout;
        if (submissionError) {
            timeout = setTimeout(() => {
                setSubmissionError('');
            }, 3000);
        }
        return () => clearTimeout(timeout);
    }, [submissionError, setSubmissionError]);


    const handleSubmit = () => {
      if (selectedOption) {
        // set curated name
        localStorageUtil.updateObjectItem('subscription', { curated: selectedOption });

        const selectedItems = {};

        const curatedItems = data.filter(item => {
            return item.subscription_type === `grutto_subscription_curated_${selectedOption}_${size}`;
        });
        curatedItems.forEach(item => {
            selectedItems[item.bundled_item_id] = {
                product_id: item.meatcut_product_id,
                quantity: item.default_quantity
            };
        });

        if( Object.keys(selectedItems).length === 0 ){
            setSubmissionError(`Wrong bundle config!`);
            return;
        }

        // If not loading, proceed with the AJAX request
        if (!loading) {
            setLoading(true); // Set loading state to true
            flatstore.set('basket_status', true);

            const formData = new FormData();
            formData.append('type', type);
            formData.append('size', size);
            formData.append('period', period);
            formData.append('curated', selectedOption);
            formData.append('items', JSON.stringify(selectedItems));

            fetch(`/${currentLang()}/shop/ajaxflow/add-subscription-to-cart`, {
                method: 'POST',
                body: formData
            })
            .then(response => response.json())
            .then(data => {
                if (data.success && data.data.cart_data) {
                    flatstore.set('basket_count', data.data.cart_data);
                    flatstore.set('basket_status', false);
                    localStorageUtil.updateObjectItem('subscription', { items: {} });
                    window.location.href = "/" + currentLang() + "/shop/";
                } else {
                    setLoading(false);
                    setSubmissionError( data.data.message );
                    flatstore.set('basket_status', false);
                }
            })
            .catch(error => {
                setLoading(false);
                setSubmissionError( 'Error adding bundle to cart' );
                console.error('Error adding bundle to cart:', error);
            });
        }
      } else {
        setSubmissionError(`Please select an option to proceed.`);
      }
    };

    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
    };


    // Update progress based on selections
    let progressBarWidthPercentage = 50;
    if (selectedOption) {
        progressBarWidthPercentage = 100;
    }

    // ProgressBar
    const progressBarWidth = {
        width: `${progressBarWidthPercentage}%`,
    };

    // if data is not loaded yet, return null
    if (!data.length) {
        return (
        <div className='subscription-box'>
            <div className="container pt-5 pb-5">
              <div className="row justify-content-center">
                <div className="col-md-10">
                    <SubscriptionCuratedLoader />
                </div>
              </div>
            </div>
        </div>
        );
    }

    return (
        <div className="subscription-box">

            <div className='progress-bar'>
                <div className='fill' style={progressBarWidth}></div>
            </div>

            {submissionError && (
                <div className="response error">
                    {submissionError}
                </div>
            )}

            <div className='container'>
            <div className='row justify-content-center'>
            <div className='col-md-10'>


            <div className="container py-5">
                <div className="row">
                    <div className="col primary-title">
                        <h2><W>subscription.curated.page.title</W></h2>
                        <p><W>subscription.curated.page.subtitle</W></p>
                    </div>
                </div>

                <div className="row justify-content-center">
                {['cow', 'cow_chicken', 'cow_chicken_pig'].map((option, index) => (
                    <div className="col-md-4" key={index}>
                    <label className="subscription__select">
                        <input
                        type="radio"
                        name="option"
                        value={option}
                        checked={selectedOption === option}
                        onChange={handleOptionChange}
                        />
                        <div className="checkbox">
                        <span></span>
                        </div>
                        <div className="cat-img">
                        <span className={`icon-cat-${option}`}></span>
                        </div>
                        <div className="body">
                        <div className="title">
                            <h3 className="heading"><W>subscription.{option}</W></h3>
                        </div>
                        </div>
                    </label>
                    </div>
                ))}
                </div>
            </div>

            </div>
            </div>
            </div>

            <div className="subscription__cta loading">
                {loading ?
                    <button className="btn btn--loading"> <span className='ico'></span> </button>
                    :
                    <button className="btn btn--red" onClick={handleSubmit}> <W>subscription.continue_delivery</W> </button>
                }
            </div>

        </div>
    );
}

export default flatstore.connect(['subscription'])(CuratedSubscription);