/**
 * Call services
 *
 *
 */

import {get, getR, getWithoutError, getSync, apiURL, currentLang} from './functions';
import axios from 'axios';
import flatstore from 'flatstore';

/**
 * Get Menu
 *
 */
export const getMenu = (slug, data_name) => {
    let url = apiURL()+"/menu/"+slug+"-"+currentLang();
    getR(url, data_name);
}

/**
 * Get FAQ data
 *
 */
export const getFAQ = () => {
    let url = apiURL()+"/faq";
    get(url, 'faq_data');
}

/**
 * Get Farmers List
 *
 */
export const getFarmers = (page=1) => {
    let url = apiURL()+"/farmers"; //?page="+page;
    get(url, 'farmers_data');
}

/**
 * Get Farmer Full
 *
 */
export const getFarmerInfo = (slug) => {
    let url = apiURL()+"/farmers/"+slug;
    getSync(url, 'farmer_data');
}

/**
 * Get Breeds List
 *
 */
export const getBreeds = (page=1) => {
    let url = apiURL()+"/breeds"; //?page="+page;
    get(url, 'breeds_data');
}

/**
 * Get Breed Full
 *
 */
export const getBreedInfo = (slug) => {
    let url = apiURL()+"/breeds/"+slug;
    getSync(url, 'breed_data');
}

/**
 * Get Recipes List
 *
 */
export const getRecipes = (page=1) => {
    let url = apiURL()+"/recipes";
    get(url, 'recipes_data');
}


/**
 * Get Recipe Full
 *
 */
export const getRecipeInfo = (slug) => {
    let url = apiURL()+"/recipes/"+slug;
    getSync(url, 'recipe_data');
}


/**
 * Get Product List
 *
 */
export const getProducts = () => {
    let url = apiURL()+"/products";
    getR(url, 'products_data');
}

/**
 * Get Product Info
 *
 */
export const getProductInfo = (slug) => {
    let url = apiURL()+"/products/"+slug;
    get(url, 'product_data');
}

/**
 * Get Product Info Sync
 *
 */
export const getProductInfoSync = (slug) => {
    let url = apiURL()+"/products/"+slug;
    getR(url, 'product_data');
}

/**
 * with loading
 */
export const getStatic = (slug, data_name) => {
    let url = apiURL()+"/statics/"+slug;
    get(url, data_name);
}

/**
 * with loading sync
 */
export const getStaticSync = (slug, data_name) => {
    let url = apiURL()+"/statics/"+slug;
    getSync(url, data_name);
}

/**
 * with loading sync
 */
export const getMeatcutSync = (slug) => {
    let url = apiURL()+"/meatcuts/"+slug;
    getSync(url, 'meatcut_data');
}

/**
 * without loading
 */
export const getStaticPage = (slug, data_name) => {
    let url = apiURL()+"/statics/"+slug;
    getR(url, data_name);
}

/**
 * Basket Count
 */
export const getBasketCount = () => {
    var href = window.location.href;
    var base_url = process.env.REACT_APP_SHOP_URL;
    if(href.includes('www.kaufnekuh.de')){
        base_url = "https://www.kaufnekuh.de";
    }else if(href.includes('local')){
        base_url = 'http://grutto.local';
    }else if(href.includes('alpha')){
        base_url = 'https://alpha.grutto.com';
    }else if(href.includes('staging')){
        base_url = 'https://staging.grutto.com';
        if(href.includes('kaufnekuh.de')){
            base_url = 'https://staging.kaufnekuh.de';
        }
    }
    let url = base_url+"/"+currentLang()+"/shop/grutto-cart-data.php";
    axios.get(url, {}).then(function(response){
        flatstore.set('basket_count', response.data);
        flatstore.set('basket_status', false);
    });
}

/**
 * Get settings
 */
export const getSettings = (slug) => {
    let url = apiURL()+"/settings/"+slug;
    getR(url, 'settings_data');
}

export const getRelatedProducts = (type, slug) => {
    let url = apiURL()+"/products/"+type+"/"+slug;
    getWithoutError(url, 'related_products_data');
}

export const getRelatedRecipes = (type, slug) => {
    let url = apiURL()+"/recipes/"+type+"/"+slug;
    getWithoutError(url, 'related_recipes_data');
}

export const getMeatcuts = (animalId) => {
    let url = apiURL()+"/meatcuts/animal/"+animalId;
    getWithoutError(url, 'meatcuts_data');
}

export const getCoupons = () => {
    let url = apiURL()+"/coupons";
    get(url, 'coupons_data');
}

export const getFarmshopProductList = (data_name=null) => {
    if(!data_name){
        data_name = 'farmshop_data';
    }
    let url = apiURL()+"/farmshop-products";
    getR(url, data_name);
}

export const getFarmshopBundleList = () => {
    let url = apiURL()+"/farmshop-bundles";
    get(url, 'farmshop_bundle_data');
}

export const getFarmshopProductFull = (slug) => {
    let url = apiURL()+"/farmshop-products/"+slug;
    get(url, 'farmshop_product_info');
}

export const getWooCommerceCart = () => {
    let url = "/"+currentLang()+"/shop/wp-json/wc/store/cart";
    get(url, 'woocommerce_cart');
}

export const getBlogList = () => {
    let url = apiURL()+"/blogs";
    get(url, 'blog_data');
}

export const getBlogPost = (slug) => {
    let url = apiURL()+"/blogs/"+slug;
    get(url, 'blog_post_data');
}

export const getCategories = () => {
    let url = apiURL()+"/blogs/categories";
    get(url, 'list_categories');
}

export const getSubscriptionProductList = () => {
    let url = apiURL()+"/subscription-products";
    getR(url, 'subscription');
}