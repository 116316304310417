import React, {useState, useEffect, Fragment} from 'react';
import LoadModule from './LoadModule';
import {isNil} from 'lodash';

function LoadModules(props){
    const [topModules, setTopModules] = useState(null)
    const [bottomModules, setBottomModules] = useState(null)
    var {modules, product, filter, type, moduleData} = props;
    useEffect(() => {
    
        if(modules){
            var top = [];
            var bottom = [];
            if(!isNil(modules.top)){
                modules.top.map(item=>{
                    top.push(item);
                })    
            }
            if(!isNil(modules.bottom)){
                modules.bottom.map(item=>{
                    bottom.push(item);
                })    
            }
            if(top.length > 0){
                setTopModules(top);
            }else{
                setTopModules(null);
            }
            
            if(bottom.length > 0){
                setBottomModules(bottom);
            }else{
                setBottomModules(null);
            }
        }
    }, [modules, moduleData, product])
    
    return (
        <Fragment>
            {type == 'top' &&
                <Fragment>
                {topModules && topModules.map((item, index) => {
                    return <LoadModule key={"topmodules_"+index} data={item} product={product} filter={filter} moduleData={moduleData} moduleId={item.top_sub_module_id} />
                })}
                </Fragment>
            }
            {type == 'bottom' &&
                <Fragment>
                {bottomModules && bottomModules.map((item, index) => {
                    return <LoadModule key={"botmodules_"+index} data={item} product={product} filter={filter} moduleData={moduleData} moduleId={item.bottom_sub_module_id} />
                })}
                </Fragment>
            }
        </Fragment>
    )
    
}

export default LoadModules