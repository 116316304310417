import ProductDetail from './ProductDetail';
import Statics from '../static/Statics';
import {currentLang} from '../../utils/functions';
import {replace} from 'lodash';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';


const Products = () => {
	let {slug} = useParams();
	const { t } = useTranslation();
	let location = useLocation();
	let urlpaths = replace(location.pathname, '/'+currentLang()+'/', '').split('/');

    if(urlpaths[1] && (urlpaths[1] == t("menu.animal")) && urlpaths[2]){
		slug = false;
    }

	if(slug){
		return (
			<ProductDetail slug={slug} />
		)
	}else{
		return (
			<Statics />
		)
	}
}

export default Products
