import React, {useState, useEffect, Fragment} from 'react'

export default function Button({title, type, btnclass, url, disable}) {
   
    return (
        <Fragment>
            <Fragment>
            {url && type == 'addtocart' &&
                <Fragment>
                {disable &&
                    <a className="btn btn--disable btn--full">{title}</a>
                }
                {!disable &&
                    <a href={url} className="btn btn--cta btn--full version-a-checkout">{title}</a>
                }
                </Fragment>
            }
            </Fragment>
            <Fragment>
            {type == 'normal' &&
                <button
                    className={btnclass}
                    disabled={disable}
                >
                    {title}
                </button>
            }
            </Fragment>
        </Fragment>
    )
}
