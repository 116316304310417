import React, { useEffect, useState, Fragment, useRef } from 'react';
import flatstore from 'flatstore';
import {isNil, upperFirst} from 'lodash';
import {
  getFarmshopProductList,
  getFarmshopBundleList,
  getWooCommerceCart,
  getStaticPage,
} from "../../utils/services";
import FarmshopProductBox from './components/product';
import {
  currentLang,
  farmshopProductURL,
  dynamic_delivery_promise,
  accentsTidy,
} from "../../utils/functions";
import FarmshopOverview from './components/FarmshopOverview';
import BrandBanner from './components/brandBanner';
import {useTranslation} from 'react-i18next';
import { Link } from 'react-router-dom';
import W from '../../components/common/Word'
import Meta from '../../components/common/Meta';
import Feedback_user from '../../assets/img/farmshop/customer_care.svg';
import Feedback_cover from '../../assets/img/farmshop/feedback_image.svg';
import parse from 'html-react-parser';
import FarmshopListLoader from '../../assets/contentLoader/FarmshopListLoader';
import FarmshopBundles from './components/FarmshopBundles';
import LoadModules from '../../components/modules/LoadModules';

function FarmshopList({farmshop_data, farmshop_bundle_data, woocommerce_cart, farmshop_static_data}) {
  const [data, setData] = useState(null);
  const [bundleData, setBundleData] = useState(null);
  const [modules, setModules] = useState(null);
  const [cart, setCart] = useState(null);
  const [totalItems, setTotalItems] = useState(0);
  const [bundleId, setBundleId] = useState(null);
  const [submissionNotice, setSubmissionNotice] = useState({ message: '', className: '' });
  const [activeAnimal, setActiveAnimal] = useState('');
  const [scrollY, setScrollY] = useState(0);
  const isMobile = (window.innerWidth < 992) ? true : false;
  const {t} = useTranslation();

  //Defining Search states and variables and Refrences
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [toggled, setToggled] = useState(false);
  let toggledClass = (toggled) ? "open" : "";
  const searchInputRef = useRef(null);
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState('');

  const noResults = (
    <div className="search-state error">
      <div className="icon">
        <i className="icon-warning"></i>
      </div>
      <h4>{t("search.notfound")}</h4>
    </div>
  );

  const animal_types = {
    bundles: 0,
    easter: 1,
    bioCow: 2,
    cow: 3,
    chicken: 4,
    pig: 5,
    sheep: 6,
    duck: 7,
    fish: 8,
    deer: 9,
  };
  const animal_stocks = { instock: 1, coming_soon: 2, outofstock: 3 };
  let i = 0;
  var brand_banner = 0;
  var slug_id = 0;
  var prev_animal_type = 'bundles';

  const animalTypeCounters = {
    "cow": 0,
    "chicken": 0,
    "pig": 0,
    "deer": 0,
    "duck": 0,
    "fish": 0,
    "sheep": 0,
    "bioCow":0,
    "easter":0,
    "bundles":0,
  };


  // hide submissionNotice after certain time
  useEffect(() => {
      let timeout;
      if (submissionNotice) {
          timeout = setTimeout(() => {
              setSubmissionNotice({ message: '', className: '' });
          }, 3000);
      }
      return () => clearTimeout(timeout);
  }, [submissionNotice, setSubmissionNotice]);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const searchQueryFromURL = urlSearchParams.get('search');

    if (data && searchQueryFromURL) {
      setToggled(!toggled);
      handleSearch(searchQueryFromURL);
      // Set the search input value based on the URL parameter
      setSearchInputValue(searchQueryFromURL);
    }
  }, [data]);
  const hasInitialScroll = useRef(false);

  useEffect(() => {
    const hash = window.location.hash;
    if (data && hash && !hasInitialScroll.current) {
      const animalType = t(hash.replace('#', ''));
      const timeoutId = setTimeout(() => {
        scrollTo(animalType);
        hasInitialScroll.current = true;
      }, 500);

      return () => clearTimeout(timeoutId);
    }
  }, [data, scrollTo]);

  const updateURL = (query) => {
    const newURL = `${window.location.pathname}?search=${query}`;
    window.history.pushState({ path: newURL }, '', newURL);
  };

  const handleSearch = (query) => {
    // Update the state with the search query entered by the user
    setSearchQuery(query);
    setSearchPerformed(true);

    // Remove diacritics from the search query
    const queryWithoutDiacritics = accentsTidy(query);

    // Filter the data based on the search query
    const filteredData = data.filter((item) => {
      // Remove diacritics from the item title
      const titleWithoutDiacritics = accentsTidy(item.title);
      const itemSummary = item.summary || '';

      // Compare the search query, item title, and item summary without diacritics
      return (
        titleWithoutDiacritics.toLowerCase().includes(queryWithoutDiacritics.toLowerCase()) ||
        itemSummary.toLowerCase().includes(queryWithoutDiacritics.toLowerCase())
      );
    });

    // Update the state with the filtered data
    setFilteredData(filteredData);

    // Update the URL with the search query
    updateURL(query);

  };


  // Handle input text box clearing and focusing
  useEffect(() => {
    const searchInput = document.querySelector("#dataFilterSearch");

    if (toggled && searchInput) {
      searchInput.value = ""; // Clear the input text box
      searchInput.focus(); // Focus on the input text box
    }
  }, [toggled]);

  // Handle resetting the search state
  useEffect(() => {
    if (!toggled) {
      setSearchPerformed(false); // Set searchPerformed to false
      setFilteredData([]); // Clear the filtered data
    }
  }, [toggled]);

  function searchClose(event) {
    event.preventDefault();
    setToggled(!toggled);
    const urlWithoutSearch = window.location.pathname;
    window.history.pushState({ path: urlWithoutSearch }, '', urlWithoutSearch);
  }

  useEffect(() => {
    if (isNil(farmshop_data)) {
      getFarmshopProductList();
      getStaticPage("farmshop", "farmshop_static_data");
    }
    if(isNil(farmshop_bundle_data)){
      getFarmshopBundleList();
    }
    if (farmshop_data && !isNil(farmshop_data)) {
      const animalTypeMap = {
        boar: "deer",
        roe: "deer",
        salmon: "fish",
        codfish: "fish"
      };

      const modifiedData = farmshop_data.map((item) => ({
        ...item,
        animal_type:
          item.seasonal_highlight === "easter" ? "easter" :
          animalTypeMap[item.animal_type] || item.animal_type, // Map animal_type if found, else keep original
      }));

      // Sort modifiedData based on animal_type
      modifiedData.sort((a, b) =>
        a.animal_type === b.animal_type
          ? 0
          : animal_types[a.animal_type] > animal_types[b.animal_type]
          ? 1
          : -1
      );

      modifiedData.sort((a, b) =>
        a.animal_type === b.animal_type
          ? a.stock_status === b.stock_status
            ? 0
            : animal_stocks[a.stock_status] > animal_stocks[b.stock_status]
            ? 1
            : -1
          : 0
      );


      // Update the state with modifiedData
      setData(modifiedData);
      setBundleId(modifiedData[0].bundle_id);
    }
    if (farmshop_bundle_data && !isNil(farmshop_bundle_data)) {
      setBundleData(farmshop_bundle_data);
    }
    if (farmshop_static_data && farmshop_static_data.modules !== null) {
      setModules(farmshop_static_data.modules);
    }
  }, [farmshop_data, farmshop_bundle_data]);

  useEffect(() => {
    if (isNil(cart)) {
      getWooCommerceCart();
    }

    if (woocommerce_cart && !isNil(woocommerce_cart)) {
      var cartItems = [];
      var otherPackages = 0;
      woocommerce_cart.items.map((item) => {
        if (!isNil(item.extensions.bundles.bundled_item_data)) {
          cartItems.push({
            bundle_id:
              item.extensions.bundles.bundled_item_data.bundled_item_id,
            quantity: item.quantity,
          });
        } else {
          if (
            Object.keys(item.extensions.bundles).length === 0 &&
            item.extensions.bundles.constructor === Object
          ) {
            otherPackages++;
          }
        }
        if (!isNil(item.extensions.bundles.bundle_data)) {
        }
      });
      setTotalItems(otherPackages);
      setCart(cartItems);
    }
  }, [woocommerce_cart]);

  useEffect(() => {
    if (!isNil(data)) {

      const headerLogo = document.querySelector(".header__logo");
      const headerHeight = headerLogo ? headerLogo.offsetHeight : 0;

      const animals = [];

      for (const [animalId, position] of Object.entries(animal_types)) {
        const elemSelector = `#animal_type_${animalId}`;
        const elem = document.querySelector(elemSelector);

        if( elem ){
          animals.push({
            id: animalId,
            position: Math.max(elem.offsetTop + headerHeight, 0)
          });
        }
      }

      for (const animal of animals) {
        if (scrollY > animal.position) {
          setActiveAnimal(animal.id);
        }
      }
    }
  }, [scrollY]);

  function scrollTo(animal) {
    // Get the body's bounding rectangle
    var bodyRect = document.body.getBoundingClientRect();

    // Get the header element and its height
    var headerElement = document.querySelector(".header__logo");
    var headerHeight = headerElement ? headerElement.offsetHeight : 0;

    // Special case for non-standard identifiers (e.g., BBQ)
    if (animal === 'BBQ') {
      const bbqSection = document.querySelector(".farmshop-tab[data-tab='bbq']");
      if (bbqSection) {
        window.scrollTo({
          top: bbqSection.getBoundingClientRect().top + window.pageYOffset - headerHeight,
          behavior: "smooth"
        });
      }
      return;
    }

    // Get the animal's parent element and its bounding rectangle
    var animalElement = document.querySelector("#animal_type_" + animal);
    var animalParent = animalElement ? animalElement.parentElement : null;
    var animalRect = animalParent ? animalParent.getBoundingClientRect() : null;

    // If we can't find the animal element or its parent, we shouldn't continue
    if (!animalRect) return;

    // Get the bundle tabs element and its height
    var bundleTabsElement = document.querySelector(".bundle__tabs");
    var bundleTabsHeight = bundleTabsElement ? bundleTabsElement.offsetHeight : 0;

    // Calculate the position of the animal element relative to the page
    var animalPosition = animalRect.top - bodyRect.top - bundleTabsHeight - (isMobile ? 0 : headerHeight);

    // Adjust the position based on the header's height on mobile
    var headerTopElement = document.querySelector(".header__top");
    var headerTopHeight = headerTopElement ? headerTopElement.offsetHeight : 0;
    animalPosition = isMobile ? animalPosition - headerTopHeight : animalPosition;

    setTimeout(() => {
        // Use setTimeout to ensure consistent scroll behavior
        window.scrollTo({
            top: animalPosition - 10,
            behavior: "smooth",
        });
    }, 1);
  }

  useEffect(() => {
    function saveScroll() {
      setScrollY(window.pageYOffset);
    }

    window.addEventListener("scroll", saveScroll);

    return () => {
      window.removeEventListener("scroll", saveScroll);
    };
  });

  const addToCart = (product, quantity, action) => {
    flatstore.set('basket_status', true);
    let notificationText = '';
    let basketTotal = 0;

    if (window['ajax_delay']) {
      window['abortController']?.abort();
    }

    window['abortController'] = new AbortController(); // Instantiate new AbortController for each request
    const url = `/${currentLang()}/shop/ajaxflow/add-bundle-to-cart`;
    const postdata = new FormData();
    const bundleItems = {};

    postdata.append('product_id', bundleId); // Assuming bundleId is defined somewhere

    data.forEach(item => {
      let formQuantity = 0;
      if (window[`product_${item.bundled_item_id}`]) {
        formQuantity = window[`product_${item.bundled_item_id}`];
        basketTotal += formQuantity;
      }

      if (item.bundled_item_id === product) {
        const actionText = action === 'add' ? 'add_to_cart' : 'remove_from_cart';
        notificationText = `${item.title}${t(`farmshop.notification.${actionText}`)}`;
        formQuantity = quantity;
      }

      if (formQuantity) {
        bundleItems[item.bundled_item_id] = {
          product_id: item.meatcut_product_id, // Assuming meatcut_product_id is the product_id
          quantity: formQuantity
        };
      }
    });

    postdata.append('bundle_items', JSON.stringify(bundleItems));

    basketTotal += totalItems;
    if (basketTotal > 0) {
      flatstore.set('basket_count', { count: basketTotal });
    }

    try {
      clearTimeout(window["ajax_delay"]);
      window["ajax_delay"] = setTimeout(() => {
        const options = {
          method: "POST",
          body: postdata,
          signal: window["abortController"].signal,
        };
        fetch(url, options)
          .then(response => response.json())
          .then(response => {
            if (response.success) {
              if (notificationText) {
                setSubmissionNotice({ message: notificationText, className: 'success' });
              }
            } else {
              if( !isNil( response.data.message ) ){
                setSubmissionNotice({ message: response.data.message.join('<br />'), className: 'error' });
              }
            }

            var cartItems = [];
            if( !isNil(response.data.items) ){
              response.data.items.map((item) => {
                if (!isNil(item.bundled_item_id)) {
                  cartItems.push({
                    bundle_id: item.bundled_item_id,
                    quantity: item.quantity,
                  });
                }
              });
            }
            setCart(cartItems);

            flatstore.set('basket_count', response.data.cart_data);
            flatstore.set('basket_status', false);

          })
          .catch(err => {
            console.error(err);
            if (err.name !== "AbortError") {
              flatstore.set("basket_status", false);
            }
          });
      }, 300);
    } catch (err) {
      console.error(err);
      if (err.name !== "AbortError") {
        flatstore.set("basket_status", false);
      }
    }
  };

  const bannerCounts = {
    "nl": {
      "cow": [4, 12, 20],
      "chicken": [8],
      "pig": [8],
      "sheep": [4],
      "fish": [4],
      "deer": [7],
      // Add the rest of the banners if neccassary , count from the animal section
    },
    "de": {
      //"bioCow": [4, 12, 20],
      "cow": [4, 12, 20, 28],
      "chicken": [4],
      "pig": [4],
    },
  };

  const isBannerNeeded = (animalType, count, totalProductCount) => {
    const currentLanguage = currentLang();

    if (searchPerformed || !currentLanguage || !animalType) {
      return false;
    }

    const currentLanguageBannerCounts = bannerCounts[currentLanguage];
    const animalTypeBannerCounts = currentLanguageBannerCounts ? currentLanguageBannerCounts[animalType] : undefined;

    // Check if banner should be added at the end of the product list
    if (count === totalProductCount[animalType]) {
      return true;
    }

    // Check if the banner should be added at a specific position
    return Array.isArray(animalTypeBannerCounts) && animalTypeBannerCounts.includes(count);
  };

  const returnBrandBannerSlug = (i) =>{
    var slug = '';
    return i-1;
  }


  var loading_status = flatstore.get('basket_status');

  let dataInCart = [];
  let quantities = [];

  if (data && cart) {
    dataInCart = data.filter(item => cart.some(cartItem => cartItem.bundle_id === item.bundled_item_id));
    quantities = dataInCart.map(itemInCart => {
      const matchingCartItem = cart.find(cartItem => cartItem.bundle_id === itemInCart.bundled_item_id);
      return matchingCartItem ? matchingCartItem.quantity : 0;
    });  }

  if (isNil(cart) || isNil(data)) {
    return (
      <div className="farmshop">
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="col-12">
              <FarmshopListLoader />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="farmshop">
      {modules && <LoadModules modules={modules} type="top" />}
      {farmshop_static_data && (
        <Meta
          title={
            farmshop_static_data.seo_title
              ? farmshop_static_data.seo_title
              : farmshop_static_data.title
          }
          description={
            farmshop_static_data.seo_description &&
            farmshop_static_data.seo_description
          }
        />
      )}
      {submissionNotice.message && (
        <div className={`response ${submissionNotice.className}`} dangerouslySetInnerHTML={{ __html: submissionNotice.message }} />
      )}
      <div className="farmshop__hero">
        {/* {currentLang() == "de" &&
          <div className='badge'></div>
        } */}
        <div className="container">
            <div className="row body">
                <div className="col-12">
                    <div className="text--left text--white">
                        <h1 className="heading heading--h1 text--white m-0 mb-2">
                            <W>farmshop.hero.title</W>
                        </h1>
                        <div className="m-0">
                            <ul className="list--checkbox text--white m-0">
                                <li> <W>{t(dynamic_delivery_promise(t("farmshop.deadline_hour")))}</W> </li>
                                {parse(t("farmshop.hero.usps"))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        {currentLang() == "nl" &&
        <>
          <picture className='original'>
            <source media="(min-width:768px)" srcSet="https://www.grutto.com/storage/Headers/farm/farmshop-header-lg-3.jpg" />
            <img src="https://www.grutto.com/storage/Headers/farm/farmshop-header-sm-3.jpg" className="farmshop__hero-img" alt="" />
          </picture>
          <picture className='ab-test d-none'>
            <source media="(min-width:768px)" srcSet="https://www.grutto.com/storage/Headers/farm/de-header-2024-19-lg.jpg" />
            <img src="https://www.grutto.com/storage/Headers/farm/de-header-2024-19-sm.jpg" className="farmshop__hero-img" alt="" />
          </picture>
        </>
        }
        {currentLang() == "de" &&
        <>
          <picture className='original'>
            <source media="(min-width:768px)" srcSet="https://www.kaufnekuh.de/storage/Headers/farm/de-header-2024-19-lg.jpg" />
            <img src="https://www.kaufnekuh.de/storage/Headers/farm/de-header-2024-19-sm.jpg" className="farmshop__hero-img" alt="" />
          </picture>
        </>
        }

      </div>

      {/* <div className="container">
        <div className="bundle__title">
          <span className="organic"></span>
          <h1 className="heading">{parse(t("farmshop.top_title"))}</h1>
          <div className="desc text-center">
            <W>{t(dynamic_delivery_promise(t("farmshop.deadline_hour")))}</W>
            <br />
            <W>farmshop.top_subtitle_2</W>
            <br />
            <W>farmshop.top_subtitle_3</W>
          </div>
        </div>
      </div> */}

      <div className="bundle__tabs">
        <div className={`data-filter ${toggledClass} search`}>
          <div className="nav_holder">
            <nav>
              <ul className="tab-items" id="choose-your-meatcut">
                <li key={"farmshop_tabs_bundles"} className="nav-item d-none ab-test-bbq">
                  <Link
                    id="bundles"
                    to="#bundles"
                    title="BBQ"
                    onClick={() => {
                      scrollTo('bundles');
                    }}
                    className={
                      "nav-link farmshop-tab" +
                      (activeAnimal == 'bundles' ? " active" : "")
                    }
                    aria-current="page"
                  >
                    <span className={"ico-cat-bbq"}></span>
                    BBQ
                  </Link>
                </li>
                {data &&
                  [...new Set(data.map((item) => item.animal_type))].map((item, index) => {
                    return (
                      <li key={"farmshop_tabs_" + index} className="nav-item">
                        <Link
                          id={item}
                          to={"#" + t(item)}
                          title={upperFirst(t(item))}
                          onClick={() => {
                            scrollTo(item);
                          }}
                          className={
                            "nav-link farmshop-tab" +
                            (activeAnimal == item ? " active" : "")
                          }
                          aria-current="page"
                        >
                          <span className={"ico-cat-" + item}></span>
                          {upperFirst(t(item).replace(/-(\w)/g, (_, letter) => ` ${letter.toUpperCase()}`))}
                        </Link>
                      </li>
                    );
                  })}
              </ul>
            </nav>
          </div>
          <div className="data-filter__search">
            <div className="action" onClick={searchClose}>
              <i className="icon-magnify"></i>
            </div>
            <div className="form">
              <input
                type="text"
                placeholder={t("search.placeholder")}
                ref={searchInputRef}
                id="dataFilterSearch"
                value={searchInputValue}
                onChange={(e) => {
                  setSearchInputValue(e.target.value);
                  handleSearch(e.target.value);
                }}
                onFocus={() => searchInputRef.current && searchInputRef.current.focus()}
                />
            </div>
          </div>
        </div>
      </div>
      <div className="container farmshop-items">
        <div className="row">
          {cart && data &&
            <FarmshopOverview
            dataInCart={dataInCart}
            quantities={quantities}
            addToCartCallback={addToCart}
            isLoading={loading_status}
            />
          }
          <div className="col">
            <div
              className={
                "bundle__items" +
                (searchPerformed && filteredData.length === 0
                  ? " no-results"
                  : "")
              }
            >
              {/* Farmshop Bundles */}
              {!isNil(bundleData) && (
                <FarmshopBundles farmshop_data={data} data={bundleData} setCartHandler={setCart} />
              ) }
              {cart && data && (
                <>
                  {searchPerformed && filteredData.length === 0
                    ? noResults
                    : (filteredData.length > 0 ? filteredData : data).map(
                        (item, index) => {
                          const currentAnimalType = item.animal_type;
                          animalTypeCounters[item.animal_type]++;
                          const totalProductCount = (filteredData.length > 0 ? filteredData : data).filter(item => item.animal_type === currentAnimalType).length;
                          var brandBanner = isBannerNeeded(item.animal_type, animalTypeCounters[item.animal_type], totalProductCount);
                          if (brandBanner) slug_id++;
                          var quantity = 0;
                          cart.forEach((x) => {
                            if (x.bundle_id == item.bundled_item_id) {
                              quantity = x.quantity;
                            }
                          });
                          i++;
                          var newSection = index == 0;
                          if (prev_animal_type != item.animal_type) {
                            newSection = true;
                            prev_animal_type = item.animal_type;
                          }
                          brand_banner++;
                          return (
                            <Fragment key={"farmshop_list_product_" + index}>
                              {newSection && (
                                <Fragment>
                                  <div className={`animal_group animal_group_${item.animal_type}`}>
                                    <a
                                      name={"products_" + t(item.animal_type)}
                                    ></a>
                                    <h2 className="title m-0">
                                      <span className='name'>
                                        {upperFirst( t(item.animal_type).replace(/-([a-zA-Z])/g, (_, letter) => ` ${letter.toUpperCase()}`))}
                                      </span>

                                      {(t((item.animal_type) + '_desc')) === ""
                                          ? ''
                                          : <>
                                              <span className='sep'>-</span>
                                              <span className='desc desc1'> {t((item.animal_type) + '_desc')} </span>
                                            </>
                                      }
                                    </h2>
                                    <hr
                                      id={"animal_type_" + item.animal_type}
                                    />
                                  </div>
                                </Fragment>
                              )}
                              <FarmshopProductBox
                                data={item}
                                quantity={quantity}
                                addToCartCallback={addToCart}
                              />
                              {(brandBanner) && (
                                <BrandBanner
                                  bannerId={returnBrandBannerSlug(slug_id)}
                                  isMobile={isMobile}
                                  lang={currentLang()}
                                />
                              )}
                            </Fragment>
                          );
                        }
                      )}

                  <div className="farmshop__feedback">
                    <Link
                      to={farmshopProductURL("feedback")}
                      title={t("farmshop.feedback_title")}
                    >
                      <div className="farmshop__title">
                        <W>farmshop.feedback_title</W>
                      </div>
                      <figure className="img m-0">
                        <img
                          src={Feedback_cover}
                          alt="Feedback"
                          className="img-fluid"
                        />
                      </figure>
                    </Link>
                    <div className="farmshop__item__farmer">
                      <div className="farmshop_farmer_image">
                        <img src={Feedback_user} />
                      </div>
                      <div className="farmshop_farmer_name">
                        <W>farmshop.feedback_icon_title</W>
                      </div>
                    </div>
                    <div className="body p-2">
                      <div className="cta">
                        <Link
                          to={farmshopProductURL("feedback")}
                          title={t("farmshop.feedback_button")}
                        >
                          <div className="btn btn--border btn--full text-white">
                            <W>farmshop.feedback_button</W>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {modules && <LoadModules modules={modules} type="bottom" />}
    </div>
  );
}

export default flatstore.connect(['farmshop_data', 'farmshop_bundle_data', 'woocommerce_cart', 'farmshop_static_data'])(FarmshopList);
