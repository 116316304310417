import React, {useEffect, useState, Fragment} from 'react'
import flatstore from 'flatstore'
import {isNil} from 'lodash'
import {getBlogPost} from '../../utils/services'
import parse from 'html-react-parser'
import {blogURL, calculateTimeToRead, currentLang} from '../../utils/functions'
import RelatedBlogs from './RelatedBlogs'
import {useLocation, Link} from 'react-router-dom'
import Meta from '../../components/common/Meta'
import W from '../../components/common/Word'
import LoadModules from '../../components/modules/LoadModules'
import {siteInfo} from '../../utils/config';
import NotFound from '../static/404';
import BlogFullLoader from '../../assets/contentLoader/BlogFullLoader'
import SocialMediaShareButtons from '../../utils/social';  


function BlogFull({slug, blog_post_data, err_data}) {
    const [data, setData] = useState(null);
    const [staticData, setStaticData] = useState();
    const [modules, setModules] = useState(null);
    const [publishDate, setPublishDate] = useState(null);
    const {pathname} = useLocation();

    useEffect(() => {
        blog_post_data = null;
    }, [pathname])

    useEffect(() => {

		if(!isNil(blog_post_data)){
			setStaticData(blog_post_data);
			if(blog_post_data.modules && blog_post_data.modules.module !== null){
				setModules(blog_post_data.modules);
			}
		}
	}, [blog_post_data]);

  useEffect(() => {
    if (isNil(blog_post_data)) {
        getBlogPost(slug);
    }else{
        var url = blogURL(blog_post_data.slug, blog_post_data.categories);
        flatstore.set('canonical_url', url);
        //console.log("blogfull: "+url);
        setData(blog_post_data);
        var newdate = new Date(blog_post_data.publish_datetime).toLocaleDateString(currentLang(), { day:"numeric", year:"numeric", month:"long" } );
        setPublishDate(newdate);
    }
  }, [blog_post_data, pathname])

  const showCategories = () => {
    if(data.categories){
      return data.categories.map((category, index) => {
        // check if last item
        var seprator = ' , ';
        if(index === data.categories.length - 1){
          seprator = '';
        }
        return (
          <Fragment key={index}>
            <span className='blog_category'> {category.title} </span> {seprator}
          </Fragment>
        )
      })
    }
  }
  const showCategoriesLink = () => {
    if(data.categories){
      return data.categories.map((category, index) => {
        // check if last item
        var seprator = '<span class="mx-1"> , </span>';
        if(index === data.categories.length - 1){
          seprator = '';
        }
        return (
          <Fragment key={index}>
            <Link to={blogURL()+"/"+category.slug}> {category.title} </Link> {parse(seprator)}
          </Fragment>
        )
      })
    }
  }

  // Function to replace anchor tags with Link components
  const replaceAnchorTags = (domNode) => {
    if (domNode.type === 'tag' && domNode.name === 'a' && domNode.attribs.href) {
      var href = domNode.attribs.href;

      var siteURL = siteInfo[currentLang()]['url'];
      if( window.location.href.includes('.local') ){
          siteURL = siteInfo[currentLang()]['url_local'];
      }else if( window.location.href.includes('staging') ){
          siteURL = siteInfo[currentLang()]['url_staging'];
      }

      // Check if the href is a local link
      if (href.startsWith("/"+currentLang()+"/") || href.startsWith(siteURL)) {
        // Create a dynamic Link component with the extracted href
        return (
          <Link to={href} key={domNode.attribs.href}>
            {parse(domNode.children[0].data)}
          </Link>
        );
      }
    }
    return undefined;
  };

  if( !data && err_data && err_data == '404' ){
    return (
        <NotFound />
    );
  }

  if ( !data && ! err_data) {
    return (
        <div className="container mt-5 mb-5">
            <div className='row justify-content-center'>
              <div className='col-md-8'>
                  <BlogFullLoader />
              </div>
            </div>
        </div>
    );
  }

  return (
    <Fragment>
        {data &&
          <Meta
            title={data.seo_title ? data.seo_title : data.title}
            description={data.seo_description ? data.seo_description : data.summary}
            type="blog"
            type_data={data}
            image={data.image_url}
          />
        }
        {modules &&
            <LoadModules modules={modules} type="top" />
        }
        <div className='container pb-5'>
            <div id="fb-root"></div>
            <div className='row justify-content-center'>
            <div className='col-md-8'>
                {data &&
                    <div className='blog__full'>
                        <div className='breadcrumb'>
                            <ol>
                                <li>
                                    <a href={blogURL()}>Blog</a>
                                </li>
                                <li>
                                    {showCategoriesLink()}
                                </li>
                            </ol>
                        </div>
                        <div className='title'>
                            <h2 className='heading heading--h2'>{data.title}</h2>
                        </div>
                        <div className='post-data'>
                            <span className='time'>{calculateTimeToRead(data.body)} min</span>
                            <span className='date'> {publishDate && publishDate} </span>
                        </div>
                        <div className='image'>
                            <img src={data.image_url} className='img-fluid' />
                        </div>
                        {/* Parse the HTML content and replace anchor tags */}
                        <div className='blog__body'>{parse(data.body, { replace: replaceAnchorTags })}</div>
                    </div>
                }
            </div>
            </div>
        </div>
        
	    {data ? 
        <SocialMediaShareButtons url={window.location.href} title={data.title} />
       : (
        <p>...</p>
      )}


        {modules &&
            <LoadModules modules={modules} type="bottom" />
        }

      <div className='blog__related-post x-scroll'>
            <div className='title'> <h2 className='heading heading--h2'><W>blog.read_more</W> ...</h2> </div>
            <div className='container blog-posts'>
                <div className='row pb-3'>
                    {data && data.related_blogs.length > 0 &&
                        <RelatedBlogs data={data} />
                    }
                    <script async src='https://platform.twitter.com/widgets.js' charSet='utf-8'></script>
                </div>
            </div>
        </div>
    </Fragment>
  )
}

export default flatstore.connect(['blog_post_data', 'err_data'])(BlogFull)