import React, {useState, useEffect, Fragment} from 'react';
import flatstore from 'flatstore';
import parse from 'html-react-parser';
import axios from 'axios';
import { apiURL, meatcutURL } from '../../../utils/functions';
import {getMeatcuts} from '../../../utils/services';
import { isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
import Image from '../../common/Image';
import { Link } from 'react-router-dom';

const MeatcutOverview = ({data, meatcuts_data}) => {
    const [items, setItems] = useState(null);
    const { t } = useTranslation();

    useEffect(() => {
        meatcuts_data = null;
    }, [])

    useEffect(() => {
        if(isNil(meatcuts_data) && isNil(items)){
            getMeatcuts( data.config );
        }
        if(meatcuts_data && meatcuts_data.length > 0 && isNil(items)){
            setItems(meatcuts_data);
        }
    }, [meatcuts_data, data])

    return (
        <div className="container">
        {items &&
            <Fragment>
                {data.title &&
                    <div className="module__title">
                        <h3 className="heading heading--h3"> {data.title} </h3>
                        {data.body && <p>{parse(data.body)}</p>}
                    </div>
                }
                <div className="row justify-content-center">
                    {items.slice(0, data.total_show ?? 3).map((item, index)=>{
                        var url = meatcutURL(item.slug);
                        return (
                            <div key={index} className="col-xs-12 col-md-6 col-lg-4">
                                <div className="list__item">
                                    <div className="list__body">
                                        <div className="list__image">
                                            <Link className="read-more" to={url} title={item.title}>
                                                <Image src={item.image_url} type="meatcuts.default" alt={item.title} title={item.title} />
                                            </Link>
                                        </div>
                                        <div className="list__description">
                                            <div>
                                                <h3 className="title">
                                                    <Link className="link" to={url} title={item.title}>{item.title}</Link>
                                                </h3>
                                                <p className="excerpt">{item.summary && parse(item.summary)}</p>
                                            </div>
                                            <Link className="read-more" to={url} title={t('meatcuts.view_cooking_instructions')}>{t('meatcuts.view_cooking_instructions')}</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </Fragment>
        }
        </div>
    )
}

export default flatstore.connect(['meatcuts_data'])(MeatcutOverview);