import React, {useState, useEffect, Fragment} from 'react'
import {useTranslation} from 'react-i18next';
import { currentLang } from '../../../../utils/functions';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import {isNil} from 'lodash';

const ProductAdd = ({defaultValue, packageInfo, parentCallback, urlChange}) => {
    const [num, setNum] = useState(null);
    const { t } = useTranslation();
    const {pathname} = useLocation();
    const [total, setTotal] = useState(packageInfo.total_pieces);
    const [isLimitReached, setIsLimitReached] = useState(false); 

    useEffect(() => {
       setNum(defaultValue);
    }, [defaultValue])

    useEffect(() => {  
        if(!isNil(num)){
          urlChange(packageInfo.name, num);
          setTotal(num * packageInfo.pieces_per_vac);
        }
    }, [num])

    const plus = () =>{
      if (packageInfo.max_amount_product_vac === null || num < packageInfo.max_amount_product_vac) {
        setNum(num+1);
        parentCallback(1);
      } else {
        setIsLimitReached(true);
      }
    }

    const minus = () =>{
      if(num > 0){
        setNum(num-1);
        parentCallback(0);
        setIsLimitReached(false); 

      }
      
    }
    return (
      <Fragment>
        {packageInfo &&   
            <Fragment>
                <div className="row border package__quantity">
                    <div className="col-5 col-sm-5 col-md-6 col-lg-5 meatcuts">
                      <Link to={"/"+currentLang()+"/"+t("menu.meatcuts")+"/"+packageInfo.frontend_name_detailpage} title={packageInfo.frontend_name}>
                        {packageInfo.frontend_name}
                      </Link>
                    </div>
                    <div className="col-4 col-sm-4 col-md-2 col-lg-4 pieces">
                      <div className="product-add">
                          <span onClick={minus}> <i className="icon-minus"></i> </span>
                            {num}
                          <span onClick={plus} disabled={isLimitReached}>  <i className="icon-plus"></i> </span>
                      </div> 
                    </div>
                    <div className="col-3 col-sm-3 col-md-2 col-lg-3 meals">
                        <div className="package__meals">
                          ≃ {total} x {packageInfo.weight}g
                        </div>
                    </div>
                    
                </div>
            </Fragment>
        }
      </Fragment>
    )
}

export default ProductAdd
