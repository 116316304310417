import React, {Fragment} from 'react'
import { currentLang } from '../../../utils/functions';
import { useTranslation } from 'react-i18next';
import {Link, useNavigate, useLocation} from 'react-router-dom';
import {replace} from 'lodash';

const CategoryItem = ({id, name, icon, itemKey, activeCategory, filter}) => {
    let location = useLocation();
    const { t } = useTranslation();
    let urlpaths = replace(location.pathname, '/'+currentLang()+'/', '').split('/');
    if(urlpaths.length > 2 || (urlpaths[0].length > 1  && urlpaths[0] != t("menu.animal"))){
        var url = "/"+currentLang()+"/"+urlpaths[0]+"/"+t("menu.animal")+"/"+t(filter);
    }else{
        var url = "/"+currentLang()+"/"+t("menu.animal")+"/"+t(filter);
    }
  
    return (
        <Link to={url} key={itemKey} title={name} className={'category__item item-'+filter+' itm-'+id+' '+((activeCategory == 1 || activeCategory === id) ? "active" : "")}>
            <div className="body">
                <figure className={icon}></figure>
                <div className='title'>{name}</div>
            </div>
        </Link>
    )
}

export default CategoryItem
