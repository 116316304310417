import React, {Fragment} from 'react'
import { useTranslation } from 'react-i18next'

const SearchState = ({status}) => {
    const { t } = useTranslation();

    return (
        <div>
            <Fragment>
            {status == '3char' && 
                <div className="search-state warning">
                    <div className="icon">
                        <i className="icon-info"></i>
                    </div>
                    <h4>{t("search.notfound")}</h4>
                    <div>{t("search.minchar")}</div>
                </div>
            }
            {status == 'noresult' &&
                <div className="search-state error">
                    <div className="icon">
                        <i className="icon-warning"></i>
                    </div>
                    <h4>{t("search.notfound")}</h4>
                </div>
            }
            {status == 'only3char' && 
                <div className="search-state warning">
                    <div className="icon">
                        <i className="icon-info"></i>
                    </div>
                    <div>{t("search.minchar")}</div>
                </div>
            }
            </Fragment>
        </div>
    )
}

export default SearchState
