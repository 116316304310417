import React from 'react';
import parse from 'html-react-parser';
import {recipeURL} from '../../utils/functions';
import { useTranslation } from 'react-i18next';
import W from '../../components/common/Word';
import Image from '../../components/common/Image';
import { Link } from 'react-router-dom';

const RecipeItem = ({detail}) => {
    const {t} = useTranslation();
    const url = recipeURL(detail.slug);

    return (
        <div className="list__item">
            {detail &&
            <div className="list__body">
                <div className="list__image">
                    <Link className="read-more" to={url} title={detail.title}><Image type="recipes.list" src={detail.image_url} alt={detail.title} title={detail.title} /></Link>
                    <div className="list__info">
                        <div className="badge">
                            {detail.cook_time && <div className="badge__item location"> <span className="icon-time"></span> {detail.cook_time} </div> }
                            {detail.calories && <div className="badge__item location"> <span className="icon-calories"></span> {detail.calories} </div>}
                        </div>
                    </div>
                </div>
                <div className="list__description">
                    <div>
                        <div className="title"> <Link className="link" to={url} title={t("recipes.view")}>{detail.title}</Link> </div>
                        <p className="excerpt">
                            {detail.body && parse(detail.body)}
                        </p>
                    </div>
                    <Link className="read-more" to={url} title={t("recipes.view")}><W>recipes.view</W></Link>
                </div>
            </div>
            }
        </div>
    )
}

export default RecipeItem
