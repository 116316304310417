import ContentLoader from 'react-content-loader';
import React, { useState, useEffect } from 'react';

const FarmshopDetailLoader = () => {
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 992);

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 992);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {isWideScreen ? (
        <ContentLoader viewBox="0 0 1373 533" speed={2} backgroundColor="#d9d9d9" foregroundColor="#ededed"  style={{ width: '100%' }}>
            <rect y="62" width="710" height="471"/>
            <rect x="816" y="347" width="557" height="1"/>
            <rect x="816" y="303" width="557" height="1"/>
            <rect x="813" y="170" width="557" height="1"/>
            <rect x="813" y="110" width="557" height="1"/>
            <rect x="1163" y="122" width="207" height="37"/>
            <rect x="816" y="392" width="557" height="1"/>
            <rect x="813" y="62" width="323" height="25" rx="5"/>
            <rect x="690" width="139" height="23" rx="5"/>
            <path d="M662.646 10.6464C662.451 10.8417 662.451 11.1583 662.646 11.3536L665.828 14.5355C666.024 14.7308 666.34 14.7308 666.536 14.5355C666.731 14.3403 666.731 14.0237 666.536 13.8284L663.707 11L666.536 8.17157C666.731 7.97631 666.731 7.65973 666.536 7.46447C666.34 7.2692 666.024 7.2692 665.828 7.46447L662.646 10.6464ZM678 10.5L663 10.5V11.5L678 11.5V10.5Z"/>
            <path d="M1349.94 332.061C1350.53 332.646 1351.47 332.646 1352.06 332.061L1361.61 322.515C1362.19 321.929 1362.19 320.979 1361.61 320.393C1361.02 319.808 1360.07 319.808 1359.49 320.393L1351 328.879L1342.51 320.393C1341.93 319.808 1340.98 319.808 1340.39 320.393C1339.81 320.979 1339.81 321.929 1340.39 322.515L1349.94 332.061ZM1349.5 330V331H1352.5V330H1349.5Z"/>
            <path d="M1349.94 377.061C1350.53 377.646 1351.47 377.646 1352.06 377.061L1361.61 367.515C1362.19 366.929 1362.19 365.979 1361.61 365.393C1361.02 364.808 1360.07 364.808 1359.49 365.393L1351 373.879L1342.51 365.393C1341.93 364.808 1340.98 364.808 1340.39 365.393C1339.81 365.979 1339.81 366.929 1340.39 367.515L1349.94 377.061ZM1349.5 375V376H1352.5V375H1349.5Z"/>
            <path d="M1349.94 421.061C1350.53 421.646 1351.47 421.646 1352.06 421.061L1361.61 411.515C1362.19 410.929 1362.19 409.979 1361.61 409.393C1361.02 408.808 1360.07 408.808 1359.49 409.393L1351 417.879L1342.51 409.393C1341.93 408.808 1340.98 408.808 1340.39 409.393C1339.81 409.979 1339.81 410.929 1340.39 411.515L1349.94 421.061ZM1349.5 419V420H1352.5V419H1349.5Z"/>
            <rect x="813" y="200" width="557" height="15" rx="5"/>
            <rect x="813" y="254" width="240" height="15" rx="5"/>
            <rect x="813" y="224" width="557" height="15" rx="5"/>
            <rect x="813" y="128" width="151" height="25" rx="5"/>
            <rect x="821" y="316" width="143" height="21" rx="5"/>
            <rect x="821" y="359" width="143" height="21" rx="5"/>
            <rect x="816" y="436" width="557" height="1"/>
            <rect x="821" y="404" width="143" height="21" rx="5"/>
            <rect x="813" y="200" width="557" height="15" rx="5"/>
            <rect x="813" y="254" width="240" height="15" rx="5"/>
            <rect x="813" y="224" width="557" height="15" rx="5"/>
            <rect x="813" y="128" width="151" height="25" rx="5"/>
            <rect x="821" y="316" width="143" height="21" rx="5"/>
            <rect x="821" y="359" width="143" height="21" rx="5"/>
            <rect x="816" y="436" width="557" height="1"/>
            <rect x="821" y="404" width="143" height="21" rx="5"/>
        </ContentLoader>
      ) : (
        <ContentLoader viewBox="0 0 992 1323" speed={2} backgroundColor="#d9d9d9" foregroundColor="#ededed"  style={{ width: '100%' }}>
            <rect y="76" width="992" height="518"/>
            <rect x="440" width="139" height="23" rx="5"/>
            <path d="M412.646 10.6464C412.451 10.8417 412.451 11.1583 412.646 11.3536L415.828 14.5355C416.024 14.7308 416.34 14.7308 416.536 14.5355C416.731 14.3403 416.731 14.0237 416.536 13.8284L413.707 11L416.536 8.17157C416.731 7.97631 416.731 7.65973 416.536 7.46447C416.34 7.2692 416.024 7.2692 415.828 7.46447L412.646 10.6464ZM428 10.5L413 10.5V11.5L428 11.5V10.5Z"/>
            <rect x="5.31433" y="1163.86" width="986.686" height="1.77143"/>
            <rect x="5.31433" y="1085.91" width="986.686" height="1.77143"/>
            <rect y="850.314" width="986.686" height="1.77143"/>
            <rect y="744.029" width="986.686" height="1.77143"/>
            <rect x="620" y="765.286" width="366.686" height="65.5429"/>
            <rect x="5.31433" y="1243.57" width="986.686" height="1.77143"/>
            <rect y="659" width="572.171" height="44.2857" rx="5"/>
            <path d="M951.968 1136.58C952.554 1137.16 953.503 1137.16 954.089 1136.58L963.635 1127.03C964.221 1126.44 964.221 1125.49 963.635 1124.91C963.049 1124.32 962.1 1124.32 961.514 1124.91L953.029 1133.39L944.543 1124.91C943.957 1124.32 943.008 1124.32 942.422 1124.91C941.836 1125.49 941.836 1126.44 942.422 1127.03L951.968 1136.58ZM951.529 1133.74V1135.51H954.529V1133.74H951.529Z"/>
            <path d="M951.968 1216.29C952.554 1216.88 953.503 1216.88 954.089 1216.29L963.635 1206.74C964.221 1206.16 964.221 1205.21 963.635 1204.62C963.049 1204.04 962.1 1204.04 961.514 1204.62L953.029 1213.11L944.543 1204.62C943.957 1204.04 943.008 1204.04 942.422 1204.62C941.836 1205.21 941.836 1206.16 942.422 1206.74L951.968 1216.29ZM951.529 1213.46V1215.23H954.529V1213.46H951.529Z"/>
            <path d="M951.968 1294.23C952.554 1294.82 953.503 1294.82 954.089 1294.23L963.635 1284.69C964.221 1284.1 964.221 1283.15 963.635 1282.56C963.049 1281.98 962.1 1281.98 961.514 1282.56L953.029 1291.05L944.543 1282.56C943.957 1281.98 943.008 1281.98 942.422 1282.56C941.836 1283.15 941.836 1284.1 942.422 1284.69L951.968 1294.23ZM951.529 1291.4V1293.17H954.529V1291.4H951.529Z"/>
            <rect y="903.457" width="986.686" height="26.5714" rx="5"/>
            <rect y="999.114" width="425.143" height="26.5714" rx="5"/>
            <rect y="945.971" width="986.686" height="26.5714" rx="5"/>
            <rect y="775.914" width="267.486" height="44.2857" rx="5"/>
            <rect x="14.1714" y="1108.94" width="253.314" height="37.2" rx="5"/>
            <rect x="14.1714" y="1185.11" width="253.314" height="37.2" rx="5"/>
            <rect x="5.31433" y="1321.51" width="986.686" height="1.77143"/>
            <rect x="14.1714" y="1264.83" width="253.314" height="37.2" rx="5"/>
        </ContentLoader>
      )}
    </>
  );
};

export default FarmshopDetailLoader;