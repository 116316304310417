import axios from 'axios';
import flatstore from 'flatstore';
import {siteInfo, defaultLang,urlMaps} from './config';
import {keys, includes, isEmpty, trim, isNull, isNil, first} from 'lodash';
import {getStorage, setStorage} from './storage';
import i18n from '../init/i18n';

flatstore.set('loading', false);

if (!String.prototype.replaceAll) {
    String.prototype.replaceAll = function(str, newStr){
        // If a regex pattern
        if (Object.prototype.toString.call(str).toLowerCase() === '[object regexp]') {
            return this.replace(str, newStr);
        }
        // If a string
        return this.replace(new RegExp(str, 'g'), newStr);
    };
}

export function get(url, datastate, options={}){
    flatstore.set('loading', true);
    try{
        axios.get(url, options).then(function(response){
            flatstore.set(datastate, response.data);
            flatstore.set('loading', false);

        }).catch(err => {
            if(err.response){
                flatstore.set('err_data', err.response.status);
                flatstore.set('loading', false);
            }

        });
    }catch(err){
        if(err.response){
            flatstore.set('err_data', err.response.status);
            flatstore.set('loading', false);
        }
    }
}

export function getR(url, datastate, options={}){
    try{
        axios.get(url, options).then(function(response){
            flatstore.set(datastate, response.data);
        }).catch(err => {
            if(err.response){
                flatstore.set('err_data', err.response.status);
                flatstore.set('loading', false);
            }

        });
    }catch(err){
        if(err.response){
            flatstore.set('err_data', err.response.status);
            flatstore.set('loading', false);
        }
    }
}

export async function getSync(url, datastate, options={}){
    flatstore.set('loading', true);

    try{
        await axios.get(url, options).then(function(response){
            flatstore.set(datastate, response.data);
            flatstore.set('loading', false);
        });
    }catch(err){
        if(err.response){
            flatstore.set('err_data', err.response.status);
            flatstore.set('loading', false);
        }
    }
}

// using it for modules like relatedProducts
export function getWithoutError(url, datastate, options={}){
    try{
        axios.get(url, options).then(function(response){
            flatstore.set(datastate, response.data);
        }).catch(err => {

        });
    }catch(err){

    }
}

export function post(url, postdata, datastate, options={}){
    flatstore.set('loading', true);
    try{
        axios.post(url, postdata, options).then(function(response){flatstore.set(datastate, response.data);flatstore.set('loading', false);});
    }catch(err){
        console.log(err);
    }
}

export async function postSync(url, postdata, datastate, options={}){
    flatstore.set('loading', true);
    try{
        await axios.post(url, postdata, options).then(function(response){flatstore.set(datastate, response.data); flatstore.set('loading', false);});
    }catch(err){
        console.log(err);
    }
}

export function postR(url, postdata, datastate, options={}){
    try{
        axios.post(url, postdata, options).then(function(response){flatstore.set(datastate, response.data);});
    }catch(err){
        console.log(err);
    }
}

export function isMobile(){
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
            return true
    }
    else{
            return false
    }
}

export function currentLang() {
    var lang = window.location.pathname.substring(1,3);
    if(includes(keys(siteInfo), lang)){
        return lang;
    }else{
        return defaultLang;
    }
}

export function apiURL() {
    try {
        // Safely check if window and localStorage are available
        const isBrowser = typeof window !== 'undefined';
        const href = isBrowser ? window.location.href : '';

        let baseURL = process.env.REACT_APP_URL;

        // Validate if the environment variables are defined
        if (!baseURL) {
            throw new Error('REACT_APP_URL is not defined');
        }

        if (isBrowser) {
            const isDev = localStorage.getItem('bypass_api_cache') === 'true';

            if (isDev) {
                baseURL = process.env.REACT_APP_DEV_URL || baseURL; // Fallback to REACT_APP_URL if DEV_URL is not defined
            }

            if (href.includes('local')) {
                baseURL = process.env.REACT_APP_LOCAL_URL || baseURL; // Fallback to REACT_APP_URL if LOCAL_URL is not defined
            } else if (href.includes('alpha') || href.includes('staging')) {
                baseURL = process.env.REACT_APP_STAGING_URL || baseURL; // Fallback to REACT_APP_URL if STAGING_URL is not defined
            }
        }

        if (!currentLang() || !siteInfo[currentLang()]) {
            throw new Error('Current language or site info is not available');
        }

        const baseParams = `/${currentLang()}/${siteInfo[currentLang()]['lang']}`;

        return `${baseURL}${baseParams}`;
    } catch (error) {
        console.error('Error generating API URL:', error);
        return ''; // Return an empty string or handle the error as per the requirement
    }
}

export function isProductType(productType = 'products') {
    const currentLanguage = currentLang();

    // Helper function to sanitize URL pattern
    function sanitizePattern(pattern) {
        return pattern.replace(':slug?', '').replace(currentLanguage, '').replaceAll('/', '');
    }

    let productPatterns = [];
    urlMaps[currentLanguage].forEach(item => {
        if (item.name === productType) {
            productPatterns.push(sanitizePattern(item.pattern));
        }

        if (item.items) {
            item.items.forEach(row => {
                const tempURL = row.pattern.replace('/' + currentLanguage, '');
                if (!isEmpty(row.pattern) && !isEmpty(tempURL) && row.component === productType) {
                    productPatterns.push(sanitizePattern(row.pattern));
                }
            });
        }
    });

    if (productType === 'products') {
        window.product_urls = productPatterns;
    } else if (productType === 'farmshop-products') {
        window.farmshop_product_urls = productPatterns;
    }

    const url = window.location.pathname.split('/').filter(str => str.length > 1);
    return !!(url && url[1] && productPatterns.includes(url[1]));
}

export const getSubscriptionPaths = () => {
    const currentLanguage = currentLang();

    const subscriptionConfig = urlMaps[currentLanguage].find(item => item.name === 'subscription');
    let paths = {
        base: subscriptionConfig.pattern,
        steps: {}
    };

    if (subscriptionConfig && subscriptionConfig.items) {
        subscriptionConfig.items.forEach(item => {
            paths.steps[item.name] = item.pattern;
        });
    }

    return paths;
};

export const localStorageUtil = {
  // Method to set any type of data in localStorage
  setItem(key, value) {
    let valueToStore;
    if (typeof value === 'object' && value !== null) {
      valueToStore = JSON.stringify(value);
    } else {
      valueToStore = value;
    }
    localStorage.setItem(key, valueToStore);
  },

  // Method to get any type of data from localStorage
  getItem(key) {
    const value = localStorage.getItem(key);
    try {
      return JSON.parse(value);
    } catch (e) {
      return value;
    }
  },

  // Method to update an object in localStorage
  updateObjectItem(key, partialObject) {
    // Get the current object, or initialize to an empty object if not present
    const currentItem = this.getItem(key) || {};
    // Merge the new data with the existing object
    const updatedItem = { ...currentItem, ...partialObject };
    // Save the updated object back to localStorage
    this.setItem(key, updatedItem);
  },

  // Method to remove an item from localStorage
  removeItem(key) {
    localStorage.removeItem(key);
  }
};

export function productURL(url, slug, type){
    var arr = [];
    var defaultURL = '';
    urlMaps[currentLang()].map(item=>{
        if(item.name == 'products')
            defaultURL = item.pattern.replace(':slug?', '');
        if(item['items']){
          item['items'].map(row=>{
            var tempURL = row.pattern.replace('/'+currentLang(), '');
            if(!isEmpty(row.pattern) && !isEmpty(tempURL)){
              if(row.component == 'products'){
                arr[row.name] = row;
              }
            }
          })
        }
      })
    if(includes(keys(arr), type)){
        return arr[type].pattern.replace(':slug?', '')+slug;
    }else{
        return defaultURL+slug;
    }
}

export function farmerURL(url){
    var base = window.urls['farmers'].replace(':slug?/:page?', '');
    if(isNull(url)){
        base = base.replace(/\/$/, "");
        url = "";
    }
    return base+url;
}

export function recipeURL(url){
    var base = window.urls['recipes'].replace(':slug?/:page?', '');
    if(isNull(url)){
        base = base.replace(/\/$/, "");
        url = "";
    }
    return base+url;
}

export function meatcutURL(url){
    var base = window.urls['meatcuts'].replace(':slug?', '');
    if(isNull(url) || isEmpty(url)){
        base = base.replace(/\/$/, "");
        url = "";
    }
    return base+url;
}
export function breedURL(url){
    var base = window.urls['breeds'].replace(':slug?/:page?', '');
    if(isNull(url)){
        base = base.replace(/\/$/, "");
        url = "";
    }
    return base+url;
}

export function farmshopProductURL(url){
    var base = window.urls['farmshop-products'].replace(':slug?', '');
    if(isNull(url) || isEmpty(url)){
        base = base.replace(/\/$/, "");
        url = "";
    }
    return base+url;
}

export function blogURL(url, categories){
    var base = window.urls['blogs'].replace(':category?/:slug?/:page?', '');

    if(isNil(url) && isNil(categories)){
        base = base.replace(/\/$/, "");
        url = "";
    }else{
        if (isNil(categories) || categories.length === 0) {
            var category = 'posts';
        }else{
            var category = first(categories)['slug'];
        }
        url = category+"/"+url;

    }
    return base+url;

}

export function getPath(num){
    var url = window.location.pathname.split('/').filter(str=>str.length>1);
    return url[num];
}

export function getPaymentLogos(){
    return siteInfo[currentLang()]['payments'];
}
export function animalPercent(value){
    var price = value;
    if(value >= 100)
        price = 100;
    if(value <= 3)
        price = 3;
    if(value < 100 && value > 97)
        price = 97;
    return price;
}

export function accentsTidy(s){
    var r=s.toLowerCase();
    r = r.replace(new RegExp(/\s/g),"");
    r = r.replace(new RegExp(/[àáâãäå]/g),"a");
    r = r.replace(new RegExp(/æ/g),"ae");
    r = r.replace(new RegExp(/ç/g),"c");
    r = r.replace(new RegExp(/[èéêë]/g),"e");
    r = r.replace(new RegExp(/[ìíîï]/g),"i");
    r = r.replace(new RegExp(/ñ/g),"n");
    r = r.replace(new RegExp(/[òóôõö]/g),"o");
    r = r.replace(new RegExp(/œ/g),"oe");
    r = r.replace(new RegExp(/[ùúûü]/g),"u");
    r = r.replace(new RegExp(/[ýÿ]/g),"y");
    r = r.replace(new RegExp(/\W/g),"");
    return r;
}

export function hasClass(el, className) {
    if (el.classList) return el.classList.contains(className);
    return !!el.className.match(new RegExp('(\\s|^)' + className + '(\\s|$)'));
}

export function addClass(el, className) {
   if (el.classList) el.classList.add(className);
   else if (!hasClass(el, className)) el.className += " " + className;
}

export function removeClass(el, className) {
    if (el.classList) el.classList.remove(className);
    else if (hasClass(el, className)) {
      var reg = new RegExp('(\\s|^)' + className + '(\\s|$)');
      el.className=el.className.replace(reg, ' ');
    }
}

export function setCookie(name, value, days, path="/") {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path="+path;
}
export function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

// Usage: url = http://google.com/?test=1&ac=2
// getUrlVars()["test"]; or getUrlVars()["ac"];
export function getUrlVars() {
    var vars = {};
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
        vars[key] = value;
    });
    return vars;
}

export function listFilter(type){
    var data = flatstore.get(type+'_data');
    // We should remove this line after Akbar change the recipes API ('data' array)
    if(data && ['recipes', 'faq', 'breeds'].includes(type)){
        data = data.data;
    }

    var filterType = flatstore.get('list_filter_type');
    var page = flatstore.get('list_active_page');
    if(type == 'farmers' && filterType == 'general'){
        filterType = null;
    }
    var per_page = 18;
    if(type == 'faq'){
        per_page = 1000;
        if(isNil(filterType) || filterType.length < 2){
            filterType = 'general';
        }
    }
    var fdata = [];
    var j = 0; // nice variable name :)
    var i = (page-1)*per_page;
    var total = 0;
    var id = 0;
    if(data){
        data.map((item, index)=>{
            if(isNil(filterType) && type != 'blog'){
                if(j < per_page && id >= i){
                    fdata.push(item);
                    j++;
                }
                id++;
            }else{
                // Akbar should change animal_type to animal_types in FAQ API
                if(['faq', 'breeds'].includes(type)){
                    if(item.animal_type && item.animal_type.includes(filterType)){
                        total++;
                        if(j < per_page && id >= i){
                            fdata.push(item);
                            j++;
                        }
                        id++;
                    }
                }else if(type == 'blog'){
                    item.categories.map(cat=>{
                        if(isNil(filterType)){
                            total++;
                            if(j < per_page && id >= i){
                                if(j < 3){
                                    // Checking if two consecutive posts are not similar
                                    if (fdata.length > 0 && fdata[fdata.length - 1] === item) {
                                        return;
                                      }
                                    fdata.push(item);
                                    j++;
                                }
                            }
                            id++;
                        }else{
                            if(cat.slug == filterType){
                                total++;
                                if(j < per_page && id >= i){
                                    fdata.push(item);
                                    j++;
                                }
                                id++;
                            }
                        }

                    })

                }else{

                    if(item.animal_types && item.animal_types.includes(filterType) && item.slug != i18n.t("farmers.newfarmerslug")){
                        total++;
                        if(j < per_page && id >= i){
                            fdata.push(item);
                            j++;
                        }
                        id++;
                    }else{
                        if(item.slug == i18n.t("farmers.newfarmerslug")){
                            total++;
                        }
                    }

                }

            }

        })
        if(type == 'farmers' && filterType != 'game'){

            data.map((item, index)=>{
                if(!isNull(filterType) && Math.ceil(total/per_page) == page && item.slug == i18n.t("farmers.newfarmerslug")){
                    if(item.animal_types && item.animal_types.includes(filterType)){
                        fdata.push(item);
                        total++; // for new farmers
                    }
                }
            })
        }

        if(isNil(filterType)){
            fdata.total = data.length;
        }else{
            fdata.total = total;
        }

    }
    fdata.per_page = per_page;
    flatstore.set('list_filtered_data', fdata);
}
export function listSearch(type, search){
    var data = flatstore.get(type+'_data');
    // We should remove this line after Akbar change the recipes API ('data' array)
    if(data && ['recipes', 'faq', 'breeds'].includes(type)){
        data = data.data;
    }
    var fdata = [];
    if(search && search.length > 1){
        search = search.toLowerCase();
        var fdata = [];
        data.map((item, index)=>{
            if(type == 'faq'){
                if((item.question && accentsTidy(item.question).includes(search)) || (item.answer && accentsTidy(item.answer).includes(search))){
                    fdata.push(item);
                }
            }else if(type == 'blog'){
                if((item.title && accentsTidy(item.title).includes(search)) || (item.body && accentsTidy(item.body).includes(search))){
                    fdata.push(item);
                }
            }else{
                if((item.title && accentsTidy(item.title).includes(search)) || (item.location && accentsTidy(item.location).includes(search)) || (item.body && accentsTidy(item.body).includes(search))){
                    fdata.push(item);
                }
            }
        });
        if(fdata.length < 1){
            flatstore.set('list_search_status', 'noresult');
            flatstore.set('list_filtered_data', fdata);
        }else{
            fdata.total = 100;
            fdata.per_page = 1000;
            flatstore.set('list_filtered_data', fdata);
            flatstore.set('list_search_status', null);
        }
    }else{
        flatstore.set('list_search_status', null);
    }
}

export function checkRedirects(){
    var pathname = window.location.pathname;
    var redirects_url = 'https://www.grutto.com/configs/redirects.json';
    var redirects = getStorage('redirects');
    if(!redirects){
        axios.get(redirects_url).then(function(response){
            var items = response.data;
            if(items){
                // expire after one week
                setStorage('redirects', items, 604800);
                Object.keys(items).map((item, index)=>{
                    if(pathname == item){
                        window.location.replace(window.location.href.replace(pathname, Object.values(items)[index].to));

                    }
                });
            }
        }).catch(err => {
            console.log(err);
        });
    }else{
        // cached
        Object.keys(redirects).map((item, index)=>{
            if(pathname == item){
                window.location.replace(window.location.href.replace(pathname, Object.values(redirects)[index].to));
            }
        })
    }

}

export function addTimeAndGetFormattedDate(timeToAdd, unit) {
  const today = new Date();
  const locale = siteInfo[currentLang()]['lang'];
  let resultDate;

  if (unit === 'week') {
    resultDate = new Date(today.setDate(today.getDate() + (timeToAdd * 7)));
  } else if (unit === 'month') {
    resultDate = new Date(today.setMonth(today.getMonth() + timeToAdd));
  }

  // Using Intl.DateTimeFormat for formatting and internationalization
  const formatter = new Intl.DateTimeFormat(locale, {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  });
  const formattedDate = formatter.format(resultDate);

  return formattedDate; // e.g., "4 April 2024" or "4. April 2024" depending on locale
}

// check webp support:
export function webp_support() {
    if(navigator.vendor.match(/apple/i)){
        var webp_enable = getStorage('webp_enable');
        var webp_disable = getStorage('webp_disable');
        if(webp_enable || webp_disable){

        }else{
            var kTestImages = {
                lossy: "UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA",
                lossless: "UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==",
                alpha: "UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==",
                animation: "UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA"
            };
            var img = new Image();

            img.onload = function () {
                var result = (img.width > 0) && (img.height > 0);
                if(result){
                    setStorage('webp_enable', 1, 604800); // for a week
                    //return true;
                }
            };

            img.onerror = function () {
                setStorage('webp_disable', 1, 604800); // for a week
            };
            img.src = "data:image/webp;base64," + kTestImages['lossy'];
        }
    }
}

export function calculateTimeToRead(content){
    if(content){
        var wordsPerMinute = 200;
        var noOfWords = content.split(/\s/g).length;
        var minutes = noOfWords / wordsPerMinute;
        var readTime = Math.ceil(minutes);
        return readTime;
    }
}

export function dynamic_delivery_promise( deadline_hour ) {
    let $delivery_messages = {
        'weekend':'farmshop.top_subtitle_1_weekend',
        'next_day':'farmshop.top_subtitle_1_next_day',
        'two_days':'farmshop.top_subtitle_1_two_days'
    };
    let $rules = {
        '6':{'before':'weekend','after':'weekend'},   // satuday
        '0':{'before':'two_days','after':'two_days'}, // sunday
        '1':{'before':'next_day','after':'two_days'}, // monday
        '2':{'before':'next_day','after':'two_days'}, // tuesday
        '3':{'before':'next_day','after':'two_days'}, // wednesday
        '4':{'before':'next_day','after':'two_days'}, // thursday
        '5':{'before':'next_day','after':'weekend'},  // friday
    };

    // if( currentLang() == "nl" ){
    //     $rules['4']['after']  = 'weekend';
    //     $rules['5']['before'] = 'weekend';
    // }

    const current_date = new Date();
    let weekday = current_date.getDay();
    let hour = current_date.getHours();

    let time = 'after';
    if ( hour < deadline_hour  ) {
        time = 'before';
    }
    return $delivery_messages[$rules[weekday][time]];
}

export function objectToQueryString(obj) {
  const keys = Object.keys(obj);
  const keyValuePairs = keys.map(key => {
    return encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]);
  });
  return keyValuePairs.join('&');
}