import React, { useEffect, useRef } from 'react';

const Popup = ({ show, handleClose, children, name }) => {
  const popupRef = useRef(null);

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27 && show) { // Check if the popup is shown
        handleClose();
      }
    };

    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target) && show) { // Check if the popup is shown
        handleClose();
      }
    };

    document.addEventListener('keydown', handleEsc);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('keydown', handleEsc);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClose, show]);

  useEffect(() => {
    if (show) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [show]);

  if (!show) return null;

  return (
    <div className="popup show">
      <div className="popup-overlay" onClick={handleClose}></div>
      <div className="popup-content" ref={popupRef}>
        <div className='popup-header'>
          <div className='title'>{name}</div>
          <button className="close-btn" onClick={handleClose}>
            <i className='icon-close'></i>
          </button>
        </div>
        {children}
      </div>
    </div>
  );
};

export default Popup;