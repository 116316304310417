import React, {Fragment, useState} from 'react'
import { useTranslation } from 'react-i18next';
import {blogURL} from '../../../utils/functions'
import { Helmet } from 'react-helmet-async';

function SchemaBlog({data}) {
    const { t } = useTranslation();

    let createdDate = new Date(data.created_at);
    let updatedDate = new Date(data.updated_at);
    let canonicalurl = window.location.origin+window.location.pathname.replace(/\/$/, "");

    // generate breadcrumbs
    let breadcrumbs = {
        "@type": "BreadcrumbList",
        "itemListElement": [{
          "@type": "ListItem",
          "position": 1,
          "name": "Books",
          "item": window.location.origin + blogURL(data.slug, data.categories)
        }]
    };
    if( data.categories ){
        breadcrumbs["itemListElement"] = breadcrumbs["itemListElement"].concat(data.categories.map(category => ({
            "@type": "ListItem",
            "position": 2,
            "name": category.title,
            "item": window.location.origin + blogURL() + "/" + category.slug
        })));
    }
    breadcrumbs["itemListElement"] = breadcrumbs["itemListElement"].concat([{
        "@type": "ListItem",
        "position": data.categories ? 3 : 2,
        "name": data.title,
        "item": canonicalurl
    }]);

    let jsonld = {
        "@context": "https://schema.org",
        "@graph":[
            {
                "@type": "BlogPosting",
                "headline": data.title,
                "alternativeHeadline": data.seo_title ?? "",
                "image": [data.image_url],
                "url": canonicalurl,
                "datePublished": createdDate instanceof Date && !isNaN(createdDate) ? createdDate.toISOString() : "",
                "dateCreated": createdDate instanceof Date && !isNaN(createdDate) ? createdDate.toISOString() : "",
                "dateModified": updatedDate instanceof Date && !isNaN(updatedDate) ? updatedDate.toISOString() : "",
                "description": data.seo_description ?? "",
                "articleBody": data.body ? (data.body?.replace(/<[^>]+>/g, '') || "") : "",
                "publisher": {
                    "@type": "Organization",
                    "name": t('website_name')
                },
                "author": {
                    "@type": "Organization",
                    "name": t('website_name')
                }
            },
            breadcrumbs
        ]

    };

    return (
        <Helmet>
        <script type="application/ld+json">
            {JSON.stringify(jsonld)}
        </script>
        </Helmet>
    )
}

export default SchemaBlog
