import React, {useState, useEffect, Fragment} from 'react';
//import {t} from '../../utils/functions';
import flatstore from 'flatstore';
import Button from '../../components/common/Button';
import Meta from '../../components/common/Meta';
import W from '../../components/common/Word';
import LoadModules from '../../components/modules/LoadModules';
import AnimalFill from '../../components/product/common/AnimalFill';
import AnimalTag from '../../components/product/common/AnimalTag';
import Price from '../../components/product/common/Price';
import ProductSlider from '../../components/product/common/ProductSlider';
import ProductNewsletter from '../../components/product/common/ProductNewsletter';
import ProductContent from '../../components/product/packages/ProductContent';
import PackagePricePerMealPerPerson from '../../components/product/packages/PackagePricePerMealPerPerson';
import ProfileFarmer from '../../components/product/profile/ProfileFarmers';
import Traceability from '../../components/product/packages/Traceability';
import PackageCta from '../../components/product/packages/PackageCta';

import ProfileBuyer from '../../components/product/profile/ProfileBuyer';
import ProfilePackagesRemaining from '../../components/product/profile/ProfilePackagesRemaining';

import ProfileBreed from '../../components/product/profile/ProfileBreed';
import {isEmpty, mapValues, upperFirst, isNil, last, includes} from "lodash";
import parse from 'html-react-parser';
import {getProductInfoSync} from '../../utils/services';
import {siteInfo} from '../../utils/config';
import {currentLang, productURL, animalPercent} from '../../utils/functions';
import { useTranslation } from 'react-i18next';
import NotFound from '../static/404';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import ProductDetailLoader from '../../assets/contentLoader/ProductDetailLoader'

const ProductDetail = ({slug, loading, product_data, err_data, basket_count}) => {
    const [data, setData] = useState(null);
    const [modules, setModules] = useState(0);
    const [shopURL, setShopURL] = useState(null);
    const [btnDisable, setBtnDisable] = useState(false);
    const [variations, setVariations] = useState(null);
    const [loadingClass, setLoadingClass] = useState(false); //loading-opacity
    const [forceUpdate, setForceUpdate] = useState(true);
    const [activeVariation, setActiveVariation] = useState(null);
    const [showPercentMessage, setShowPercentMessage] = useState(false);
    const [moduleData, setModuleData] = useState(null);
    const [productPackages, setProductPackages] = useState(null);
    const [processedChecklist, setProcessedChecklist] = useState(null);
    const [updateDataLayer, setUpdateDataLayer] = useState(false);
    const [productDescOpen, setProductDescOpen] = useState(false);
    const { t, i18n } = useTranslation();
    const history = useNavigate();
    const {pathname} = useLocation();
    const animals = siteInfo[currentLang()]['animal_types'];
    useEffect(() => {
        product_data = null;
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        if(history.action == "POP"){
            var slug_r = last(window.location.href.replace(/\/$/, "").split('/'));
            product_data = null;
            setData(null);
            setShowPercentMessage(false);
            setForceUpdate(true);
            if(data)
                getProductInfoSync(slug_r);
        }else{
            // fix the click on the different menu item
            if(data && data.package_variations && !isEmpty(data.package_variations)){
                var slug_r = last(window.location.href.replace(/\/$/, "").split('/'));
                var is_new_variation = isEmpty(data.package_variations.filter(vari => vari.package_variation_slug == slug_r));
                if(is_new_variation){
                    product_data = null;
                    setData(null);
                    setShowPercentMessage(false);
                    setForceUpdate(true);
                    getProductInfoSync(slug_r);
                }else if(data.slug != slug_r){ // it's just a different variation of the same package
                    changeVariation(slug_r);
                }
            }
        }

    }, [pathname])

	useEffect(() => {

		if (isNil(product_data) && slug) {
            getProductInfoSync(slug);
        }
        if(forceUpdate && product_data){
            product_data.shop_product_percentage_sold  = animalPercent(product_data.shop_product_percentage_sold);
            setData(product_data);
            setProductPackages(product_data.packages);
            setModules(product_data.modules);
            var mdata = {
                "farmer_image_url":product_data.farmer_image_url,
                "farmer_header_image_url":product_data.farmer_header_image_url,
                "farmer_title":product_data.farmer_title,
                "farmer_location":product_data.farmer_location,
                "farmer_slug":product_data.farmer_slug,
                "farmer_is_new":product_data.farmer_is_new,
                "shop_brand_slug":product_data.shop_brand_slug,
                "animal":product_data.shop_brand_slug
            };
            setModuleData(mdata);
            setForceUpdate(false);
        }
    }, [product_data]);

    useEffect(() => {
        if(data){
            //console.log(data);
            setTimeout(()=>{setShowPercentMessage(true)}, 4000);
            setLoadingClass(false);
            if(Array.isArray(window.dataLayer) && window.dataLayer.length > 0){
                window.dataLayer.map((item, index)=>{
                    if(item.productInfo){
                        delete window.dataLayer[index];
                    }
                })
            }
            window.dataLayer = window.dataLayer || [];
            var dataLayerProductData = {
                'category_slug':data.category_slug,
                'package_slug':data.package_slug,
                'product_id':data.shop_package_variation_slug,
                'relation_slug':data.relation_slug,
                'slug':data.slug,
                'title':data.shop_package_variation_name,
                'variation_slug':data.variation_slug,
                'shop_product_id':data.shop_product_id,
                'shop_package_price':data.shop_package_price,
                'shop_package_id':data.shop_package_id,
                'shop_package_frontend_size':data.shop_package_frontend_size,
                'shop_brand_slug':data.shop_brand_slug,
                'shop_animal_brand':data.shop_animal_brand,
                'shop_product_title':data.shop_product_title,
                'language':siteInfo[currentLang()]['lang']
            }
            window.dataLayer.push({
                'productInfo': dataLayerProductData,
                'event':'grutto-product-view'
            });
            setUpdateDataLayer(true);

        }
        if(data){
            setProductPackages(data.packages);
            if(data.modules){
                    setModules(data.modules);
                    var mdata = {
                        "farmer_image_url":data.farmer_image_url,
                        "farmer_header_image_url":data.farmer_header_image_url,
                        "farmer_title":data.farmer_title,
                        "farmer_location":data.farmer_location,
                        "farmer_slug":data.farmer_slug,
                        "farmer_is_new":data.farmer_is_new,
                        "shop_brand_slug":data.shop_brand_slug,
                        "animal":product_data.shop_brand_slug
                    };
                setModuleData(mdata);
            }
        }

        if(data && data.package_variations && !isEmpty(data.package_variations) && data.package_variations.length > 1){
            data.package_variations[0].variation_active_slug = data.slug;
            setVariations(data.package_variations)
        }
    }, [data])

    useEffect(() => {
        if ( data && data.checklist){
            if(data.product_shop_delivery_date && includes(data.checklist, '__estimated_delivery_date__')){
                setProcessedChecklist(data.checklist.replaceAll( '__estimated_delivery_date__', t('estimated_delivery_date')+data.product_shop_delivery_date));
            }else{
                setProcessedChecklist(data.checklist);
            }
        }
    }, [data]);

    const changeVariation = (value) =>{
        setProductPackages(null);
        setShowPercentMessage(false);
        setLoadingClass(true);
        setActiveVariation(value);
        setForceUpdate(true);
        getProductInfoSync(value);


    }
    const handleBasket = (value) =>{
        if(value == 0){
            setBtnDisable(true);
        }else{
            setBtnDisable(false);
        }
    }

    const createURL = (params) => {
        // fix the problem with grutto.de
        var siteURL = siteInfo[currentLang()]['url'];
        if( window.location.href.includes('.local') ){
            siteURL = siteInfo[currentLang()]['url_local'];
        }else if( window.location.href.includes('staging') ){
            siteURL = siteInfo[currentLang()]['url_staging'];
        }


        if(params){
            var url_params = "";
            if(!isEmpty(params)){
                mapValues(params, (value, key)=>{
                    url_params += "&"+key+"="+value;
                });
            }
            var tmpURL = siteURL+"shop/order-package/?category="+data.category_slug
                        +"&path="+t("menu.products")+"/"+data.slug;

            if(JSON.parse(data.shop_package_attributes) != undefined){
                var attr = JSON.parse(data.shop_package_attributes);
                for(var aKey in attr){
                    tmpURL += "&"+aKey+"="+attr[aKey];
                }
            }
            tmpURL += url_params;

            setShopURL(tmpURL);
        }
    }

    useEffect(() => {
        if(basket_count && basket_count.unique_id && updateDataLayer){
            window.dataLayer.push({
                'event': 'wp_unique_id',
                'wp_unique_id': basket_count.unique_id,
                'wp_date': basket_count.date
            });
        }

    }, [basket_count, updateDataLayer])

    if( !data && err_data && err_data == '404' ){
        return (
            <NotFound />
        );
    }

    if (isNil(data)) {
        return (
          <div className="farmshop">
            <div className="container mt-5 mb-5">
              <div className="row">
                <div className="col-12">
                  <ProductDetailLoader />
                </div>
              </div>
            </div>
          </div>
        );
      }

    return (
        <Fragment>
        {data &&
            <Fragment>
                {moduleData &&
                    <div>
                    <LoadModules modules={modules} product={data} moduleKey={slug} moduleData={moduleData} type="top" />
                    </div>
                }
                <Meta
                    title={data.seo_title ?? data.title}
                    description={data.seo_description ?? data.body}
                    type="product"
                    type_data={data}
                    image={data.image_url}
                />
                <div className={"container package__full--info package--" + data.shop_brand_slug + (data.is_organic == 1 || data.is_demeter == 1 ? " package--bio" : "") + (data.is_msc == 1 ? " package--msc" : "") + (data.is_special == 1 ? " package--special" : "")}>

                {data.package_variations && !isEmpty(data.package_variations) && data.package_variations.length > 1 &&
                    <div className="row d-none">
                        <div className="col-xxl-6 offset-xxl-3 col-xl-8 offset-xl-2 offset-lg-1 col-lg-10 col-md-12">
                            <div className="button-group__holder">
                                <div className="button-group">
                                {data.package_variations.map((item, index) => {
                                    var variationClass = 'button-group__item button-group--blue';
                                    if((data.slug == item.package_variation_slug && !loadingClass) || (loadingClass && activeVariation == item.package_variation_slug))
                                        variationClass = 'button-group__item button-group--red active'
                                    return (
                                        <Fragment key={"product_package_variations_"+index}>
                                            <Link  to={productURL(t('menu.products'), item.package_variation_slug, data.shop_brand_slug)} className={variationClass}>{item.package_variation_title}</Link>
                                        </Fragment>
                                    )
                                })}
                                </div>
                            </div>
                        </div>
                    </div>
                }


                <div className={"package__title"+(loadingClass ? " loading-opacity" : "")}>
                    <h1 className="heading heading--h2 text-center">
                        {(data.is_organic == 1 || data.is_demeter == 1) &&
                            <span className="text--green bio"> <W>general.organic</W> </span>
                        }
                        {data.title}
                        {(data.is_special == 1) &&
                            <span className="text--red special"> <W>product.special</W> </span>
                        }
                        {(data.is_highlight == "express") &&
                            <span className="text--orange special"> <W>product.express</W> </span>
                        }
                    </h1>
                    <div className="package__info">
                        <ul className="list list--no-list text-center">
                            {isNil(data.meatcut_pieces_suffix) &&
                                <li>{data.shop_package_count} <W>product.differentmeatcuts</W></li>
                            }
                            {!isNil(data.meatcut_pieces_suffix) &&
                                <li>{data.shop_package_count} {data.meatcut_pieces_suffix}</li>
                            }
                            <li>{parseInt(data.shop_package_total_meals_for_person)} <W>product.meals</W> / {data.shop_package_frontend_size}</li>
                            <li>{data.additional_info}</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className={"container pt-3 pb-4 package__full package--" + data.shop_brand_slug+(loadingClass ? " loading-opacity" : "") + (" " + data.category_slug)+ (" " + currentLang())}>
                <div className="row">
                    <div className="col-12 col-lg-6">

                        <div className="percentage__box">
                            <AnimalTag
                                country={data.shop_animal_nr_prefix}
                                amount={data.shop_animal_nr}
                                currentAnimal={data.shop_brand_slug}
                            />
                            <AnimalFill
                                percentage={data.shop_product_percentage_sold}
                                animal={data.shop_brand_slug}
                                category_slug={data.shop_category_slug}
                            />

                        </div>

                        <div className="package__spec">
                            <ProfileFarmer
                                name={data.farmer_name}
                                title={data.farmer_title}
                                body={data.farmer_summary}
                                location={data.farmer_location}
                                image={data.farmer_image_url}
                                link={data.farmer_slug}
                                earnumber={data.shop_animal_nr}
                                isnew={data.farmer_is_new}
                                animal={data.shop_brand_slug}
                                breeds={data.shop_animal_name}
                            />

                            <ProfileBuyer
                                totalBuyers={data.shop_product_buyer_count}
                                totalPackages={data.shop_product_total_package_available}
                                soldPercentage={data.shop_product_percentage_sold}
                                animalType={data.shop_brand_slug}
                            />

                            <ProfilePackagesRemaining
                                totalBuyers={data.shop_product_buyer_count}
                                totalPackages={data.shop_product_total_package_available}
                                soldPercentage={data.shop_product_percentage_sold}
                                animalType={data.shop_brand_slug}
                            />

                            <ProfileBreed
                                slug={data.breed_slug}
                                title={data.breed_title}
                                body={data.breed_body}
                                image={data.breed_image_url}
                            />
                            <div className="item item-meal-per-person">
                                {
                                <PackagePricePerMealPerPerson
                                    value={data.shop_package_price_per_meals &&data.shop_package_price_per_meals.toFixed(2)}
                                />
                                }
                            </div>
                        </div>

                        <div className={"package__full--gallery " + (data.is_organic == 1 ? 'bio' : '') + (data.is_msc == 1 ? 'msc' : '') + (data.has_label_rouge == 1 ? 'label-rouge' : '')}>

                            {data.main_slider &&
                                <ProductSlider type={null} data={data.main_slider} />
                            }
                        </div>
                        {data.body &&
                            <Fragment>
                                <div className="package__full--body">
                                    <div className='heading--h3 mb-2 color--primary'>{data.body_title ? data.body_title : t('product.description_title')}</div>
                                    {parse(data.body)}
                                </div>
                            </Fragment>
                        }
                    </div>
                    <div className="col-12 col-lg-5 offset-lg-1">
                        <div className="row package__order align-items-center mt-4 pt-3 pb-3">
                            <div className="col-6 col-md-4 col-lg-12 package__price">
                                {!isNil(data.previous_price) &&
                                    <div className="p-prev">
                                        <Price value={data.previous_price} />
                                    </div>
                                }
                                <div className={"p-main"}>
                                    <Price value={data.shop_package_price} />
                                </div>
                                <div className="package__meal">
                                    {data.shop_package_frontend_size} / {parseInt(data.shop_package_total_meals_for_person)} <W>product.meals</W>
                                </div>
                            </div>
                            <div className="col-6 col-md-4 col-lg-12 package__btn">
                                <PackageCta
                                    stockStatus={data.shop_product_stock_status}
                                    shopUrl={shopURL}
                                    btnDisable={btnDisable}
                                />
                            </div>
                        </div>
                        {/* I know ;) I will refactor it later */}
                        {showPercentMessage && data.shop_product_percentage_sold <= 50 && data.shop_product_stock_status != 'coming_soon' &&
                        <div className="message__urgency success">
                            <div className="status"> <W>product.notification_btn.l50</W> </div>
                            <div className="description">
                              {(i18n.exists('product.notification.'+data.shop_brand_slug)) ? t('product.notification.'+data.shop_brand_slug, {animal_nr: data.shop_animal_nr, percentage: data.shop_product_percentage_sold }) : upperFirst(t(data.shop_brand_slug)) + ' ' + t('product.notification_message', {animal_nr: data.shop_animal_nr, percentage: data.shop_product_percentage_sold })}
                            </div>
                        </div>
                        }
                        {showPercentMessage && data.shop_product_percentage_sold <= 75 && data.shop_product_percentage_sold > 50 && data.shop_product_stock_status != 'coming_soon' &&
                        <div className="message__urgency warning">
                            <div className="status"> <W>product.notification_btn.l75</W> </div>
                            <div className="description">
                               {(i18n.exists('product.notification.'+data.shop_brand_slug)) ? t('product.notification.'+data.shop_brand_slug, {animal_nr: data.shop_animal_nr, percentage: data.shop_product_percentage_sold }) : upperFirst(t(data.shop_brand_slug)) + ' ' + t('product.notification_message', {animal_nr: data.shop_animal_nr, percentage: data.shop_product_percentage_sold })}
                            </div>
                        </div>
                        }
                        {showPercentMessage && data.shop_product_percentage_sold > 75 && data.shop_product_percentage_sold < 100 && data.shop_product_stock_status != 'coming_soon' &&
                        <div className="message__urgency error">
                            <div className="status"> <W>product.notification_btn.l100</W> </div>
                            <div className="description">
                               {(i18n.exists('product.notification.'+data.shop_brand_slug)) ? t('product.notification.'+data.shop_brand_slug, {animal_nr: data.shop_animal_nr, percentage: data.shop_product_percentage_sold }) : upperFirst(t(data.shop_brand_slug)) + ' ' + t('product.notification_message', {animal_nr: data.shop_animal_nr, percentage: data.shop_product_percentage_sold })}
                            </div>
                        </div>
                        }

                        {data.shop_product_stock_status != "instock" &&
                        <div className="row">
                            <div className="col-md-12">

                                {includes(animals, data.shop_brand_slug) &&
                                    <ProductNewsletter status={data.shop_product_stock_status} productId={data.slug} animaltype={data.shop_brand_slug} />
                                }
                            </div>
                        </div>
                        }

                        <ul className="package__usp">
                            {processedChecklist && parse(processedChecklist)}
                        </ul>

                        {productPackages &&
                            <Fragment>
                                <ProductContent
                                    perperson={data.shop_package_price_per_meals}
                                    raw_data={data}
                                    data={productPackages}
                                    handleBasket={handleBasket}
                                    createURL={createURL}
                                    stockStatus={data.shop_product_stock_status}
                                    shopUrl={shopURL}
                                    btnDisable={btnDisable}
                                />
                            </Fragment>
                        }
                        {data.body &&
                            <Fragment>
                                <div className={"package__full--body2 accordion " + (productDescOpen ? 'open' : '')}>
                                    <div className={"accordion__title " + (productDescOpen ? 'open' : '')} onClick={() => {setProductDescOpen(!productDescOpen)}}>
                                        <span>
                                            <h2><W>product.description_title</W></h2>
                                            <p>
                                                {parseInt(data.shop_package_total_meals_for_person)} <W>product.meals</W> - {Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(data.shop_package_price_per_kilo)} p/kg {isNil(data.meatcut_pieces_suffix) && ' - '+data.shop_package_count+' '+t('product.differentmeatcuts')}
                                            </p>
                                        </span>
                                        <i className="icon-angle-down"></i>
                                    </div>
                                    <div className={"accordion__body "+(productDescOpen ? 'open' : '')}>
                                        {parse(data.body)}
                                    </div>
                                </div>
                            </Fragment>
                        }

                    </div>
                </div>


                <div className="row traceability-enable">
                    <div className="col-12">
                        <Traceability type="product" data={data} />
                    </div>
                </div>

            </div>
            {moduleData &&
                <div>
                <LoadModules modules={modules} product={data} moduleData={moduleData} type="bottom" />
                </div>
            }
            </Fragment>
        }

        </Fragment>
    )
}

export default flatstore.connect(['loading', 'product_data', 'err_data', 'basket_count'])(ProductDetail);
