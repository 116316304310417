import React, {Fragment} from 'react';
import { currentLang, farmerURL } from '../../../utils/functions';
import { useTranslation } from 'react-i18next';
import Image from '../../common/Image';
import { Link } from 'react-router-dom';

function FarmerBanner({data}) {
    const { t } = useTranslation();
    const url = farmerURL((data.farmer_is_new == 1 ? t("farmers.newfarmerslug")+"/"+t(data.farmer_animal) : data.farmer_slug));
    return (
        <Fragment>
        <div className="module__farmer">
            <div className="module__farmer__bg">
                {data.farmer_header_image_url &&
                    <Image type="farmers.header" src={data.farmer_header_image_url} alt={data.farmer_title} title={data.farmer_title} />
                }
            </div>
            <div className="module__farmer__body">
                <div className="container">
                    <div className="row">
                        <div className="col">

                            <div className="module__farmer__info">
                                <div className="module__farmer__image">
                                    {data.farmer_image_url ? <Image type="farmers.module" src={data.farmer_image_url} alt={data.farmer_title} title={data.farmer_title} /> : <span className="icon-farmers"></span>}
                                </div>
                                <div className="module__farmer__text">
                                    <div className="badge">
                                        <div className="badge__item location"> <span className="icon-location"></span> {data.farmer_location} </div>
                                    </div>
                                    <div className="module__farmer__name">
                                        <div className="heading heading--h3">
                                            <Link to={url} title={data.farmer_title}>
                                                {data.farmer_title}
                                            </Link>
                                        </div>
                                        <div className="module__farmer__cta">
                                            {['game', 'roe', 'deer', 'boar'].includes(data.animal) &&
                                                <a className="btn btn--red" href={url} title={t("farmers.readmoreaboutfarmer_deer")}>{t("farmers.readmoreaboutfarmer_deer")}</a>
                                            }
                                            {['fish', 'salmon'].includes(data.animal) &&
                                                <a className="btn btn--red" href={url} title={t("farmers.readmoreaboutfarmer_fish")}>{t("farmers.readmoreaboutfarmer_fish")}</a>
                                            }
                                            {!['game', 'roe', 'deer', 'boar', 'fish', 'salmon'].includes(data.animal) &&
                                                <a className="btn btn--red" href={url} title={t("farmers.readmoreaboutfarmer")}>{t("farmers.readmoreaboutfarmer")}</a>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="module__farmer__expand"></div>
        </Fragment>
    )
}

export default FarmerBanner
