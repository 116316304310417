import ContentLoader from 'react-content-loader';
import React, { useState, useEffect } from 'react';

const BlogFullLoader = () => {
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 992);

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 992);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {isWideScreen ? (
        <ContentLoader viewBox="0 0 932 969" speed={2} backgroundColor="#d9d9d9" foregroundColor="#ededed"  style={{ width: '100%' }}>
          <rect x="266" y="42" width="400" height="28" rx="5"/>
          <rect x="458" y="95" width="96" height="10" rx="5"/>
          <rect x="441" y="93" width="14" height="14" rx="7"/>
          <rect x="395" y="95" width="36" height="10" rx="5"/>
          <rect x="378" y="93" width="14" height="14" rx="7"/>
          <rect y="129" width="932" height="417"/>
          <rect x="362" y="6" width="68" height="12" rx="5"/>
          <rect x="473" y="6" width="96" height="12" rx="5"/>
          <path d="M459.061 13.0607C459.646 12.4749 459.646 11.5251 459.061 10.9393L449.515 1.3934C448.929 0.807611 447.979 0.807611 447.393 1.3934C446.808 1.97919 446.808 2.92893 447.393 3.51472L455.879 12L447.393 20.4853C446.808 21.0711 446.808 22.0208 447.393 22.6066C447.979 23.1924 448.929 23.1924 449.515 22.6066L459.061 13.0607ZM457 13.5H458V10.5H457V13.5Z"/>
          <rect y="677" width="359" height="22" rx="5"/>
          <rect y="867" width="216" height="22" rx="5"/>
          <rect y="576" width="932" height="8" rx="4"/>
          <rect y="596" width="932" height="8" rx="4"/>
          <rect y="616" width="932" height="8" rx="4"/>
          <rect y="711" width="932" height="8" rx="4"/>
          <rect y="731" width="932" height="8" rx="4"/>
          <rect y="751" width="932" height="8" rx="4"/>
          <rect y="791" width="932" height="8" rx="4"/>
          <rect y="771" width="768" height="8" rx="4"/>
          <rect y="811" width="932" height="8" rx="4"/>
          <rect y="831" width="584" height="8" rx="4"/>
          <rect y="636" width="584" height="8" rx="4"/>
          <rect y="901" width="932" height="8" rx="4"/>
          <rect y="921" width="932" height="8" rx="4"/>
          <rect y="941" width="932" height="8" rx="4"/>
          <rect y="961" width="768" height="8" rx="4"/>
        </ContentLoader>
      ) : (
        <ContentLoader viewBox="0 0 600 847" speed={2} backgroundColor="#d9d9d9" foregroundColor="#ededed"  style={{ width: '100%' }}>
          <rect y="129" width="600" height="286"/>
          <rect x="100" y="42" width="400" height="28" rx="5"/>
          <rect x="292" y="95" width="96" height="10" rx="5"/>
          <rect x="275" y="93" width="14" height="14" rx="7"/>
          <rect x="229" y="95" width="36" height="10" rx="5"/>
          <rect x="212" y="93" width="14" height="14" rx="7"/>
          <rect x="196" y="6" width="68" height="12" rx="5"/>
          <rect x="307" y="6" width="96" height="12" rx="5"/>
          <path d="M293.061 13.0607C293.646 12.4749 293.646 11.5251 293.061 10.9393L283.515 1.3934C282.929 0.807612 281.979 0.807612 281.393 1.3934C280.808 1.97918 280.808 2.92893 281.393 3.51472L289.879 12L281.393 20.4853C280.808 21.0711 280.808 22.0208 281.393 22.6066C281.979 23.1924 282.929 23.1924 283.515 22.6066L293.061 13.0607ZM291 13.5H292V10.5H291V13.5Z"/>
          <rect y="555" width="231" height="22" rx="5"/>
          <rect y="745" width="139" height="22" rx="5"/>
          <rect y="454" width="600" height="8" rx="4"/>
          <rect y="474" width="600" height="8" rx="4"/>
          <rect y="494" width="600" height="8" rx="4"/>
          <rect y="589" width="600" height="8" rx="4"/>
          <rect y="609" width="600" height="8" rx="4"/>
          <rect y="629" width="600" height="8" rx="4"/>
          <rect y="669" width="600" height="8" rx="4"/>
          <rect y="649" width="494" height="8" rx="4"/>
          <rect y="689" width="600" height="8" rx="4"/>
          <rect y="709" width="377" height="8" rx="4"/>
          <rect y="514" width="377" height="8" rx="4"/>
          <rect y="779" width="600" height="8" rx="4"/>
          <rect y="799" width="600" height="8" rx="4"/>
          <rect y="819" width="600" height="8" rx="4"/>
          <rect y="839" width="494" height="8" rx="4"/>
        </ContentLoader>
      )}
    </>
  );
};

export default BlogFullLoader;