import React, { useEffect } from 'react'
import BlogList from './BlogList';
import BlogFull from './BlogFull';
import flatstore from 'flatstore';
import { replace } from 'lodash';
import { currentLang } from '../../utils/functions';

function Blog({ match }) {
    let urlpaths = replace(location.pathname, '/' + currentLang() + '/', '').split('/');
    let category = urlpaths[1];
    let slug = urlpaths[2];
    let page = 1;

    let detailPage = true;

    if (category) {
        if (parseInt(category) > 0) {
            page = category;
            category = null;
            slug = null;
            detailPage = null;
        } else if (slug && parseInt(slug) > 0) {
            page = slug;
            detailPage = null;
        } else if (!slug) {
            detailPage = null;
        }
    } else {
        detailPage = null;
    }

    useEffect(() => {
        if (!detailPage) {
            flatstore.set('list_active_page', page);

            if (category) {
                flatstore.set('list_filter_type', category);
            }
        }
    }, [detailPage, page, category]);

    if (detailPage) {
        return <BlogFull slug={slug} />;
    } else {
        return <BlogList page={page} filter={category} />;
    }
}

export default Blog;