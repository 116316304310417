import React, {useState, useEffect, Fragment, useRef} from 'react'
import Image from '../../common/Image'
import Swiper from 'react-id-swiper';
import parse from 'html-react-parser';
import 'swiper/scss'
import {isNil, isArray} from 'lodash';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


const Slider = ({data, config}) => {
    const [swiper, setSwiper] = useState(null);
    const [count, setCount] = useState(null);
    const [activeSlide, setActiveSlide] = useState(0);
    const { t } = useTranslation();
    const {pathname} = useLocation();
    const ref = useRef(null);
    const responsive = (window.innerWidth >= 992) ? false : true;

    useEffect(() => {
        if (swiper !== null) {

        }

    }, [pathname])

    useEffect(() => {
        var datacount = (data && isArray(data)) ? data.length : 0;
        setCount(datacount);

        if(!isNil(swiper)){
            swiper.imagesLoaded = datacount;
            if(swiper.hasOwnProperty('slideTo')){
                swiper.slideTo(0);
                setActiveSlide(0);
                swiper.update();
            }
        }

    }, [data, pathname])

    useEffect(() => {
        if (ref.current !== null && ref.current.swiper !== null) {
            setSwiper(ref.current.swiper);
        }
    }, [ref, count])

    let touchThreshold = 30;
    if(window.innerWidth <= 768 ){
        touchThreshold = 0;
    }

    if(data && isNil(has_video)){
        var has_video = false;
        data.map(item=>{if(item.type == 'video_url'){ has_video = true; }});
    }


    const createVideoLink = (url) =>{
        if(url.includes('youtube')){
            var video_id = url.split('v=')[1];
            var url = 'https://www.youtube.com/embed/'+video_id+"?rel=0&modestbranding=1&iv_load_policy=3";
            return url;
        }
        if(url.includes('vimeo')){
            var video_id = url.split('.com/')[1];
            var url = 'https://player.vimeo.com/video/'+video_id;
            return url;
        }
    }

    const next = () => {
        if(activeSlide !== count-1){
            setActiveSlide(activeSlide+1);
            swiper.slideNext();
        }

    };

    const prev = () => {
        if(activeSlide !== 0){
            setActiveSlide(activeSlide-1);
            if (swiper !== null) {
                swiper.slidePrev();
            }
        }
    };

    const slideTo = index => {
        if(!isNil(swiper)){
            swiper.slideTo(index);
            setActiveSlide(index);
        }
    };

    useEffect(() => {
        if (swiper !== null) {
            swiper.on("slideChange", () => {

                if(activeSlide !== count-1){
                    setActiveSlide(swiper.realIndex);
                }
            });
        }

        return () => {
            if (swiper !== null) {
                swiper.off("slideChange", () => setActiveSlide(swiper.realIndex));
            }
        };
    }, [swiper]);

    return (

        <section className={"carousel__holder "+((config && !isNil(config.className)) ? config.className : "")+((config && config.body && !isNil(config.position)) ? " slider-text-"+config.position : "")+((has_video) ? " carousel__video" : "")}>
        {config && config.body &&
            <div className="text__holder">
                <div className="text">
                    <Fragment>
                        {parse(config.body)}
                    </Fragment>
                </div>
            </div>
        }
        <div className={"carousel"}>
            {data && count && count > 0 &&
                <Fragment>
                    <ul className="carousel__nav">
                        {data && count > 1 && data.map((item, index)=>{
                            return <li key={"slider-nav-"+index} className={index === activeSlide ? "active" : ""} onClick={()=>slideTo(index)}></li>
                        })}
                    </ul>
                    <div className={"items"+((count && count == 1 && data[0].type == 'image') ? ' slider-single-item' : '')}>
                        {data && count && count == 1 && data[0].type == 'image' &&
                            <div className={"item"}>
                                <Image className="slider-img" alt={data[0].image_alt_text ? data[0].image_alt_text : ''} src={responsive && data[0].responsive_image_url && data[0].responsive_image_url ? data[0].responsive_image_url : data[0].content} />
                                <div className="carousel__content">
                                        {data[0].body && parse(data[0].body)}
                                </div>
                            </div>
                        }
                        {data && count && count > 1 &&
                            <Swiper threshold={touchThreshold} ref={ref}>
                                {data.map((item, index)=>{
                                    if(item.image_url){
                                        item.content = item.image_url;
                                        item.type = 'image';
                                    }
                                    if(item.type == 'image' && responsive && item.responsive_image_url){
                                        item.src = item.responsive_image_url;
                                    }
                                    return (
                                        <div id={"sliderItem"+item.slideritem_id} key={index} className={"item"+(!isNil(item.background_color) ? " "+item.background_color : "")+(!isNil(item.custom_class) ? " "+item.custom_class : "")}>
                                            {item.type == 'image' &&
                                                <Fragment>
                                                    {item.content &&
                                                        <Image className="slider-img" alt={item.image_alt_text ? item.image_alt_text : ''} src={item.content} />
                                                    }
                                                    <div className="carousel__content">
                                                        <Fragment>
                                                            {item.body && parse(item.body)}
                                                        </Fragment>
                                                    </div>

                                                </Fragment>
                                            }
                                              {item.content && item.type == 'video_url' &&
                                                    <div className="video">
                                                        <iframe className="gruttoYT" src={createVideoLink(item.content)} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true}></iframe>
                                                    </div>
                                                }

                                        </div>
                                    )
                                })}
                            </Swiper>
                        }
                         {data && count && count == 1 && data[0].type == 'video_url' &&
                            <div className="video">
                                <iframe className="gruttoYT" src={createVideoLink(data[0].content)} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true}></iframe>
                            </div>
                        }

                    </div>
                    {data && count > 1 &&
                    <div className="carousel__bottom">
                        <a className={"prev"+(activeSlide > 0 ? " active" : "")} onClick={prev}> <span className="icon-arrow-right"></span> </a>
                        <a className={"next"+(activeSlide < count-1 ? " active" : "")} onClick={next}> <span className="icon-arrow-right"></span> </a>
                    </div>
                    }
            </Fragment>
        }
        </div>
        </section>

    )
}

export default Slider