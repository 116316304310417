import ContentLoader from 'react-content-loader';
import React, { useState, useEffect } from 'react';

const FarmerDetailLoader = () => {
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 992);

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 992);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {isWideScreen ? (
        <ContentLoader viewBox="0 0 1420 875" speed={2} backgroundColor="#d9d9d9" foregroundColor="#ededed"  style={{ width: '100%' }}>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M1420 0H0V294H60.7016C47.5963 273.499 40 249.137 40 223C40 150.098 99.0984 91 172 91C244.902 91 304 150.098 304 223C304 249.137 296.404 273.499 283.298 294H1420V0ZM1273 237C1273 234.791 1274.79 233 1277 233H1387C1389.21 233 1391 234.791 1391 237V259C1391 261.209 1389.21 263 1387 263H1277C1274.79 263 1273 261.209 1273 259V237ZM345 233C342.791 233 341 234.791 341 237V259C341 261.209 342.791 263 345 263H646C648.209 263 650 261.209 650 259V237C650 234.791 648.209 233 646 233H345Z"/>
          <circle cx="172" cy="223" r="118"/>
          <rect x="40" y="387" width="635" height="2"/>
          <rect x="40" y="533" width="635" height="2"/>
          <rect x="40" y="387" width="2" height="148"/>
          <rect x="675" y="387" width="2" height="148"/>
          <rect x="73" y="420" width="101" height="14" rx="5"/>
          <rect x="73" y="493" width="101" height="14" rx="5"/>
          <rect x="325" y="420" width="82" height="14" rx="5"/>
          <rect x="73" y="453" width="43" height="14" rx="5"/>
          <rect x="325" y="493" width="82" height="14" rx="5"/>
          <rect x="325" y="453" width="130" height="14" rx="5"/>
          <rect x="754" y="546" width="275" height="22" rx="5"/>
          <rect x="754" y="753" width="380" height="22" rx="5"/>
          <rect x="754" y="390" width="642" height="8" rx="4"/>
          <rect x="754" y="410" width="642" height="8" rx="4"/>
          <rect x="754" y="430" width="642" height="8" rx="4"/>
          <rect x="754" y="470" width="642" height="8" rx="4"/>
          <rect x="754" y="450" width="528" height="8" rx="4"/>
          <rect x="754" y="490" width="642" height="8" rx="4"/>
          <rect x="754" y="580" width="642" height="8" rx="4"/>
          <rect x="754" y="660" width="642" height="8" rx="4"/>
          <rect x="754" y="600" width="642" height="8" rx="4"/>
          <rect x="754" y="680" width="642" height="8" rx="4"/>
          <rect x="754" y="620" width="642" height="8" rx="4"/>
          <rect x="754" y="700" width="642" height="8" rx="4"/>
          <rect x="754" y="640" width="528" height="8" rx="4"/>
          <rect x="754" y="720" width="528" height="8" rx="4"/>
          <rect x="754" y="510" width="402" height="8" rx="4"/>
          <rect x="754" y="787" width="642" height="8" rx="4"/>
          <rect x="754" y="867" width="642" height="8" rx="4"/>
          <rect x="754" y="807" width="642" height="8" rx="4"/>
          <rect x="754" y="827" width="642" height="8" rx="4"/>
          <rect x="754" y="847" width="528" height="8" rx="4"/>
          <rect x="40" y="580" width="247" height="22" rx="5"/>
          <rect x="40" y="770" width="149" height="22" rx="5"/>
          <rect x="40" y="614" width="642" height="8" rx="4"/>
          <rect x="40" y="634" width="642" height="8" rx="4"/>
          <rect x="40" y="654" width="642" height="8" rx="4"/>
          <rect x="40" y="694" width="642" height="8" rx="4"/>
          <rect x="40" y="674" width="528" height="8" rx="4"/>
          <rect x="40" y="714" width="642" height="8" rx="4"/>
          <rect x="40" y="734" width="402" height="8" rx="4"/>
          <rect x="40" y="804" width="642" height="8" rx="4"/>
          <rect x="40" y="824" width="642" height="8" rx="4"/>
          <rect x="40" y="844" width="642" height="8" rx="4"/>
          <rect x="40" y="864" width="528" height="8" rx="4"/>
        </ContentLoader>
      ) : (
        <ContentLoader viewBox="0 0 600 928" speed={2} backgroundColor="#d9d9d9" foregroundColor="#ededed"  style={{ width: '100%' }}>
          <rect x="146" y="378" width="309" height="30" rx="4"/>
          <path d="M600 0H0V294H197.848C186.531 275.689 180 254.107 180 231C180 164.726 233.726 111 300 111C366.274 111 420 164.726 420 231C420 254.107 413.469 275.689 402.152 294H600V0Z"/>
          <circle cx="300" cy="231" r="105"/>
          <rect x="19" y="451" width="562" height="2"/>
          <rect x="19" y="597" width="562" height="2"/>
          <rect x="19" y="451" width="2" height="148"/>
          <rect x="579" y="452" width="2" height="148"/>
          <rect x="52" y="484" width="101" height="14" rx="5"/>
          <rect x="52" y="557" width="101" height="14" rx="5"/>
          <rect x="304" y="484" width="82" height="14" rx="5"/>
          <rect x="52" y="517" width="43" height="14" rx="5"/>
          <rect x="304" y="557" width="82" height="14" rx="5"/>
          <rect x="304" y="517" width="130" height="14" rx="5"/>
          <rect x="19" y="636" width="215" height="22" rx="5"/>
          <rect x="19" y="826" width="130" height="22" rx="5"/>
          <rect x="19" y="670" width="560" height="8" rx="4"/>
          <rect x="19" y="690" width="560" height="8" rx="4"/>
          <rect x="19" y="710" width="560" height="8" rx="4"/>
          <rect x="19" y="750" width="560" height="8" rx="4"/>
          <rect x="19" y="730" width="461" height="8" rx="4"/>
          <rect x="19" y="770" width="560" height="8" rx="4"/>
          <rect x="19" y="790" width="351" height="8" rx="4"/>
          <rect x="19" y="860" width="560" height="8" rx="4"/>
          <rect x="19" y="880" width="560" height="8" rx="4"/>
          <rect x="19" y="900" width="560" height="8" rx="4"/>
          <rect x="19" y="920" width="461" height="8" rx="4"/>
        </ContentLoader>
      )}
    </>
  );
};

export default FarmerDetailLoader;