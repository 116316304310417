import React, {Fragment, useEffect, useState} from 'react'
import { useTranslation } from 'react-i18next'
import { currentLang, recipeURL } from '../../../utils/functions'
import Image from '../../common/Image'
import W from '../../common/Word';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';

function RecipeHighlight({data, items}) {
    const { t } = useTranslation();

    return (
        <section className="module__recipe-highlight">
            {items &&
            <Fragment>
            {data.title &&
                <div className="module__title">
                    <h2 className="heading heading--h3"> {data.title} </h2>
                    {data.body && <p>{parse(data.body)}</p>}
                </div>
            }
            <div className="container">
                <div className="row justify-content-center">
                    {items.slice(0, data.total_show ?? 3).map((recipe, index)=>{
                        //var url = '/'+currentLang()+'/'+t("menu.recipes")+'/'+recipe.slug;
                        var url = recipeURL(recipe.slug);
                        return (
                            <div className="col-xs-12 col-md-6 col-lg-4" key={'recipe_highlight_'+index}>
                                <div className="list__item">
                                    <div className="list__body">
                                        <div className="list__image"><Link className="read-more" to={url} title={recipe.title}><Image src={recipe.image_url} type="recipes.list" alt={recipe.title} title={recipe.title} /></Link>
                                            <div className="list__info">
                                                <div className="badge"></div>
                                            </div>
                                        </div>
                                        <div className="list__description">
                                            <div>
                                                <div className="title heading--h3 mb-2"> 
                                                    <Link className="link" to={url} title={recipe.title}>{recipe.title}</Link>
                                                </div>
                                            </div>
                                            <Link className="read-more" to={url} title={t("recipes.view_recipe_button")}><W>recipes.view_recipe_button</W></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            {data.button_text &&
                <div className="see-all">
                        <Link to={data.button_link ?? recipeURL(null)} title={data.button_text} className="btn btn--border btn--red">
                            {data.button_text}
                        </Link>
                </div>
            }
            </Fragment>
            }
        </section>
    )
}

export default RecipeHighlight
