import React, { useEffect, useState, useCallback } from 'react';
import W from '../../../components/common/Word';

const ActionTypes = {
  ADD: 'add',
  REMOVE: 'remove'
};

function FarmshopBundleButton({ bundle_id, quantity, onUpdateQuantity }) {
  const [value, setValue] = useState(quantity);
  const [localUpdate, setLocalUpdate] = useState(null);

  useEffect(() => {
    setValue(quantity);
  }, [quantity]);

  const addtocart = useCallback((action) => {
    setValue((prevValue) => {
      let newVal = prevValue;

      switch (action) {
        case ActionTypes.ADD:
          newVal++;
          break;
        case ActionTypes.REMOVE:
          if (newVal > 0) newVal--;
          break;
        default:
          break;
      }

      setLocalUpdate({ bundle_id, newVal });
      return newVal;
    });
  }, [bundle_id]);

  useEffect(() => {
    if (localUpdate) {
      onUpdateQuantity(localUpdate.bundle_id, localUpdate.newVal);
      // Clear localUpdate to prevent infinite loop
      setLocalUpdate(null);
    }
  }, [localUpdate, onUpdateQuantity]);

  return (
    <div className={`quantity product-${bundle_id} ${value > 0 ? "enabled" : ""}`}>
      <a
        className="plus-btn farmshop-add-to-cart"
        role="button"
        onClick={() => addtocart(ActionTypes.ADD)}
        tabIndex={0}
        onKeyDown={(e) => e.key === 'Enter' && addtocart(ActionTypes.ADD)}
      >
        <span><W>farmshop.btn</W></span>
        <i className='icon-plus'></i>
      </a>
      <span className='number'>
        <input type="text" value={value} disabled />
      </span>
      <a
        className="minus-btn"
        role="button"
        onClick={() => addtocart(ActionTypes.REMOVE)}
        tabIndex={0}
        onKeyDown={(e) => e.key === 'Enter' && addtocart(ActionTypes.REMOVE)}
      >
        <i className='icon-minus'></i>
      </a>
    </div>
  );
}

export default FarmshopBundleButton;