import React, {Fragment, useState, useEffect} from 'react'
import flatstore from 'flatstore';
import {isNil, isArray} from 'lodash';
import axios from 'axios';
import { useLocation, Link } from 'react-router-dom';
import {apiURL} from '../../../utils/functions';
import FarmshopProductBox from '../../../pages/Farmshop/components/product';
import parse from 'html-react-parser';

function FarmshopPopularProducts({data}) {
    const [items, setItems] = useState(null);
    const {pathname} = useLocation()


    useEffect(() => {
        //var animal_type = flatstore.get('animal_type');
        var animal_type = null;
        if(data && data.config){
            var config = JSON.parse(data.config)
            if(!isNil(config.animal_type)){
                animal_type = config.animal_type;
            }
        }

        if(isNil(items)){
            var url = apiURL()+"/popular-farmshop-products"+(animal_type ? "/"+animal_type : "");
            axios.get(url, {}).then(function(response){
                var products = Object.values(response.data).filter(item => item.stock_status == 'instock' );
                setItems(products);
            }).catch(function(error){
                console.log(error);
            });
        }
    }, [pathname])

    return (
        <div className="container">
           {items &&
            <Fragment>
                {data.title &&
                    <div className="h-title">
                        <h3 className="heading heading--h3"> {data.title && data.title} </h3>
                        {data.body && <p>{parse(data.body)}</p>}
                    </div>
                }
                <div className='row justify-content-between align-items-center'>
                    <div className="col-lg-12 mf-items">
                        <div className="row justify-content-center">
                            {items.sort((a, b) => {
                                return a.stock_status != "instock" ? 1 : -1;
                            }).slice(0, data.total_show ?? 6).map((item, index)=>{
                                return(
                                    <div key={"product_card_"+index} className="col-6 col-md-4 col-lg-3">
                                        <FarmshopProductBox key={index} data={item} view_button={true} />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                {data.button_text &&
                    <div className="see-all">
                        <Link to={data.button_link ?? '/'+currentLang()} className="btn btn--border btn--red">
                            {data.button_text}
                        </Link>
                    </div>
                }
            </Fragment>
            }
        </div>
    )
}

export default FarmshopPopularProducts;
