import ContentLoader from 'react-content-loader';
import React, { useState, useEffect } from 'react';

const RecipeListLoader = () => {
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 992);

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 992);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {isWideScreen ? (
        <ContentLoader viewBox="0 0 1500 979" speed={2} backgroundColor="#d9d9d9" foregroundColor="#ededed"  style={{ width: '100%' }}>
          <rect x="442.5" width="400" height="28" rx="5"/>
          <rect x="442.5" y="40" width="615" height="8" rx="4"/>
          <rect x="442.5" y="60" width="615" height="8" rx="4"/>
          <rect x="442.5" y="80" width="615" height="8" rx="4"/>
          <rect x="442.5" y="100" width="249" height="8" rx="4"/>
          <rect x="254.5" y="158" width="97" height="61"/>
          <rect x="357.5" y="158" width="97" height="61"/>
          <rect x="460.5" y="158" width="97" height="61"/>
          <rect x="563.5" y="158" width="97" height="61"/>
          <rect x="666.5" y="158" width="97" height="61"/>
          <rect x="769.5" y="158" width="97" height="61"/>
          <rect x="872.5" y="158" width="97" height="61"/>
          <rect x="975.5" y="158" width="97" height="61"/>
          <rect x="1078.5" y="158" width="97" height="61"/>
          <rect x="1181.5" y="158" width="64" height="61"/>
          <rect x="1" y="270" width="478" height="338"/>
          <rect x="1" y="270" width="478" height="338"/>
          <rect x="511" y="270" width="478" height="338"/>
          <rect x="511" y="270" width="478" height="338"/>
          <rect x="1021" y="270" width="478" height="338"/>
          <rect x="1021" y="270" width="478" height="338"/>
          <rect x="1" y="640" width="478" height="338"/>
          <rect x="1" y="640" width="478" height="338"/>
          <rect x="511" y="640" width="478" height="338"/>
          <rect x="511" y="640" width="478" height="338"/>
          <rect x="1021" y="640" width="478" height="338"/>
          <rect x="1021" y="640" width="478" height="338"/>
        </ContentLoader>
      ) : (
        <ContentLoader viewBox="0 0 600 979" speed={2} backgroundColor="#d9d9d9" foregroundColor="#ededed"  style={{ width: '100%' }}>
          <rect width="400" height="28" rx="5"/>
          <rect y="40" width="600" height="8" rx="4"/>
          <rect y="60" width="600" height="8" rx="4"/>
          <rect y="80" width="600" height="8" rx="4"/>
          <rect y="100" width="249" height="8" rx="4"/>
          <rect y="158" width="128" height="61"/>
          <rect x="134" y="158" width="128" height="61"/>
          <rect x="268" y="158" width="128" height="61"/>
          <rect x="402" y="158" width="128" height="61"/>
          <rect x="536" y="158" width="64" height="61"/>
          <rect x="1" y="270" width="598" height="338"/>
          <rect x="1" y="270" width="598" height="338"/>
          <rect x="1" y="640" width="598" height="338"/>
          <rect x="1" y="640" width="598" height="338"/>
        </ContentLoader>
      )}
    </>
  );
};

export default RecipeListLoader;